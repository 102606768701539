import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import Select from "react-select";
import { selectStyle } from '../../../../theme/theme'
import { APIEndpoint } from '../../../../config/apiendpoint';
import AppServices from '../../../../services/appServices';
import Swal from 'sweetalert2';

const LogAnalysis = (
  {
    show,
    onHide,
    analysisData,
    patientData,
    staffList,
    onUpdate
  }: any) => {
  const _appServices = new AppServices();
  const [btnActive, setIsBtnActive] = useState(false);
  const [data, setData] = useState<any>();
  const [errors, setErrors] = useState<any>();
  const [staffId, setStaffId] = useState()
  const [notes, setNotes] = useState('')
  const [staffMemberError, setStaffMemberError] = useState("");
  const [notesError, setNotesError] = useState("");

  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      setStaffId(selectedOption.value);
      setStaffMemberError("");
    }
    // setAssessmentError("");
  };


  const handleNoteChange = (e: any) => {
    setNotes(e.target.value)
    setNotesError('')
  }
  const handleHide = () => {
    setStaffMemberError("");
    setNotesError('')
    onHide();
  };

  const handleSave = async (values: any) => {
    if (!staffId) {
      setStaffMemberError("Please select one member");
    }
    if (!notes) {
      setNotesError("Analysis Notes is required");
    }
    if (!staffId || !notes) {
      return
    }

    let postJson = {
      createdBy: staffId,
      analysisNotes: notes
    }

    try {
      const res = await _appServices.patchData(`${APIEndpoint.logAnalysis}/${analysisData.id}/?patientUid=${patientData?.uuid}`, postJson)
      if ((res.status === 200 || res.status === 202) && res.success) {
        // handle success
        onHide()
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res?.message,
        })
        onUpdate();
      } else {
        // handle failure
        let errMessage = _appServices.getErrorMessage(res)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
      _appServices.logData({
        Severity: 'Info',
        Activity: res?.message,
        ActivityStatus: 'Log Analysis Updated',
        ActivityResponse: 'Data Submitted',
        Operation: 'User Activity',
      })
    } catch (error) {
      let errMessage = _appServices.getErrorMessage(error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });
      _appServices.logData({
        Severity: 'Info',
        Activity: error,
        ActivityStatus: 'Log Analysis Error',
        ActivityResponse: 'Data Submit Failed',
        Operation: 'User Activity',
      })
    }

    setIsBtnActive(false);
  }

  const handleFormSubmit = async (values: any) => {

  }

  // const staffList=[{ id:"123",name:"John Doe"},{ id:"124",name:"Jane Smith"},{ id:"125",name:"Emily Davis"},{ id:"126",name:"Michael Brown"}]

  const staffMembers = staffList?.map((option: any) => ({
    value: option.uuid,
    label: option.firstName + ' ' + option.lastName,
  }));


  return (
    <div>
      <Modal
        className="assignModal assign-assessment-pop-model"
        show={show}
        animation={false}
        onHide={handleHide}
      >

        <div className="header-popup modal-header">
          <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>
        <Modal.Body className="body-popup">
          <div className="modal-body-inner">

            <h2 className='graphHeading h5'>Log Analysis</h2>
            <br />
            <p className='mb-0'>Patient</p>
            <div className="custom-form-notEditable">
              {`${patientData?.firstName} ${patientData?.lastName}`}
            </div>

            <p className='mb-0'>30-Day Cycle End Date</p>
            <div className="custom-form-notEditable">
              {`${analysisData?.cycleEndDate}`}
            </div>

            <p className='mb-0'>Measurement</p>
            <div className="custom-form-notEditable">
              {`${analysisData?.analysis}`}
            </div>

            <div className="select-input-wrap">
              <p className="">Staff Member</p>
              <Select
                name="Choose Staff"
                value={staffMembers?.find(
                  (option: any) => option.value == analysisData?.id
                )}
                onChange={handleSelectChange}
                options={staffMembers}
                styles={selectStyle}
                className={`${staffMemberError ? 'error-adMonitor' : ''}`}
                isSearchable
              />
              {staffMemberError && (
                <p className="text-danger">{staffMemberError}</p>
              )}
            </div>
            <div>
              <Form.Group controlId="message">
                <Form.Label className='mt-3'>Analysis Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  className={`form-control custom-form-input-text-area ${notesError ? 'error-adMonitor' : ''}`}
                  rows={6}
                  onChange={handleNoteChange}
                />
                {notesError && <p className="text-danger">{notesError}</p>}
              </Form.Group>
            </div>



          </div>
          <Button
            className="primary-btn"
            onClick={handleSave}
            disabled={btnActive}
          >
            Submit
          </Button>

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default LogAnalysis