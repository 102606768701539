import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import CustomInput from '../../../component/CustomInput/CustomInput';
import { isValidEmail } from '../../../utils/validationHelper';
import InputMask from 'react-input-mask';
import { APIEndpoint } from '../../../../config/apiendpoint';
import AppServices from '../../../../services/appServices';

const initialState = {
  firstName: '',
  lastName: '',
  middleName: '',
  credentialTitle: '',
  phoneNumber: '',
  email: '',
};

const initialStateErrors = {
  firstNameError: '',
  lastNameError: '',
  // middleNameError:'',
  // titleError:'',
  credentialTitleError: '',
  mobileNumberError: '',
  emailError: '',
};


const InviteStaffMemberPopup = (
  {
    show,
    onHide,
    onClick,
    editMode,
    addStaffEvent
  }: any) => {
  const _appServices = new AppServices();
  const [btnActive, setIsBtnActive] = useState(false);
  const [data, setdata] = useState(initialState);
  const [errors, seterrors] = React.useState(initialStateErrors)
  const _onChangeTextChange = (text: React.ChangeEvent<HTMLInputElement>, type: string) => {
    switch (type) {
      case 'FIRST_NAME':
        setdata({
          ...data,
          firstName: text.target.value,
        });
        seterrors({
          ...errors,
          firstNameError: '',
        });
        break;

      case 'MIDDLE_NAME':
        setdata({
          ...data,
          middleName: text.target.value,
        });
        break;

      case 'LAST_NAME':
        setdata({
          ...data,
          lastName: text.target.value,
        });
        seterrors({
          ...errors,
          lastNameError: '',
        });
        break;

      case 'TITLE':
        setdata({
          ...data,
          credentialTitle: text.target.value,
        });
        seterrors({
          ...errors,
          credentialTitleError: '',
        });
        break;
      case 'PHONE_NUMBER':
        const maskedValue = text.target.value;
        const numericValue = maskedValue.replace(/[^\d]/g, '');
        const trimmedValue = numericValue.substring(1);
        setdata({
          ...data,
          phoneNumber: trimmedValue,
        });
        seterrors({
          ...errors,
          mobileNumberError: '',
        });

        break;
      case 'EMAIL':
        setdata({
          ...data,
          email: text.target.value,
        });
        seterrors({
          ...errors,
          emailError: '',
        });
        break;

      default:
        break;
    }
  }

  const handleHide = () => {
    seterrors(initialStateErrors)
    onHide();
    setdata(initialState)
  };
  const validateForm = () => {
    const errors: any = {};
    const isEmailValid = isValidEmail(data?.email);
    if (!isEmailValid) {
      errors.emailError = "Invalid Email Format.";
    }
    if (!data?.email) {
      errors.emailError = "Email is required.";
    }
    if (!data?.credentialTitle) {
      errors.credentialTitleError = "Credential/Title is required.";
    }
    if (!data?.phoneNumber) {
      errors.mobileNumberError = "Mobile Phone Number is required."
    }
    if (!data?.firstName) {
      errors.firstNameError = "First Name is required.";
    }
    if (!data?.lastName) {
      errors.lastNameError = "Last Name is required.";
    }
    return errors;
  };

  const handleSave = async () => {

    const errors = validateForm();
    if (Object.keys(errors).length) {
      seterrors(errors);
      return;
    }
    let payload = {
      ...data,
      middleName: (data['middleName'] != null && data['middleName'] != undefined) ? data['middleName'] : ''
    }
    try {
      const resp = await _appServices.postData(
        `${APIEndpoint.staff}`,
        payload
      );
      setIsBtnActive(false);
      if ((resp.status === 201 || resp.status === 200) && resp.success === true) {
        onClick();
        addStaffEvent();
        setdata(initialState)
        _appServices.showFlashMsg('success', 'Success', resp?.message, false);
        _appServices.logData({
          Severity: 'Info',
          Activity: resp?.message,
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        })
      } else {
        const message = _appServices.getErrorMessage(resp)
        console.log("Error message", message, resp)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message,
        });
      }
    } catch (error: any) {

      setIsBtnActive(false);
      const message = _appServices.getErrorMessage(error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message || "Something went wrong.",
      })
      console.error("Error fetching data:", error);
      _appServices.logData({
        Severity: 'Error',
        Activity: `Staff member is not adding`,
        ActivityStatus: 'Failure',
        ActivityResponse: 'Data Submitted',
        Operation: 'User Activity',
      })
    }
  };


  return (
    <div>
      <Modal
        className="assignModal assign-assessment-pop-model"
        show={show}
        animation={false}
        onHide={handleHide}
      >

        <div className="header-popup modal-header">
          <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>
        <Modal.Body className="body-popup">
          <div className="modal-body-inner">

            <h2 className='graphHeading'>Add Staff Member</h2>
            <br />
            <div className="row mb-2">
              <div className="col-6 pl-0">
                <CustomInput
                  type='text'
                  value={data.firstName}
                  onChange={(event) => _onChangeTextChange(event, 'FIRST_NAME')}
                  errorText={errors.firstNameError}
                  label='First Name*' />
              </div>
              <div className="col-6 pl-0">
                <CustomInput
                  type='text'
                  value={data.middleName}
                  onChange={(event) => _onChangeTextChange(event, 'MIDDLE_NAME')}
                  label='Middle Name' />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6 pl-0">
                <CustomInput
                  type='text'
                  value={data.lastName}
                  onChange={(event) => _onChangeTextChange(event, 'LAST_NAME')}
                  errorText={errors.lastNameError}
                  label='Last Name*' />
              </div>
              <div className="col-6 pl-0">
                <CustomInput
                  type='text'
                  value={data.credentialTitle}
                  onChange={(event) => _onChangeTextChange(event, 'TITLE')}
                  errorText={errors.credentialTitleError}
                  label='Credential/Title(i.e. MD,PA)*' />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-6 pl-0">
                <Form.Label style={{ marginLeft: '0px' }}>Mobile Phone Number*</Form.Label>
                <InputMask
                  mask="+1 (999) 999-9999"
                  placeholder="+1 (999) 999-9999"
                  // maskChar={null}
                  value={data.phoneNumber}
                  onChange={(event) => _onChangeTextChange(event, 'PHONE_NUMBER')}
                  className={`custom-form-input`}
                  type="text"
                  name="mobileNumber"
                  style={{ marginBottom: '10px', height:'50px' }}
                />
                {errors.mobileNumberError && <p className='invalid-input text-left' style={{ fontWeight: 500 }}>{errors?.mobileNumberError}</p>}
              </div>
            </div>

            <div className="row">
              <div className="col-12 pl-0">
                <CustomInput
                  type='text'
                  value={data.email}
                  onChange={(event) => _onChangeTextChange(event, 'EMAIL')}
                  errorText={errors.emailError}
                  label='Email*' />
              </div>
            </div>

          </div>

          <div className="row">
            <div className="d-flex flex-row mt-5">
              <Button
                className="primary-btn"
                onClick={handleSave}
                disabled={btnActive}
              >
                Send Email Invite
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default InviteStaffMemberPopup