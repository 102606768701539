import React from 'react';
import dropdownImage from '../../../assets/image/dropdown.png';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

interface DropdownProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: any[];
  placeholder?: string; // Optional placeholder
}

const Dropdown: React.FC<DropdownProps> = ({ value, onChange, options, placeholder }) => {
  return (
    <div style={{ position: 'relative', width: '100%',minWidth:'280px' }}>
      <select
      className='position-relative'
        value={value}
        onChange={onChange}
        style={{
          // position:'absolute',
          // left:'0',
          width: '100%',
          minWidth:'280px',         
          paddingLeft: '30px',
          paddingRight:'30px',
          borderRadius: '12px',
          border: '1px solid grey',
          appearance: 'none',    
          height:'60px',
          backgroundColor:'transparent',
          zIndex:2
        }}
      >
        {/* Optional placeholder */}
        {placeholder && <option value="" disabled hidden>{placeholder}</option>}
        {/* Map over options array to generate options */}
        {options.map((option:any, index:any) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {/* <img
        src={dropdownImage}
        alt="Icon"
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '50px',
          height: '44px',
          zIndex:1
        }}
      /> */}
      <MdOutlineKeyboardArrowDown size={30}  style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '50px',
        //   height: '44px',
          zIndex:1
        }}/>
    </div>
  );
};

export default Dropdown;
