const minCharAllowed = 3;
const maxCharAllowed = 50;
export const isValidEmail = (email:any) => {
    const validEmailRegex =
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/
    return validEmailRegex.test(email);
  };
  
  // export const isValidPassword = (password:any) => {
  //   const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/
  //   return passwordRegex.test(password);
  // };
  export const isValidPassword = (inputtxt:any, isRequired = true) => {
    var passw_regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{10,50}$/;

    if ((!inputtxt || inputtxt === ''|| inputtxt == undefined) && isRequired) {
        return { isValid: false, message: 'Password is required' };
    } else if(inputtxt && inputtxt!= ''){
        inputtxt = inputtxt?inputtxt.trim(): '';

        if (inputtxt.length > maxCharAllowed) {
            return { isValid: false, message: `Max ${maxCharAllowed} characters allowed` };
        }
    
        if (!passw_regx.test(inputtxt)) {
            return {
                isValid: false,
                message: 'Password must contain at least 10 characters including a symbol, upper and lowercase letters, and a number.',
            };
        }
    }


    return { isValid: true, message: '' };
};