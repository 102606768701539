import React, { useContext, useEffect, useState } from 'react'
import Swal from "sweetalert2";
import { Loader } from '../../../component/loader/loader'
import AppServices from '../../../../services/appServices'
import { APIEndpoint } from '../../../../config/apiendpoint';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFileCircleQuestion, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Transition } from 'react-transition-group';


export default function PaymentHandler() {
  const [animate, setAnimate] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search)
  const status = queryParameters.get('status');

  const navigate = useNavigate();
  const _appServices = new AppServices();

  const { setSessionDetails, isLoggedIn, setIsLoggedIn } = useContext(UserContext)

  const [isRequesting, setIsRequesting] = useState(true);
  const [responseMessage, setResponseMessage] = useState<any>();


  const goToDashboard = () => {
    navigate("/user-panel/dashboard");
  };

  const retryPayment = () => {
    console.log('Retry Payment code here')
    onPaymentStatus(0)
  };

  // setTimeout(() => {
  //   setIsRequesting(false)
  // }, 3000);

  useEffect(() => {
    const sessionObject = localStorage.getItem('sessionObject');
    if (sessionObject) {
      const parsedSession = JSON.parse(sessionObject);
      if (parsedSession.stripeCustomerId && status === "success") {
        onPaymentStatus(1);
      }
    }
    // if (status == "success") {
    //   onPaymentStatus(1)
    // }
    if (status == "failed") {
      setIsRequesting(false)
    }
  }, [])

  let icon;
  let text;
  let button;

  if (status == 'success') {
    icon = (
      <Transition in={animate} timeout={5000} onEnter={() => setAnimate(true)}>
        {(state) => (
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="3x"
            color="green"
            style={{
              transform: state === 'entered' ? 'scale(2.2)' : 'scale(1.3)',
              transition: 'transform 0.5s ease-in-out',
            }}
          />
        )}
      </Transition>
    );
    text = 'Payment successful!';
    button = <button className="primary-btn mt-3" onClick={goToDashboard}> Ok </button>
  } else if (status == 'failed') {
    icon = (
      <Transition in={animate} timeout={5000} onEnter={() => setAnimate(true)}>
        {(state) => (
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="3x"
            color="#e74242"
            style={{
              transform: state === 'entered' ? 'scale(2.2)' : 'scale(1.3)',
              transition: 'transform 0.5s ease-in-out',
            }}
          />
        )}
      </Transition>
    );
    text = 'Payment failed. Please try again.';
    button = <button className="primary-btn mt-3" onClick={retryPayment}> Retry </button>
  } else {
    icon = (<Transition in={animate} timeout={5000} onEnter={() => setAnimate(true)}>
      {(state) => (
        <FontAwesomeIcon
          icon={faFileCircleQuestion}
          size="3x"
          color="orange"
          style={{
            transform: state === 'entered' ? 'scale(2.2)' : 'scale(1.3)',
            transition: 'transform 0.5s ease-in-out',
          }}
        />
      )}
    </Transition>);
    text = 'Unknown payment status.';
    button = <button className="primary-btn mt-3" onClick={retryPayment}> Retry </button>
  }

  const onPaymentStatus = (statusCode: any) => {
    let sessionDetails: any = localStorage.getItem('sessionObject');
    let parseSessionDetails = JSON.parse(sessionDetails);
    let postJson: any = {
      "customerId": parseSessionDetails.stripeCustomerId,
      "paymentStatus": statusCode
    };
    let apiUrl = APIEndpoint.getPaymentStatus;
    _appServices.postData(apiUrl, postJson).then((_res: any) => {
      if (_res.status == 200) { 
        let data = _res.data
        if (statusCode) {
          parseSessionDetails.isLoggedIn= true;
          localStorage.setItem('sessionObject', JSON.stringify(parseSessionDetails));
           setSessionDetails(parseSessionDetails);
          navigate("/user-panel/dashboard");
          setIsRequesting(false)
        } else {
          window.location.replace(data.registrationCheckoutUrl);
          setIsRequesting(false)
        }
      }
    }, (err) => {
      const errorMessage = _appServices.getErrorMessage(err)
      setResponseMessage(errorMessage)
      setIsRequesting(false)
    });
  }

  return (
    <div className='conatiner top-margin-container'>
      <div className='row justify-content-center p-5'>
        <div className='col shadow' style={{ minHeight: '200px', maxWidth: '500px', padding: '30px', background: '#fff' }}>
          {isRequesting ? <><Loader></Loader> <p> Do not close the window</p></> :
            <div>
              {icon}
              <h2 style={{ marginTop: 20 }}>{text}</h2>
              {/* <p style={{ marginTop: 20 }}>
                      Today is {new Date().toLocaleDateString()} and here are the results:
                    </p> */}
              {button}
            </div>
          }
        </div>

      </div>
    </div>
  );
};