// eslint-disable-next-line
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
// import setting from '../../../assets/image/settings.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { bottom_header_color, primary, top_header_color } from '../../../theme/theme';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconContext } from 'react-icons';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiSettings5Fill } from 'react-icons/ri';
import { APIEndpoint } from '../../../config/apiendpoint';
import { envConfig } from '../../../config/env_config';
import ContextStore from '../../../context/clearContext';
import { UserContext } from '../../../context/UserContext';
import AppServices from '../../../services/appServices';

import Swal from 'sweetalert2';


export const UserPanelHeader = () => {
  const _appServices = new AppServices();
  const _contextStore = new ContextStore()
  const { setUserDetails, setGenderInfo, setRelationshipInfo, setFrequencyInfo, setCountryCode, countryCode, userDetails, genderInfo, relationshipInfo, frequencyInfo } = useContext(UserContext)
  const [anchorPatientSubEl, setAnchorPatientSubEl] = useState<null | HTMLElement>(null);
  const [openPatientSub, setOpenPatientSub] = useState(false);
  const [anchorSupportSubEl, setAnchorSupportSubEl] = useState<null | HTMLElement>(null);
  const [anchorSettingSubEl, setAnchorSettingSubEl] = useState<null | HTMLElement>(null);
  const openSettingSub = Boolean(anchorSettingSubEl);
  const [roleType, setRoleType] = useState(3)
  const [openSupportSub, setOpenSupportSub] = useState(false);

  const handlePatientSubClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorPatientSubEl(event.currentTarget);
    setOpenPatientSub(!openPatientSub);
  };
  const closePatientSub = () => {
    setAnchorPatientSubEl(null);
    setOpenPatientSub(false);
  };
  const handleSupportSubClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorSupportSubEl(event.currentTarget);
    setOpenSupportSub(!openSupportSub);
  };
  const closeSupportSub = () => {
    setAnchorSupportSubEl(null);
    setOpenSupportSub(false);
  };
  const handleSettingSubClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorSettingSubEl(event.currentTarget);
  };
  const closeSettingSub = () => {
    setAnchorSettingSubEl(null);
  };

  const navigate = useNavigate();
  const [sideBarWidth, setSideBarWidth] = useState("0%");
  const faqUrl = envConfig.faq
  const location = useLocation();


  useEffect(() => {
    var dropdown = document.getElementsByClassName("dropdown-btn");
    for (var i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function (this: any) {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
    const url = 'https://salesiq.zoho.com/widget';
    const widgetCode = 'siq2840837a3d127e06edece6de35feff4fe59203ab8149c0c4daae77aa2b3b9b9d';
    const script = document.createElement('script');
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", "zoho-script-id");
    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`
    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);
    const chatBox = document.getElementById('zsiq_float')
    if (chatBox) {
      chatBox.classList.remove('d-none');
    }
  }, [])

  useEffect(() => {
    async function getInitialData() {
      await getUserDetails()
      await getGenderDetails()
      await getFrequencyDetails()
      await getCountryCodeList()
      // if (!userDetails) {
      //   if (!genderInfo?.length) {
      //     getGenderDetails()
      //   }
      //   if (!frequencyInfo?.length) {
      //     getFrequencyDetails()
      //   }
      //   if (!countryCode?.length) {
      //     getCountryCodeList()
      //   }
      // }
    }
    getInitialData()
  }, [])

  const getUserDetails = async () => {
    try {
      const sessionObj = localStorage.getItem("sessionObject")
      const parseSession = sessionObj ? JSON.parse(sessionObj) : null
      console.log("**parseSession**", parseSession)
      let userEndpoint = parseSession.userType == "Staff" ? APIEndpoint.staff : APIEndpoint.createProvider
      setRoleType(parseSession.userType == "Staff" ? 2 : 3)
      let data = await _appServices.getData(userEndpoint);
      if (data && data?.data && data?.data) {
        setUserDetails(data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getGenderDetails = async () => {
    try {
      let data = await _appServices.getData(APIEndpoint.getGender);
      if (data && data?.data && data?.data?.length) {
        localStorage.setItem('genderInfo', JSON.stringify(data?.data));
        setGenderInfo(data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getFrequencyDetails = async () => {
    try {
      let data = await _appServices.getData(APIEndpoint.getFrequency);
      if (data && data?.data && data?.data?.length) {
        setFrequencyInfo(data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getCountryCodeList = async () => {
    try {
      let data = await _appServices.getData(APIEndpoint.countryCode);
      if (data && data?.data && data?.data?.length) {
        setCountryCode(data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const logOut = () => {
    _contextStore.clearContext()
    localStorage.clear()
    navigate('/')
    // _appServices.showFlashMsg('success', 'Success', 'Logged out successfully!', false);
  }



  const openNav = () => {
    setSideBarWidth('80%');
  }

  const closeNav = () => {
    setSideBarWidth('0%');
  }

  const gotoMainPage = () => {
    navigate('/user-panel/dashboard')
  }
  const manageSubscription = async () => {
    try {
      let resp = await _appServices.getData(APIEndpoint.manageBilling);
      if (resp && resp?.data && resp.status == 200) {
        window.open(resp?.data, '_blank');
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: resp.message,
        });
      }
    } catch (error) {
      let errMessage = _appServices.getErrorMessage(error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });
    }
  }
  const isActiveParent = ['/user-panel/viewallpatients', '/user-panel/enroll','/user-panel/patientProfile','/user-panel/viewAllMessages'].some(path => location.pathname.startsWith(path));
  const isActiveParentSupport = ['/user-panel/userGuides', faqUrl].some(path => location.pathname.startsWith(path));
  return (
    <div className='nav_container'>
      <div className='top_backGround' style={{ background: top_header_color }} />

      <div className='top_navbar flex-container shadow p-1 mb-5 rounded' style={{ backgroundColor: bottom_header_color }}>
        <div onClick={gotoMainPage} className='nav_logo'>
          <img className='top_logo' src={require('../../../assets/image/App_logo.png')} alt='' />
        </div>
        <div className='top_right_menu web_menu'>
          <nav style={{ width: '100%' }} className='menus'>
            <ul className='nav-bar'>
              <ul style={{ display: 'flex', gap: 100 }}>
                <li className="hideOnMobile">
                  <NavLink to="/user-panel/dashboard" >Dashboard</NavLink>
                </li>

                <li className={`hideOnMobile`}>
                  <span
                    aria-controls={openPatientSub ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openPatientSub ? 'true' : undefined}
                    onClick={handlePatientSubClick}
                    className={`${isActiveParent ? 'active' : ''}`}
                  >
                    Patient {openPatientSub ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </span>
                  <Menu
                    id="patient-sub-menu"
                    anchorEl={anchorPatientSubEl}
                    open={openPatientSub}
                    onClose={closePatientSub}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    className='submenu-wrap'
                  >
                    <MenuItem onClick={closePatientSub}>
                      <NavLink to="/user-panel/viewallpatients" >View All Patients</NavLink>
                    </MenuItem>
                    <MenuItem onClick={closePatientSub}>
                      <NavLink to="/user-panel/enroll" >Enroll new Patient</NavLink>
                    </MenuItem>
                  </Menu>
                </li>

                <li className={`hideOnMobile`}>
                  <span
                    aria-controls={openSupportSub ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSupportSub ? 'true' : undefined}
                    onClick={handleSupportSubClick}
                    className={` ${isActiveParentSupport ? 'active' : ''}`}
                  >
                    Support
                  </span>
                  <Menu
                    id="support-sub-menu"
                    anchorEl={anchorSupportSubEl}
                    open={openSupportSub}
                    onClose={closeSupportSub}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    className='submenu-wrap'
                  >
                    <MenuItem onClick={closeSupportSub}>
                      <NavLink to="/user-panel/userGuides" >User Guides</NavLink>
                    </MenuItem>
                    <MenuItem onClick={closeSupportSub}>
                      <a href={faqUrl} target='_blank' rel='noopener noreferrer'>FAQs</a>
                    </MenuItem>

                  </Menu>
                </li>
              </ul>

              <ul className="hideOnMobile">
                <div className="d-flex">
                  <div className='d-flex flex-column mb-2 align-items-start'>
                    <span className='profile-back'>Welcome Back</span>
                    <span className='profile-name mr-3'> {userDetails ? <span>{roleType == 3 ? 'Dr. ' : ''}{userDetails?.['lastName']}, {userDetails?.['firstName']} {userDetails?.['middleName']}</span> : "Loading..."}</span>
                    {userDetails && <span className='graphText thick-heading textColor' onClick={logOut} style={{ cursor: 'pointer' }}>Log Out</span>}
                  </div>
                  <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <IconContext.Provider value={{ color: '#A4AEBC', size: '40px' }}>
                      <span aria-controls={openSettingSub ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openSettingSub ? 'true' : undefined}
                        onClick={handleSettingSubClick}><RiSettings5Fill /></span>
                    </IconContext.Provider>
                  </div>
                  <Menu
                    id="setting-sub-ment"
                    anchorEl={anchorSettingSubEl}
                    open={openSettingSub}
                    onClose={closeSettingSub}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    className='submenu-wrap'
                  >
                    <MenuItem onClick={closeSettingSub}>
                      {
                        roleType == 3 ? <NavLink className='pl-2' to="/user-panel/edit">View My Profile</NavLink> : <NavLink className='pl-2' to="/user-panel/editMemberProfile" >View My Profile</NavLink>
                      }
                    </MenuItem>
                    {
                      roleType == 3 &&
                      <MenuItem onClick={closeSettingSub}>
                        <NavLink className='pl-2' to="/user-panel/manageStaffMember" >Manage Staff Access</NavLink>
                      </MenuItem>
                    }
                    {
                      roleType == 3 &&
                      <MenuItem onClick={closeSettingSub}>
                        <NavLink className='pl-2' to='#' onClick={manageSubscription}>Billing</NavLink>
                      </MenuItem>
                    }
                    <MenuItem onClick={closeSettingSub}>
                      <NavLink className='pl-2' to='/' onClick={logOut}>Log Out</NavLink>
                    </MenuItem>
                  </Menu>
                </div>
              </ul>
            </ul>
          </nav>
        </div>
        <div className='top_right_menu mobile_menu'>
          <a className="icon mt-4 mb-4 mr-4" onClick={openNav} style={{ fontSize: 32, color: primary }}>
            <i className="fa fa-bars"></i>
          </a>
        </div>
      </div>

      <div className='backDrop' style={{ display: sideBarWidth == '0%' ? 'none' : 'block' }} onClick={closeNav}></div>
      <div id="mySidenav" className="sidenav" style={{ width: sideBarWidth, maxWidth: 350, borderRight: sideBarWidth == "0%" ? "none" : "2pt solid whitesmoke" }}>
        <span className="closebtn" onClick={closeNav}>&times;</span>
        <div className='ml-auto d-flex mb-3'>
          <div className='row w-100'>
            <div className='col-4' style={{ paddingLeft: 30 }}>
              <img src={"https://www.svgrepo.com/show/113263/avatar-close-up.svg"} width={60} style={{ float: 'left' }} className="rounded" alt="..." />
            </div>
            <div className='col-8' style={{ textAlign: 'left' }}>
              <span className='profile-back'>Welcome Back</span><br />
              <span className='profile-name'>{roleType == 3 ? 'Dr. ' : ''} {userDetails?.['lastName']}, {userDetails?.['firstName']} {userDetails?.['middleName']}</span>
            </div>
          </div>
        </div>
        <NavLink className="dropbtn" to="/user-panel/dashboard">Dashboard</NavLink>
        <NavLink className="dropbtn" to="/user-panel/viewallpatients">Patients</NavLink>
        <button className="dropdown-btn">Support
          <i className="fa fa-caret-down"></i>
        </button>
        <div className="dropdown-container">
          <NavLink className="dropbtn" to="/user-panel/userGuides">Guides</NavLink>
          <a className="dropbtn" href={faqUrl} target='blank'>FAQs</a>
          {/* <NavLink className="dropbtn" to="/user-panel/knowYourAssessments">Know your assessments</NavLink> */}
          {/* <NavLink className="dropbtn" to="/">App Support</NavLink> */}
        </div>
        <NavLink className="dropbtn" to="/user-panel/edit">View My Profile</NavLink>
        <NavLink className='pl-2' to="/user-panel/manageStaffMember" >Manage Staff Access</NavLink>

        <span onClick={logOut}>Log Out</span>
      </div>
    </div>
  );
}; 