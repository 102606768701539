import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { editMemberSchema } from "../validationData";
import { ToastContainer } from "react-toastify";
import EditPatient from '../../Authorization/CreateProvider/EditProvider';
import ChangeVerificationn from './ChangeVerificationn';
import ChangePassword from './ChangePassword';
import AppServices from "../../../../services/appServices";
import { APIEndpoint } from '../../../../config/apiendpoint';
import { TailSpin } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { UserContext } from '../../../../context/UserContext';

const EditStaffMember = () => {
  const _appServices = new AppServices();
  const { setUserDetails, userDetails } = useContext(UserContext)
  const [showModal, setShowModal] = useState(false);
  const [changePassPopUp,setChangePassPopUp]=useState(false);
  const [loading, setLoading] = useState(false);
  const [staffDetails, setStaffDetails] = useState<any>({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resp = await _appServices.getData(`${APIEndpoint.staff}`);
        if (resp.status === 200 && resp.success) {
          console.log("staff",resp.data)
          setStaffDetails(resp?.data)
          setLoading(false);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const showPopup = () => {
    setShowModal(true)
  }

  const closePopup = () => {
    setShowModal(false)
  }
  const showChangePassPopUp = () => {
    setChangePassPopUp(true)
  }

  const closeChangePassPopUp = () => {
    setChangePassPopUp(false)
  }

  const handleStaffMemberData = async (
    values: any,
    resetForm: any,
    setSubmitting: any
  ) => {
    setSubmitting(true);

    const staffMemberData = {
      firstName: values.firstName,
      credentialTitle: values.memberTitle,
      lastName: values.lastName,
      middleName: values.middleName,
      phoneNumber: values.mobileNumber,
      updatedBy: staffDetails.uuid
    };
    let updateDetails: any = userDetails
    try {
      const resp = await _appServices.patchData(`${APIEndpoint.staff}?staffUid=${staffDetails.uuid}`, staffMemberData);
      setSubmitting(false);
      if (resp.success === true) {
        updateDetails['firstName'] = staffMemberData.firstName
        updateDetails['lastName'] = staffMemberData.lastName
        updateDetails['middleName'] = staffMemberData.middleName
        setUserDetails(updateDetails)
        _appServices.showFlashMsg('success', 'Success', resp?.message, false);
      } else {
        const message = _appServices.getErrorMessage(resp)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message || 'Something went wrong.',
        });
      }
    }
    catch (error: any) {
      console.error("Error updating", error);
      setSubmitting(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.message || 'Something went wrong.',
      })
      // _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
    }
  }
  return (
    <div className="container top-margin-container">
      <div className="d-flex flex-column ">
        <div className="mt-2 d-flex flex-row justify-content-between align-items-center">
          <h5 className="h5 heading ml-3 mr-5">
            View Your Pofile
          </h5>
          <div>
          <Button
            type="button"
            onClick={showPopup}
            className="primary-btn"
          >Change 2-Step Verification</Button>

           <Button
            type="button"
            onClick={showChangePassPopUp}
            className="primary-btn ml-3"
          >Reset Password</Button>
          </div>
        </div>
        {!loading ? <div className="text-left mt-4 mb-4">
          <Formik
            validationSchema={editMemberSchema}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              handleStaffMemberData(values, resetForm, setSubmitting);
              // console.log("clicked -----======>>>>>");
            }}
            initialValues={{
              firstName: staffDetails?.firstName || "",
              middleName: staffDetails?.middleName || "",
              lastName: staffDetails?.lastName || "",
              memberTitle: staffDetails?.title || "",
              mobileNumber: staffDetails?.phoneNumber || "",
              email: staffDetails?.email || ""
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              isSubmitting,
            }: any) => (
              // console.log( "error=======>", values),
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="validationFormik01">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      className="custom-form-input"
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="validationFormik03">
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      className="custom-form-input"
                      type="text"
                      name="middleName"
                      value={values.middleName}
                      onChange={handleChange}
                      isInvalid={!!errors.middleName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.middleName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="validationFormik03">
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      className="custom-form-input"
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>


                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="validationFormik02">
                    <Form.Label>Credential/Title <br/> (i.e. MD,PA, Office Admin)</Form.Label>
                    {/* <Form.Label>(i.e. MD,PA, Office Admin)</Form.Label> */}
                    <Form.Control
                      className="custom-form-input"
                      type="text"
                      name="memberTitle"
                      value={values.memberTitle}
                      onChange={handleChange}
                      isInvalid={!!errors.memberTitle}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.memberTitle}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationFormik06"> 
                    <Form.Label> <br/>Mobile Phone Number *</Form.Label>
                    {/* <Form.Control
                        className="custom-form-input"
                        type="text"
                        name="mobileNumber"
                        value={values.mobileNumber.trim("")}
                        onChange={handleChange}
                        isInvalid={!!errors.mobileNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.mobileNumber}
                      </Form.Control.Feedback> */}
                    <InputMask
                      mask="+1 (999) 999-9999"
                      placeholder="+1 (999) 999-9999"
                      // maskChar={null}
                      value={values.mobileNumber.trim("")}
                      onChange={(e) => {
                        const maskedValue = e.target.value;
                        const numericValue = maskedValue.replace(/[^\d]/g, '');
                        const trimmedValue = numericValue.substring(1);
                        handleChange({
                          target: {
                            name: 'mobileNumber',
                            value: trimmedValue
                          }
                        });
                      }}
                      className={`custom-form-input ${errors.mobileNumber ? 'error-adMonitor' : ''}`}
                      type="text"
                      name="mobileNumber"
                    />
                    {errors.mobileNumber && (
                      <div className="errorText">{errors.mobileNumber}</div>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="validationFormik07">
                    <Form.Label><br/> Email *</Form.Label>
                    <Form.Control
                      className="custom-form-input"
                      //placeholder="Email"
                      type="text"
                      name="email"
                      value={values.email.trim("")}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <div>
                </div>

                <Button
                  type="submit"
                  className="primary-btn mt-4"
                  disabled={isSubmitting}
                >
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>
          <ChangeVerificationn show={showModal} data={staffDetails} onHide={closePopup} editMode={false} />
          <ChangePassword show={changePassPopUp} data={staffDetails} onHide={closeChangePassPopUp} editMode={false} />
          <hr className='mt-5 mb-0 custom-hr ' />
          {(staffDetails && staffDetails.providerDetails) ? <EditPatient view={'member'} providerDetails={staffDetails?.providerDetails} />:null}
        </div> : <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#03A84E"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />}
      </div>
    </div>
  )
}

export default EditStaffMember