import * as yup from "yup";

const nameRegex = /^(?!.*\s{2})[A-Za-z\s\-_'',.]+$/;

const calculateAge = (dob: any) => {
  const dobDate = new Date(dob);
  const today = new Date();
  const age = today.getFullYear() - dobDate.getFullYear();

  return age;
};

const scriptTagsRegex =
  /(import|from|function|var|let|const|class|console.log\s*\(|console.error\s*\()/g;

const nameValidation = yup
  .string()
  .required("Required field")
  .min(3, "Too Short!")
  .max(30, "Too Long, must be up to 30!")
  .matches(nameRegex, "Invalid characters. Only Alphabets are allowed.")
  .test(
    "no-script-tags",
    "Script tag (import|from|function|var|let|const|class|console) not allowed",
    function (value) {
      if (value) {
        return !scriptTagsRegex.test(value);
      }
      return true;
    }
  );

const middleNameValidation = yup
  .string()
  .notRequired()
  .when("$middleName", {
    is: (middleName: any) =>
      middleName !== undefined && middleName !== null && middleName !== "",
    then: yup
      .string()
      .min(3, "Too Short!")
      .max(30, "Too Long, must be up to 30!")
      .matches(nameRegex, "Invalid characters. Only Alphabets are allowed.")
      .test(
        "no-script-tags",
        "Script tag (import|from|function|var|let|const|class|console) not allowed",
        function (value) {
          if (value) {
            return !scriptTagsRegex.test(value);
          }
          return true;
        }
      ),
    otherwise: yup.string(),
  });

  const memberTitleValidation = yup
  .string()
  .required("Required field")
  .when("$memberTitle", {
    is: (memberTitle: any) =>
      memberTitle !== undefined && memberTitle !== null && memberTitle !== "",
    then: yup
      .string()
      .max(30, "Too Long, must be up to 30!")
      .matches(nameRegex, "Invalid characters. Only Alphabets are allowed.")
      .test(
        "no-script-tags",
        "Script tag (import|from|function|var|let|const|class|console) not allowed",
        function (value) {
          if (value) {
            return !scriptTagsRegex.test(value);
          }
          return true;
        }
      ),
    otherwise: yup.string(),
  });


  const caremiddleNameValidation = yup
  .string()
  .notRequired()
  .min(3, "Too Short!")
  .max(30, "Too Long, must be up to 30!")
  .matches(nameRegex, "Invalid characters. Only Alphabets are allowed.")
  .test(
    "no-script-tags",
    "Script tag (import|from|function|var|let|const|class|console) not allowed",
    function (value) {
      if (value) {
        return !scriptTagsRegex.test(value);
      }
      return true;
    }
  );

const emailValidation = yup
  .string()
  .email("Invalid email")
  .required("Email is required")
  .max(80, "Email must be at most 80 characters")
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
    "Invalid email format"
  )
  .test(
    "no-script-tags",
    "Script tag (import|from|function|var|let|const|class|console) not allowed",
    function (value) {
      if (value) {
        return !scriptTagsRegex.test(value);
      }
      return true;
    }
  );

const phoneValidation = yup
  .string()
  .matches(/^[0-9]+$/, "Must be a number")
  .required("Mobile number is required and must be a digit.")
  .min(10, "Too Short!")
  .max(10, "Too Long, must be up to 10!")
  .test(
    "no-script-tags",
    "Script tag (import|from|function|var|let|const|class|console) not allowed",
    function (value) {
      if (value) {
        return !scriptTagsRegex.test(value);
      }
      return true;
    }
  );

const dobValidation = yup
  .date()
  .required("Required field")
  .max(new Date(), "Date of birth cannot be greater than the current date")
  .test("valid-age", "Age must be less than 150 years", function (value) {
    if (value) {
      const age = calculateAge(value);
      return age < 150;
    }
    return true;
  })
  .test("min-age", "Age must be at least 18 years", function (value) {
    if (value) {
      const age = calculateAge(value);
      return age >= 18;
    }
    return true;
  });


const caredobValidation = yup
  .date()
  .notRequired()
  .max(new Date(), "Date of birth cannot be greater than the current date")
  .test("valid-age", "Age must be less than 150 years", function (value) {
    if (value) {
      const age = calculateAge(value);
      return age < 150;
    }
    return true;
  })
  .test("min-age", "Age must be at least 18 years", function (value) {
    if (value) {
      const age = calculateAge(value);
      return age >= 18;
    }
    return true;
  });

const booleanValidation = yup.boolean().required("Required");

export const zipCodeValidation = yup
  .number()
  .required("Required")
  .integer("Must be an integer")
  .min(100000, "Must be a minimum of 6 digits")
  .max(999999, "Must be up to 6 digits")
  .test(
    "no-script-tags",
    "Script tag (import|from|function|var|let|const|class|console) not allowed",
    function (value: any) {
      if (value) {
        return !scriptTagsRegex.test(value);
      }
      return true;
    }
  );

export const cityValidation = yup
  .string()
  .required("Required")
  .test(
    "no-script-tags",
    "Script tag (import|from|function|var|let|const|class|console) not allowed",
    function (value) {
      if (value) {
        return !scriptTagsRegex.test(value);
      }
      return true;
    }
  );

export const streetAddressValidation = yup
  .string()
  .required("Required")
  .test(
    "no-script-tags",
    "Script tag (import|from|function|var|let|const|class|console) not allowed",
    function (value) {
      if (value) {
        return !scriptTagsRegex.test(value);
      }
      return true;
    }
  );

export const stateValidation = yup
  .string()
  .required("Required")
  .test(
    "no-script-tags",
    "Script tag (import|from|function|var|let|const|class|console) not allowed",
    function (value) {
      if (value) {
        return !scriptTagsRegex.test(value);
      }
      return true;
    }
  );

export const createProviderValidation = yup.object().shape({
  firstName: nameValidation,
  middleName: nameValidation,
  lastName: nameValidation,
  providerNPINumber: yup
    .number()
    .required("Provider NPI Number is required")
    .integer("Provider NPI Number must be an integer"),
  // .min(100000, 'Provider NPI Number must be a minimum of 6 digits').max(999999, 'Provider NPI Number must be less than or equal to 999999'),
  organizationNPINumber: yup.string().optional(),
  licenseNumber: yup.string().required("License Number is required"),
  practiceName: yup.string().optional(),
  streetAddress: streetAddressValidation,
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zipCode: zipCodeValidation,
  email: emailValidation,
  mobileNumber: phoneValidation,
  password: yup
    .string()
    .required("Password is required")
    .min(10, "Password must be at least 10 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  reEnteredPassword: yup
    .string()
    .required("Password is required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
  terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
});

const testFields = [
  "careGiverisAuthToCondPatAssess",
  "careGiverMiddleName",
  "careGiverLastName",
  "careGiverDob",
  "careGiverGender",
  "careGiverRelation",
  "careGiverPhone",
  "careGiverEmail",
];

export const enrollSchema = yup.object().shape({
  isPatientManage: yup.boolean().optional(),
  firstName: nameValidation,
  middleName: middleNameValidation,
  lastName: nameValidation,
  dob: dobValidation,
  gender: yup.string().required("Required field"),
  email: emailValidation,
  mobileNumber: phoneValidation,
  caregivers: yup.array().of(
    yup.object().shape({
      careGiverisAuthToCondPatAssess: yup.boolean().optional(),
      careGiverFirstName: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: nameValidation,
      }),
      careGiverMiddleName: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: caremiddleNameValidation,
      }),
      careGiverLastName: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: nameValidation,
      }),
      careGiverDob: yup.date().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: dobValidation,
      }),
      careGiverGender: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then:yup.string().required("Required field"),
      }),
      careGiverRelation: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: yup.string().required("Required field"),
      }),
      careGiverPhone: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: phoneValidation,
      }),
      careGiverEmail: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: emailValidation,
      }),
    })
  ),
});

export const enrollSchema2 = yup.object().shape({
  isPatientManage: yup.boolean().optional(),
  firstName: nameValidation,
  middleName: middleNameValidation,
  lastName: nameValidation,
  dob: dobValidation,
  gender: yup.string().required("Required field"),
  email: emailValidation
  // .test(
  //   "unique-email",
  //   "Email must be unique",
  //   function (value) {
  //     const caregiversEmails = this.parent.caregivers.map(
  //       (caregiver:any) => caregiver.careGiverEmail
  //     );
  //     return !caregiversEmails.includes(value);
  //   }
  // )
  ,
  mobileNumber: phoneValidation,
  caregivers: yup.array().of(
    yup.object().shape({
      careGiverisAuthToCondPatAssess: yup.boolean().optional(),
      careGiverFirstName: nameValidation,
      careGiverMiddleName: caremiddleNameValidation,
      careGiverLastName: nameValidation,
      careGiverDob: dobValidation,
      careGiverGender: yup.string().required("Required field"),
      careGiverRelation: yup.string().required("Required field"),
      careGiverPhone: phoneValidation,
      careGiverEmail: emailValidation,
    })
  ),
});

// https://codeshare.io/oQR6ZX

export const manageSchema2 = yup.object().shape({
  isPatientManage: yup.boolean().optional(),
  firstName: nameValidation,
  middleName: middleNameValidation,
  lastName: nameValidation,
  dob: dobValidation,
  gender: yup.string().optional(),
  email: emailValidation,
  mobileNumber: phoneValidation,
  caregivers: yup.array().of(
    yup.object().shape({
      careGiverisAuthToCondPatAssess: yup.boolean().optional(),
      careGiverFirstName: nameValidation,
      careGiverMiddleName: caremiddleNameValidation,
      careGiverLastName: nameValidation,
      careGiverDob: dobValidation,
      careGiverGender: yup.string().required("Required field"),
      careGiverRelation: yup.string().required("Required field"),
      careGiverPhone: phoneValidation,
      careGiverEmail: emailValidation,
    })
  ),
});

export const manageSchema = yup.object().shape({
  isPatientManage: yup.boolean().required("Required"),
  firstName: nameValidation,
  middleName: middleNameValidation,
  lastName: nameValidation,
  email: emailValidation,
  mobileNumber: phoneValidation,
  gender: yup.string().required("Required field"),
  dob: dobValidation,
  caregivers: yup.array().of(
    yup.object().shape({
      careGiverisAuthToCondPatAssess: yup.boolean().optional(),
      careGiverFirstName: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: nameValidation,
        otherwise: yup.string().optional(),
      }),
      careGiverMiddleName: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: caremiddleNameValidation,
        otherwise: yup.string().optional(),
      }),
      careGiverLastName: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: nameValidation,
        otherwise: yup.string().optional(),
      }),
      careGiverDob: yup.date().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: dobValidation,
        otherwise: yup.date().optional(),
      }),
      careGiverGender: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: yup.string().required("Required field"),
        otherwise: yup.string().optional(),
      }),
      careGiverRelation: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: yup.string().required("Required field"),
        otherwise: yup.string().optional(),
      }),
      careGiverPhone: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: phoneValidation,
        otherwise: yup.string().optional(),
      }),
      careGiverEmail: yup.string().when("careGiverisAuthToCondPatAssess", {
        is: true,
        then: emailValidation,
        otherwise: yup.string().optional(),
      }),
    })
  ),
});

export const editMemberSchema = yup.object().shape({
  firstName: nameValidation,
  middleName:middleNameValidation,
  memberTitle: memberTitleValidation,
  lastName: nameValidation,
  email: emailValidation,
  mobileNumber: phoneValidation,
})
