import React from "react";

export default function About() {
  return (
    <div className="container top-margin-container">
      <div className="text-left">
        <h5 className="h5 thick-heading">About RPM</h5>

        {/* <h1 className="text-center">Coming soon</h1> */}
        <p className="assessques">
        The RPM by OtisHealth provider portal is a web-based platform designed to simplify the remote physiologic monitoring (RPM) of patients with acute and chronic conditions. 
        </p>
        <h5 className="h5 thick-heading">How It Works:</h5>

        <p className="assessques">Healthcare providers and their staff enroll patients in RPM directly through the provider portal. Providers assign specific physiological measurements such as blood pressure, blood glucose, oxygen saturation, and heart rate for patients to record daily. Providers can specify the number of readings required each day and the preferred reading times. Patients use a Bluetooth-enabled device to take their readings and sync the data to the RPM by OtisHealth patient app.
        </p>
    <h5 className="h5 thick-heading">
    Key Features:
    </h5>
    <ul>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">Patient Enrollment:</u> Easily enroll patients and assign specific daily measurements.
       </p> 
      </li>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">Patient Alerts:</u> Providers receive alerts for abnormal or missed readings, ensuring timely intervention.

       </p> 
      </li>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">Data Collection:</u>  Securely collect and store physiologic data for analysis and interpretation.

       </p> 
      </li>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">Provider-Patient Communication:</u> Facilitate direct messaging from providers to patients for monitoring and adjusting treatment plans.
       </p> 
      </li>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">CMS Reimbursement:</u> Streamline reimbursement submission with automated data collection and compliance tracking.
       </p> 
      </li>
    </ul>
    <h5 className="h5 thick-heading">CMS Guidelines:</h5>
    <p className="assessques thick-heading">RPM by Otishealth supports CMS reimbursement guidelines:
    </p>
    <ul>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">Electronic Data Collection:</u> Physiologic data is electronically collected and securely uploaded for analysis.
       </p> 
      </li>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">FDA-Approved Devices:</u> Utilize medical devices, as defined by the FDA, for data collection and transmission.

       </p> 
      </li>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">Data Collection Requirements:</u> Collect physiological data for at least 16 days out of a 30-day cycle.

       </p> 
      </li>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">Monitoring Conditions:</u> Provide remote physiologic monitoring services for acute or chronic conditions.
       </p> 
      </li>
      <li>
       <p className="assessques"> <u className="assessques thick-heading">Supervision:</u> Services can be provided by auxiliary personnel under the supervision of the billing practitioner.
       </p> 
      </li>
    </ul>
      </div>
    </div>
  );
}
