import React from 'react';
import { primary ,gray} from '../../../theme/theme';
import { useNavigate } from 'react-router-dom';
import { envConfig } from '../../../config/env_config';


export const Bottom = () => {
    const navigate = useNavigate()

    const privacyPolicyUrl = envConfig.privacyUrl;
    const termsConditionsUrl = envConfig.termsConditions;
    return (
    <div className='row pl-5 pr-5' style={{position:'relative', padding:'25px 0px 25px 0px'}}>
            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pb-2 web_menu' style={{fontSize:'12px', lineHeight:'16px',textAlign:'left'}}>
            © Copyright 2024, OtisHealth Inc.
             </div>  
            <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 pb-2 web_menu p' style={{textAlign:'left'}}> 
                <span style={{color:primary ,  fontWeight:'500', cursor:'pointer'}} onClick={()=>navigate('about')} > About RPM by OtisHealth </span> |
                <a style={{color:primary ,  fontWeight:'500', cursor:'pointer'}} href={privacyPolicyUrl} target="_blank" > Privacy Policy</a> | 
                <a style={{color:primary ,  fontWeight:'500', cursor:'pointer'}} href={termsConditionsUrl} target="_blank" > Terms of Use</a>
            </div>  
            <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 pb-2 mobile_menu p' style={{textAlign:'center'}}>
                <span style={{color:primary ,  fontWeight:'500', cursor:'pointer'}} onClick={()=>navigate('about')}> About RPM by OtisHealth </span> |
                <a style={{color:primary ,  fontWeight:'500', cursor:'pointer'}} href={privacyPolicyUrl} target="_blank" > Privacy Policy</a> | 
                <a style={{color:primary ,  fontWeight:'500', cursor:'pointer'}} href={termsConditionsUrl} target="_blank" > Terms of Use</a>
            </div>     
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-2 mobile_menu' style={{fontSize:'12px', lineHeight:'16px'}}>
            © Copyright 2024, OtisHealth Inc.
            </div>  
    </div>
    );
  };