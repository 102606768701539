import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function KnowYourAssessments() {
  const docs = [{ uri: require("../../../../assets/adcs_questionnaire.pdf") }];
  const docs1 = [{ uri: require("../../../../assets/adcs_guidelines.pdf") }];
  const adlLetter = [{ uri: require("../../../../assets/adl_instrument_letter.pdf") }];

  return (
    <div className="container top-margin-container">
      <div className="text-justify">
        <div>
          <h6 className="h6 thick-heading">About ADCS-ADL-MCI</h6>
          <p className="graphText">
            The Alzheimer's Disease Cooperative Study – Activities of Daily
            Living Scale for Mild Cognitive Impairment (ADCS-ADL-MCI) assesses
            functional abilities by gathering information from caregivers or
            informants regarding the performance of patients in various daily
            activities. Widely employed in both clinical trials and practice,
            this scale tracks the functional progression of individuals
            diagnosed with MCI.
          </p>
        </div>

        <div className="mt-5">
          <h6 className="h6 thick-heading">ADCS-ADL-MCI questionnaires</h6>
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            initialActiveDocument={docs[0]}
            config={{
              header: {
                disableHeader: true,
              },
            }}
            style={{backgroundColor:'#dedede'}}
          />
        </div>


        <div className="mt-5">
          <h6 className="h6 thick-heading">ADCS-ADL-MCI guidelines & score calculation</h6>
          <DocViewer
            documents={docs1}
            pluginRenderers={DocViewerRenderers}
            initialActiveDocument={docs1[0]}
            config={{
              header: {
                disableHeader: true,
              },
            }}
            style={{backgroundColor:'#dedede'}}
          />
        </div>
        
        <div className="mt-5 mb-5">
        <h6 className="h6 thick-heading">Acknowledgements</h6>

        <DocViewer
            documents={adlLetter}
            pluginRenderers={DocViewerRenderers}
            initialActiveDocument={adlLetter[0]}
            config={{
              header: {
                disableHeader: true,
              },
            }}
            style={{backgroundColor:'#dedede'}}
          />
        </div>
      </div>
    </div>
  );
}
