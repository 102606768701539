import React, { useContext, useEffect, useState } from "react";
import signIn from "../../../../assets/image/signInIdme.png";
import { useLocation, useNavigate } from "react-router-dom";
import { APIEndpoint } from "../../../../config/apiendpoint";
import { UserContext } from "../../../../context/UserContext";
import AppServices from "../../../../services/appServices";
import { notifyInfo, notifyError } from "../../../../theme/Toaster/quickToasts";
import { envConfig } from "../../../../config/env_config";
import StaffMemberLogin from "../StaffMemberLogin/StaffMemberLogin";

function LoginNew() {
  const _appServices = new AppServices();
  const { setSessionDetails, isLoggedIn, setIsLoggedIn } =
    useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [staffLogin, setStaffLogin] = useState(true)
  useEffect(() => {
    const scriptId = document.getElementById('zoho-script-id')
    const zsScript = document.getElementById('zsiqscript')
    const chatBox = document.getElementById('zsiq_float')
    if (scriptId && zsScript && chatBox) {
      scriptId.remove();
      zsScript.remove();
      chatBox.classList.add('d-none');
    }
  })
  const { clientId, idMeUrl, idMeCallBackUrl, redirectedUri } = envConfig;

  const handleSignIn = () => {
    localStorage.clear();
    sessionStorage.clear();
    let redirectedUrl = idMeUrl + clientId + `&redirect_uri=` + redirectedUri + `&response_type=code&scope=openid%20` + idMeCallBackUrl;

    window.location.replace(redirectedUrl);
  };

  const handleCreateAccount = () => {
    navigate("/register");
  };

  const handleMemberLogin = () => {
    navigate("/staffMember");
  };

  const handleStaffMemberLogin = () => {
    setStaffLogin(!staffLogin)
  }

  return (
    <div className="container top-margin-container">
      {/* {
      staffLogin ?   <> */}
      <h5 className="loginHeading">Welcome to Remote Physiologic Monitoring</h5>
      <h5 className="loginSubHeading">by OtisHealth</h5>
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex flex-column justify-content-center align-items-center text-center flex-wrap">
            <h5 className="h5 heading mt-5"> {`Returning`}<br />{`Provider Login`}</h5>
            <p className="graphText mt-3" style={{ marginBottom: 23 }}>
              Returning members log in using ID.me
            </p>

            <img
              className="mt-3 signInIDmeImage"
              src={signIn}
              alt=""
              onClick={handleSignIn}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex flex-column justify-content-center align-items-center text-center flex-wrap">
            <h5 className="h5 heading mt-5">Staff <br /> Member Login</h5>
            <p className="graphText mt-3">
              Non-provider returning staff members <br /> not using ID.me services.
            </p>
            <button className="primary-btn mt-3 main_login_btn" onClick={handleMemberLogin}>
              Login
            </button>


          </div>
        </div>

        <span className="vertical-line" />

        <div className="col">
          <div className="d-flex flex-column justify-content-center align-items-center text-center flex-wrap">
            <h5 className="h5 heading mt-5">
              New Provider <br /> Registration Here
            </h5>
            <p className="graphText mt-3">
              Create your account and verify your <br /> identity using ID.me services.
            </p>
            <button className="primary-btn mt-3" onClick={handleCreateAccount}>
              Create Account
            </button>
          </div>
        </div>
      </div>


    </div>
  );
}

export default LoginNew;
