// relationshipData.ts
interface Relationship {
    id: number;
    name: string;
    description: string;
    status: number;
    createdAt: string;
    updatedAt: string;
  }
  
  const relationshipData: Relationship[] = [
    {
        "id": 1,
        "name": "Mother",
        "description": "The relationship between them Mother",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 2,
        "name": "Father",
        "description": "The relationship between them Father",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 3,
        "name": "Sister",
        "description": "The relationship between them Sister",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 4,
        "name": "Brother",
        "description": "The relationship between them Brother",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 5,
        "name": "Spouse/Partner",
        "description": "The relationship between them Spouse/Partner",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 6,
        "name": "Child",
        "description": "The relationship between them Child",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 7,
        "name": "Professional Caregiver",
        "description": "The relationship between them Professional Caregiver",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 8,
        "name": "Legal Guardian",
        "description": "The relationship between them Legal Guardian",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 9,
        "name": "Friend",
        "description": "The relationship between them Friend",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 10,
        "name": "Other",
        "description": "The relationship between them Other",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 11,
        "name": "Mother",
        "description": "The relationship between them Mother",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 12,
        "name": "Father",
        "description": "The relationship between them Father",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 13,
        "name": "Sister",
        "description": "The relationship between them Sister",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 14,
        "name": "Brother",
        "description": "The relationship between them Brother",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 15,
        "name": "Spouse/Partner",
        "description": "The relationship between them Spouse/Partner",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 16,
        "name": "Child",
        "description": "The relationship between them Child",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 17,
        "name": "Professional Caregiver",
        "description": "The relationship between them Professional Caregiver",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 18,
        "name": "Legal Guardian",
        "description": "The relationship between them Legal Guardian",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 19,
        "name": "Friend",
        "description": "The relationship between them Friend",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 20,
        "name": "Other",
        "description": "The relationship between them Other",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    }
];

const genderData = [
    {
        "id": 1,
        "name": "Male",
        "description": "Male",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 2,
        "name": "Female",
        "description": "Female",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 3,
        "name": "Prefer not to say",
        "description": "Prefer not to say",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 4,
        "name": "Non-Binary",
        "description": "Non-Binary",
        "status": 1,
        "createdAt": "2024-01-09T16:36:01.000Z",
        "updatedAt": "2024-01-09T16:36:01.000Z"
    },
    {
        "id": 5,
        "name": "Male",
        "description": "Male",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 6,
        "name": "Female",
        "description": "Female",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 7,
        "name": "Prefer not to say",
        "description": "Prefer not to say",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    },
    {
        "id": 8,
        "name": "Non-Binary",
        "description": "Non-Binary",
        "status": 1,
        "createdAt": "2024-01-25T06:00:42.000Z",
        "updatedAt": "2024-01-25T06:00:42.000Z"
    }
]
  
  export function getRelationshipNameById(id: any) {

    const num = parseInt(id)
    const relationship = relationshipData.find((rel) => rel.id == num);
    return relationship?.name;
  }

  export  function getGender(id:any){
    let genderInfo: any = localStorage.getItem("genderInfo")
    const num = parseInt(id)
    const gender = JSON.parse(genderInfo).find((rel: any) => rel.id == num);
    return gender?.name;
  }
  