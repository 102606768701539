import { Routes, Route } from "react-router-dom";
import { appRouter } from "../../../routes/appRoutes"; 
import { UserPanelHeader } from "../../component/Navigation/userPanelheader";
import { useLocation } from "react-router-dom";
import { Bottom } from "../../component/Navigation/bottom";
import { useEffect } from "react";
// import { envConfig } from "../../../config/env_config";

function Userpanel() {

  const location = useLocation()

  useEffect(() =>{
    window.scrollTo(0, 0);
  },[location])


  return (
    <div className="App">
      <UserPanelHeader/>
      <div className={`${location.pathname.includes("/user-panel/patientProfile/") || location.pathname.includes("/user-panel/viewAllMessages") ? 'home' : ''} main_content_area`}
      style={{background: 'transparent', minHeight: `${window.innerHeight-80}px`}}
      >
        <Routes>
            {appRouter.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
                errorElement={route.errorElement}
              />
            ))}
          </Routes>
        </div>
        <Bottom/>
    </div>
  )
}

export default Userpanel