import { useContext, useEffect, useState } from "react";
import addPatient from "../../../../assets/image/addPatient.png";

import { Field, Formik } from "formik";
import { selectStyle60 } from "../../../../theme/theme";
import { Button, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { APIEndpoint } from "../../../../config/apiendpoint";
import AppServices from "../../../../services/appServices";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { enrollSchema } from "../validationData";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { UserContext } from "../../../../context/UserContext";
import Select from "react-select";
import Swal from "sweetalert2";
import { convertDateFormat1 } from "../../../utils/dateHelper";
import InputMask from 'react-input-mask';
export default function EnrollPatient() {
  const navigate = useNavigate();
  const { genderInfo, countryCode } = useContext(UserContext);
  const _appServices = new AppServices();

  const [gender, setGender] = useState<any[]>([]);

  const [schemaN, setSchemaN] = useState(enrollSchema);

  useEffect(() => {
    if (genderInfo && genderInfo.length) {
      let genderDatas: any = genderInfo.map((d: any) => ({
        value: d.id,
        label: d.name,
      }));
      setGender(genderDatas);
    }
  }, [genderInfo, countryCode]);

  const handleEnrollPatient = async (
    values: any,
    resetForm: any,
    setSubmitting: any
  ) => {
    setSubmitting(true);

    const patientData = {
      isPatientManage: values.isPatientManage,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.mobileNumber,
      gender: values.gender,
      dob: convertDateFormat1(values.dob),
    };

    let requestData = {
      ...patientData,
    };
    try {
      const res = await _appServices.postData(
        `${APIEndpoint.enrollPatient}`,
        requestData
      );
console.log("RES",res)
      if (res.status === 201 && res.success) {
        _appServices.showFlashMsg("success", "Success", res.message, false);
        resetForm();
        setSubmitting(false);
        _appServices.logData({
          Severity: "Info",
          Activity: `User enrolled new patient.`,
          ActivityStatus: "Success",
          ActivityResponse: "Data Submitted",
          Operation: "User Activity",
        });
        navigate("/user-panel/invitation");
      } else {
        const message = _appServices.getErrorMessage(res)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message || "Something went wrong.",
        });
        // _appServices.showFlashMsg('info', 'Success', res.message, false);
        setSubmitting(false);
      }
    } catch (err: any) {
      setSubmitting(false);
      const message = _appServices.getErrorMessage(err)
      console.log("*err**",err)
      let captureErrorWord = ['sql', 'salt', 'cannot read properties of null']
      Swal.fire({
        icon: "error",
        title: "Oops...",
        // text: (err && err?.message) ? captureErrorWord.some(i => err?.message.toLowerCase().includes(i)) ? "An error occurred while calling the service, please try after some time.": err?.message:"An error occurred while calling the service, please try after some time.",
        text: message || "Something went wrong.",
      });
      // _appServices.showFlashMsg('error', 'Success', err.message[0], false);
      _appServices.logData({
        Severity: "Error",
        Activity: `User enrolled new patient failed.`,
        ActivityStatus: "Failed",
        ActivityResponse: "Data Submitted",
        Operation: "User Activity",
      });
    }
    setSubmitting(false);
  };

  const schema = schemaN;

  return (
    <div className="container top-margin-container">
      <div className="d-flex flex-column ">
        <div className="text-left">
          <h5 className="h5 heading mb-4">Enroll A New Patient</h5>
          <p className="graphText thin-heading">
            To send an enrollment invitation to the patient, complete the form below.
          </p>
        </div>
        <div className="text-left mt-4 mb-4">
          <Formik
            validationSchema={schema}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              handleEnrollPatient(values, resetForm, setSubmitting);
              // console.log("clicked -----======>>>>>");
            }}
            initialValues={{
              isPatientManage: true,
              firstName: "",
              middleName: "",
              lastName: "",
              dob: "",
              gender: "",
              mobileNumber: "",
              email: ""
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              isSubmitting,
            }: any) => (
              // console.log( "error=======>", values),
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <div className="text-left mt-2 d-flex flex-row">
                    <img
                      src={addPatient}
                      style={{ height: "32px", width: "26px" }}
                      alt=""
                    />
                    <h6 className="h6 heading ml-3 mr-5">
                      Patient Information
                    </h6>
                  </div>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="validationFormik01">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      className="custom-form-input"
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationFormik02">
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      className="custom-form-input"
                      type="text"
                      name="middleName"
                      value={values.middleName}
                      onChange={handleChange}
                      isInvalid={!!errors.middleName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.middleName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationFormik03">
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      className="custom-form-input"
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="validationFormik04">
                    <Form.Label>Date of Birth *</Form.Label>
                    <DatePicker
                      className="react-date-picker custom-form-input-date2"
                      name="dob"
                      value={values.dob ? new Date(values.dob) : ""}
                      format="MM/dd/yyyy"
                      onChange={(date: any) => {
                        if (date) {
                          handleChange({
                            target: {
                              name: "dob",
                              value: date,
                            },
                          });
                        } else {
                          handleChange({
                            target: {
                              name: "dob",
                              value: "",
                            },
                          });
                        }
                      }}
                      maxDate={new Date()}
                    />
                    {errors.dob && (
                      <div className="errorText">{errors.dob}</div>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationFormik05">
                    <Form.Label>Gender*</Form.Label>

                    {gender.length > 0 ? (
                      <Field
                        as={Select}
                        name="gender"
                        options={gender}
                        onChange={(selectedOption: { value: any }) => {
                          handleChange({
                            target: {
                              name: "gender",
                              value: selectedOption.value,
                            },
                          });
                        }}
                        styles={selectStyle60}
                        value={gender.find(
                          (option) => option.value === values.gender
                        )}
                        placeholder="Choose One"
                        isSearchable
                      />
                    ) : (
                      <p>Loading...</p>
                    )}
                    {errors.gender && (
                      <div className="errorText">{errors.gender}</div>
                    )}
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="validationFormik06">
                    <Form.Label>Mobile Phone Number *</Form.Label>
                    {/* <Form.Control
                          className="custom-form-input"
                          type="text"
                          name="mobileNumber"
                          value={values.mobileNumber.trim("")}
                          onChange={handleChange}
                          isInvalid={!!errors.mobileNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mobileNumber}
                        </Form.Control.Feedback> */}
                    <InputMask
                      mask="+1 (999) 999-9999"
                      placeholder="+1 (999) 999-9999"
                      // maskChar={null}
                      value={values.mobileNumber.trim("")}
                      onChange={(e) => {
                        const maskedValue = e.target.value;
                        const numericValue = maskedValue.replace(/[^\d]/g, '');
                        const trimmedValue = numericValue.substring(1);
                        handleChange({
                          target: {
                            name: 'mobileNumber',
                            value: trimmedValue
                          }
                        });
                      }}
                      className="custom-form-input"
                      type="text"
                      name="mobileNumber"
                    />
                    {errors.mobileNumber && (
                      <div className="errorText">{errors.mobileNumber}</div>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="validationFormik07">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      className="custom-form-input"
                      //placeholder="Email"
                      type="text"
                      name="email"
                      value={values.email.trim("")}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <div>
                </div>
                <Button
                  type="submit"
                  className="primary-btn mt-4"
                  disabled={isSubmitting}
                >
                  Enroll Patient
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <ToastContainer position="bottom-center" />
      </div>
    </div>
  );
}
