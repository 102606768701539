import React, { useRef, useEffect } from 'react';
import {
    CartesianGrid,
    Dot,
    Legend,
    Line, ReferenceLine,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis, YAxis
} from 'recharts';
import { convertDateFormat, getDDDMMDD, getTime12hrsData } from '../../utils/dateHelper';
import './styles.css';

const BloodGlucoseGraph = ({ data }) => {
    const bloodGlucoseGraphRef = useRef(null);
    useEffect(() => {
        if (bloodGlucoseGraphRef.current) {
          bloodGlucoseGraphRef.current.scrollLeft = bloodGlucoseGraphRef.current.scrollWidth;
        }
      }, [data]);
    const CustomDot = ({ cx, cy, fill, payload }) => {
        let dotColor = fill;
        dotColor = payload.isAbnormalGlucoseReading ? 'red' : '#E2B71F';
        return (
            <circle
                cx={cx}
                cy={cy}
                r={4}
                fill={dotColor}
                stroke="none"
                // Ensure no hover effect
                style={{ pointerEvents: 'none' }}
            />
        );
    };
    const calculateBarchartWidth = (numberOfItem = 7) => { if (numberOfItem > 6) return numberOfItem * 75; return '100%'; };


    const formattedData = (data).map(entry => ({
        date: getDDDMMDD(entry.date),
        bpm: entry.glucoseReading,
        isAbnormalGlucoseReading: entry?.isAbnormalGlucoseReading
    }));


    const tableData = (data).map(entry => ({
        dateTime: `${convertDateFormat(entry.date)} ${getTime12hrsData(entry.date)}`,
        device: entry?.device,
        bpm: `${entry.glucoseReading} ${entry.unit}`,
        isAbnormalGlucoseReading: entry?.isAbnormalGlucoseReading
    }));

    return (
        <div className="graph-container">
            <h2 className='graphHeading'>Blood Glucose</h2>
            {(data && data.length) ?
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '65px' }}>
                        <ResponsiveContainer width={'100%'} height={275}>
                            <LineChart data={formattedData}>
                                <YAxis
                                    dy={20} // Adjust vertical position
                                    type="number"
                                    dataKey='bpm'
                                    axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                                    tick={{ fill: '#000', fontSize: 12 }}
                                    padding={{ right: 20 }}
                                    tickLine={false}
                                    style={{ fontFamily: 'Open Sans, sans-serif' }}
                                    
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{ overflowX: 'auto', width: 'calc(100% - 70px)' }} ref={bloodGlucoseGraphRef}>
                        <ResponsiveContainer width={calculateBarchartWidth(formattedData.length)} height={300}>
                            <LineChart data={formattedData}>
                                <XAxis
                                    dataKey="date"
                                    angle={0}
                                    dx={-30} // Adjust horizontal position
                                    minTickGap={-200}
                                    textAnchor="start"
                                    tick={{ fill: '#000', fontSize: 12 }}
                                    tickLine={false}
                                    axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                                    padding={{ right: 20 }}
                                    style={{ fontFamily: 'Open Sans, sans-serif' }}
                                />

                                <Tooltip />
                                <Legend
                                    verticalAlign="top"
                                    align="right"
                                    iconType="circle"
                                    iconSize={26}
                                    wrapperStyle={{ paddingLeft: 20 }}
                                    formatter={(value, entry) => (
                                        <span style={{ color: '#000', fontSize: 14 }}>
                                            {value}
                                        </span>
                                    )}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="bpm"
                                    stroke="#E2B71F"
                                    name="mg/dL"
                                    strokeWidth={3}
                                    dot={(props) => <CustomDot {...props} />}
                                />
                                <ReferenceLine y={140} stroke="black" strokeDasharray="3 3" />
                                <ReferenceLine y={70} stroke="black" strokeDasharray="3 3" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div> :
                <p className='d-flex justify-content-center align-items-center' style={{ minHeight: 300 }}>No Chart Data Found</p>
            }

            {<div className='graphtable-container'>
                <table className='graphdata-table'>
                    <thead className='graphTableHead'>
                        <tr className='graphTableborder'>
                            <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Date & Time</th>
                            <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Device</th>
                            <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Range</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((entry, index) => (
                            <tr key={index}>
                                <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.dateTime}</td>
                                <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.device}</td>
                                <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.bpm}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {tableData.length == 0 && <p>
                    No Data Found
                </p>}
            </div>}
        </div>
    );
}

export default BloodGlucoseGraph;
