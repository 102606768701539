import React, { useEffect, useState } from "react";
import Privacy from "../../User-panel/Terms-Privacy/Privacy";
import Conditions from "../../User-panel/Terms-Privacy/Conditions";
import BaaAgreement from "../../User-panel/Terms-Privacy/BaaAgreement";
import { useNavigate } from "react-router-dom";
import { fontSize } from "@mui/system";
import { primary } from "../../../../theme/theme";

export default function Register() {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showConditions, setShowConditions] = useState(false);
  const [showBAA, setShowBAA] = useState(false);

  const [baaChecked, setBaaChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [conditionChecked, setConditionChecked] = useState(false);

  const [baaError, setBaaError] = useState("");
  const [privacyError, setPrivacyError] = useState("");
  const [conditionError, setConditionError] = useState("");

  const [formValid, setFormValid] = useState(false);

  const navigate = useNavigate()
  // console.log("h")

  useEffect(() => {
    const isValid = !conditionError && !privacyError && !baaError && privacyChecked && conditionChecked && baaChecked;
    setFormValid(isValid);
  }, [conditionError, privacyError, baaError, privacyChecked, conditionChecked, baaChecked]);


  const handleClosePrivacy = () => {
    setShowPrivacy(false);
  };

  const handleCloseConditions = () => {
    setShowConditions(false);
  };

  const handleCloseBaa = () => {
    setShowBAA(false);
  }; 


  const handleShowPrivacy = () => {
    setShowPrivacy(true);
  };

  const handleShowTerms = () => {
    setShowConditions(true);
  };

  const handleShowBaa = () => {
    setShowBAA(true);
  };
  

  const handleTickConditions = (isChecked: boolean) => { 
    setConditionChecked(isChecked);
    if(isChecked){
      setConditionError('')
      setTimeout(() => {
        setShowConditions(false);
      }, 500); 
    } 
  };

  const handlePrivacyCheckboxChange = (isChecked: boolean) => { 
    setPrivacyChecked(isChecked)
    if(isChecked){
      setPrivacyError('')
      setTimeout(() => {
        setShowPrivacy(false) 
      }, 500); 
    }  
  };

  const handleTickBaa = (isChecked: boolean) => { 
    setBaaChecked(isChecked);
    if(isChecked){ 
      setBaaError("");
      setTimeout(() => {
        setShowBAA(false);   
      }, 500); 
    }   
  };

  const handleCheckBoxPrivacyCondtion = () =>{
    if(!privacyChecked){
      setPrivacyError("Please read the Privacy Policy");
    }
  }
   
  const handleCheckBoxBAA = () => {
    setBaaChecked(false); 
    setBaaError("Please read the Business Associate Agreement (BAA)"); 
  };

  const handleCheckBoxTemsCondtion = () => { 
    setConditionChecked(false) 
    if(!conditionChecked){
      setConditionError("Please read the Terms of Use");  
    } 
  };

  const handleContinue = () =>{
    let confirmation = { 
      "tnc":conditionChecked,
      "pp":privacyChecked,
      "baa":baaChecked
  }

    sessionStorage.setItem('registrationConfirmation', JSON.stringify(confirmation));
    navigate('/idVerification')
  }

  

  return (
    <div className="container top-margin-container">
      {/* try removing this align -items start */}
      <div className="d-flex flex-column align-items-start text-left">
        <h5 className="h3 heading">Welcome Provider!</h5>

        <div className="mt-4">
          <p className="graphText thin-heading">
          Welcome to Remote Physiological Monitoring by OtisHealth (RPM by OtisHealth™).  To register as a new healthcare provider, you will complete three steps:
          </p><br/>        
         <ul>
         <li className="registerList"><strong>Step 1:</strong> Agree to the RPM service Terms of Use, Privacy Policy, and Business Associate Agreement (BAA).</li><br/>
         <li className="registerList"><strong>Step 2:</strong> Complete the ID verification process using our identify service provider, ID.me. Going forward, you will log into RPM by OtisHealth using your ID.me credentials.</li><br/>
         <li className="registerList"><strong>Step 3:</strong> Select your subscription and method of payment. Our current fees are $40 for provider registration and $10 per month for each enrolled patient.</li>
         </ul>
        </div>

        <p className="h6 heading mt-4 mb-3">
          <strong className="h5">Step 1:</strong> Acceptance of Terms of Use, Privacy Policy, and BAA
        </p>
        <p className="graphText">Agree to the RPM service Terms of Use, Privacy Policy, and Business Associate Agreement (BAA).</p>
        <p className="graphText">You must click on the link and read each document to accept each agreement.</p>
        <div className="ml-4 mt-3">
          <input
            className="form-check form-check-input"
            type="checkbox"
            checked={conditionChecked}
            onChange={handleCheckBoxTemsCondtion}
          />
          <p className="thin-heading graphText dashColor ml-3 mt-2">
            to the{" "}
            <span
              className="textColor"
              onClick={handleShowTerms}
              style={{ cursor: "pointer" }}
            >
              Terms of Use
            </span>
            {/* {conditionError && <span className="errorText">{conditionError}</span>} */}
          </p>
          <div className="d-flex justify-content-between ml-5">
            {conditionError && <div className="errorText mt-0">{conditionError}</div>} 
          </div>
        </div>

        

        <div className="ml-4 mt-2">
          <input
            className="form-check form-check-input"
            type="checkbox"
            checked={privacyChecked}
            onChange={handleCheckBoxPrivacyCondtion}
          />
          <p className="thin-heading graphText dashColor ml-3 mt-2">
          to the{" "}
            <span
              className="textColor"
              onClick={handleShowPrivacy}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Privacy Policy.
            </span>
          </p>
          <div className="d-flex justify-content-between ml-5">
            {privacyError && <div className="errorText mt-0">{privacyError}</div>} 
          </div> 
        </div>


        <div className="ml-4 mt-2 mb-3">
          <input
            className="form-check form-check-input"
            type="checkbox"
            checked={baaChecked}
            onChange={handleCheckBoxBAA}
          />
          <p className="thin-heading graphText dashColor ml-3 mt-2">
          to the{" "}
            <span
              className="textColor"
              onClick={handleShowBaa}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Business Associate Agreement(BAA)
            </span>
          </p>
          {baaError && <div className="errorText ml-5">{baaError}</div>}
        </div>

        <button className="primary-btn mt-3 mb-4" disabled={!formValid} onClick={handleContinue} >Continue</button>
        
        <p className="thin-heading graphText  mb-3">
        After completing registration, a copy of the BAA will be emailed to the email address associated with your ID.me account.

        </p>


      </div>

      <Privacy
        show={showPrivacy}
        isChecked={privacyChecked}
        onHide={handleClosePrivacy} 
        onCheckboxChange={handlePrivacyCheckboxChange}
      />

      <Conditions
        show={showConditions}
        onHide={handleCloseConditions}
        isChecked={conditionChecked}
        onCheckboxChange={handleTickConditions}
      />

      <BaaAgreement
        show={showBAA}
        onHide={handleCloseBaa}
        isChecked={baaChecked}
        onCheckboxChange={handleTickBaa}
      />
    </div>
  );
}