import React, { useContext, useRef, useState } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Form } from 'react-bootstrap';
import { btn_primary } from '../../../../theme/theme';
import IDme_Logo from '../../../../assets/image/IDme_Logo.png';
import { useNavigate } from 'react-router-dom';
import { notifySuccess } from '../../../../theme/Toaster/quickToasts';
import CustomInput from '../../../component/CustomInput/CustomInput';
import { isValidEmail } from '../../../utils/validationHelper';
import ForgotPasswordModal from '../ForgotPassword/ForgotPassword';
import AppServices from '../../../../services/appServices';
import { APIEndpoint } from '../../../../config/apiendpoint';
import { UserContext } from '../../../../context/UserContext';
import Swal from 'sweetalert2';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { TailSpin } from 'react-loader-spinner';
import { envConfig } from '../../../../config/env_config';
const initialState = {
  emailError: "",
  passwordError: "",
};
export default function StaffMemberLogin() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={envConfig.captchaKey}>
      <MyForm />
    </GoogleReCaptchaProvider>
  )
}
const MyForm = () => {
  const _appService = new AppServices();
  const { setSessionDetails } = useContext(UserContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, seterrors] = React.useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const forgotModal = useRef<any>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const hideModal = () => {
    setShowModal(!showModal);
  };

  const outSideClick = (e: any) => {
    if (e.target == forgotModal.current) {
      setShowModal(false);
    }
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    seterrors({
      ...errors,
      emailError: "",
    });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    seterrors({
      ...errors,
      passwordError: "",
    });
  };

  const validateForm = () => {
    const errors: any = {};
    const isEmailValid = isValidEmail(email);
    if (!isEmailValid) {
      errors.emailError = "Invalid Email Format.";
    }
    if (!email) {
      errors.emailError = "Email is required.";
    }
    if (!password) {
      errors.passwordError = "Password is required.";
    }
    return errors;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length) {
      seterrors(errors);
      return;
    }
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }
    try {
      const tokenNew = await executeRecaptcha('staffLogin'); // 'homepage' is the action name

      if (!tokenNew) {
        console.error('Failed to get reCAPTCHA token');
        return;
      }
      // Verify the token (client-side testing)
      // const response = await fetch('https://www.google.com/recaptcha/api/siteverify', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //   },
      //   body: new URLSearchParams({
      //     secret: '6LdBryUqAAAAAOgVh_h2wLZRUYS_yb3uJYgKT7W8', // Your reCAPTCHA secret key
      //     response: tokenNew,
      //   }).toString(),
      // });

      // const data = await response.json();
      // console.log(data); // Inspect the response for errors

      // if (data.success) {
      //   console.log('Captcha verified successfully.');
      //   // Proceed with form submission or other actions
      // } else {
      //   console.log('Captcha verification failed:', data['error-codes']);
      // }
      setLoading(true);
      let postJson = {
        email: email,
        password: password,
        token: tokenNew
      };
      try {
        let res = await _appService.postData(APIEndpoint.login, postJson);
        if (res.status == 200 && res.success) {
          let _res = res.data[0];
          if (_res.twoFactorEnabled) {
            navigate("/otp", {
              state: { res: res, email: email, password: password },
            });

            setLoading(false);
          } else {
            let sessionObj = {
              email: email,
              authToken: _res?.tokens,
              isLoggedIn: true,
              isFirstTimeUser: _res.isFirstTimeLogin,
              userType: _res.userType,
              userTypeId: _res.userTypeId,
            };
            setSessionDetails(sessionObj);
            setTimeout(() => {
              if (_res.isFirstTimeLogin && (_res.userTypeId == 1)) {
                navigate('/welcome')
              } else if (_res.isFirstTimeLogin && (_res.userTypeId == 2)) {
                navigate('/welcome')
              } else {
                navigate('/user/home')
              }
              setLoading(false);
            }, 2000);
          }
        } else {
          setLoading(false);
          let errMessage = _appService.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      } catch (error: any) {
        setLoading(false);
        let errMessage = _appService.getErrorMessage(error);
        let errorResponse = (error && error.message && !error.message.includes('salt')) ? error.message : errMessage
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse,
        });
      }
    } catch (error) {
      console.error('Error during reCAPTCHA verification:', error);
    }
    return

  };


  return (
    <div className='container top-margin-container'>
      <div className='row w-100 d-flex justify-content-center'>
        <div className='col-12 col-md-6 provider-login mt-4'>
          <div className='login-box'>
            <h6 className='h6 thin-heading mb-4 text-left'>Staff Member Login</h6>
            <div className="">
              <CustomInput
                // placeholder="Email"
                type="email"
                value={email}
                onChange={handleUsernameChange}
                errorText={errors.emailError}
                label='Email'
              />
              <CustomInput
                // placeholder="Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                errorText={errors.passwordError}
                icon={true}
                label='Password'
              />
            </div>
            <div className='login-btn-container'>
              <button onClick={handleSubmit}
                className="btn btn-lg mt-4 mb-4 primary-btn login-btn"
                disabled={loading}>
                <span>Login</span>
                <TailSpin
                  visible={loading}
                  height="20"
                  width="20"
                  color="#03A84E"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass="tail-spin-loading"
                /></button>
              <a style={{ color: '#03A84E' }}
                href="#"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Forgot Password ?
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`forget-modal-container ${showModal ? "hide-forget-modal" : ""
          }`}
        ref={forgotModal}
        onClick={outSideClick}
      >
        <ForgotPasswordModal show={showModal} onHide={hideModal} />
      </div>
    </div>
  )

}
