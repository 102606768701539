
import { UserContext } from './UserContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { info, notifyInfo } from '../theme/Toaster/quickToasts';
import AppServices from '../services/appServices';

// const notification = () =>{
//     const _appServices = new AppServices();
//     _appServices.showFlashMsg('info', 'Success', "Your session expired. Login again.", false);
// }
class ContextStore {
    public navigate = useNavigate()
    public userContextData = useContext(UserContext)

    // public _appServices = new AppServices();

    async clearContext() {
        this.navigate("/")
        if(localStorage.getItem("sessionObject")){
            // info()
            // this._appServices.showFlashMsg('info', 'Success', "Your session expired. Login again.", false);
            // notification()
        }
        localStorage.clear()
    }

}
export default ContextStore;