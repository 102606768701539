import React, { useState, useEffect, useContext } from 'react';
import editProvider from '../../../../assets/image/editProvider.png'
import { Formik } from 'formik';
import * as yup from 'yup';
import { btn_primary} from '../../../../theme/theme';
import { Button, Col, Form } from 'react-bootstrap';
import AppServices from '../../../../services/appServices';
import { APIEndpoint } from '../../../../config/apiendpoint';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess } from '../../../../theme/Toaster/quickToasts';
import Swal from 'sweetalert2';
// import { apiService } from '../../../utils/apiService';
// import { envConfig } from '../../../../config/env_config';
import InputMask from 'react-input-mask';
import { UserContext } from '../../../../context/UserContext';

    interface Address {
      addressLine1: string;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    }

    interface ProviderData {
      firstName: string;
      middleName: string;
      lastName: string;
      dob: string;
      email: string;
      phoneNumber: string;
      gender: string;
      NPInumber: string;
      organizationNPINumber: string;
      licenseNumber: string;
      practiceName: string;
      address: Address;
      [key: string]: any;
    }

    const schema = yup.object().shape({
      firstName: yup.string(),
      middleName: yup.string(),
      lastName: yup.string(),  
      // providerNPINumber: yup.string().required(),
      organizationNPINumber: yup.string(),
      // licenseNumber: yup.string().required(),
      practiceName: yup.string(),
      streetAddress: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zipCode: yup.string(),
      email: yup.string().email('Invalid email'),
      phoneNumber: yup.string(),
    });

export default function EditPatient({view, providerDetails}:any) {
  const { setUserDetails,userDetails } = useContext(UserContext)
  const _appServices = new AppServices();
  const [data, setData] = useState<ProviderData | null>(null);
  const [isRequesting, setRequesting] = useState(false);
  const [initialValues, setInitialValues] = useState<Partial<ProviderData>>({});

  useEffect(() => {
    const editProviderData = async () => {
      try {
        let resData: any = {}
        if(providerDetails && providerDetails.uuid) {
          resData = providerDetails
        } else {
          const resp = await _appServices.getData(`${APIEndpoint.createProvider}`);
          resData = resp.data;
        }
        setData(resData);

        setInitialValues({
          firstName: resData.firstName || '',
          middleName: resData.middleName || '',
          lastName: resData.lastName || '',
          providerNPINumber: resData.NPInumber || '',
          organizationNPINumber: resData.organizationNPINumber || '',
          licenseNumber: resData.licenseNumber || '',
          practiceName: resData.practiceName || '',
          streetAddress: resData.address?.addressLine1 || '',
          city: resData.address?.city || '',
          state: resData.address?.state || '',
          zipCode: resData.address?.zipCode || '',
          email: resData.email || '',
          phoneNumber: resData.phoneNumber || '',
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    editProviderData();
  }, []);

  const handleFormSubmit = async (values: any) => { 
    const changedFields = getChangedFields(values);  
    if (Object.keys(changedFields).length > 0) {
      try {
        setRequesting(true)
        const resp = await _appServices.patchData(`${APIEndpoint.createProvider}`, changedFields); 
        if(resp.success === true){ 
          _appServices.showFlashMsg('success', 'Success', resp?.message, false);
          setRequesting(false)
        }else{
          const message = _appServices.getErrorMessage(resp)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: message ||'Something went wrong.',
          });
          setRequesting(false)
        }
      } 
      catch (error:any) {
        console.error("Error updating", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error?.message||'Something went wrong.',
        })
        // _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
      }
    } else {
      Swal.fire({
        icon: "warning", 
        text: 'No Changes found',
      })
    }
  };

  const getChangedFields = (values:any) => {
    
    const changedFields: any = {};
    const addressKeys = ["organizationNPINumber", "practiceName"];

    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key) && values[key] !== data?.[key] && values[key] !== initialValues[key]) {
        if (addressKeys.includes(key)) {
          changedFields[key] = values[key];
        }
      }
    }
    return changedFields;
  };


  return (
    <div className='container '>
        <div className='d-flex flex-column'>
          <div className='text-left mt-4 d-flex flex-row align-items-center mb-3'>
          {view === 'member'? '':<img src={editProvider} style={{height:'34px', width:'34px'}} alt='' />}
            <h5 className='h5 heading ml-2'>{view === 'member'?'Your Provider Profile':'View Your RPM Provider Profile'}</h5>
          </div>
          <div className='text-left mb-2'>
          { data ? (
            <Formik
            validationSchema={schema}
            onSubmit={(values) =>handleFormSubmit(values)}
            initialValues={{
              firstName: data?.firstName || '',
              middleName: data?.middleName || '',
              lastName: data?.lastName || '',
              providerNPINumber: data?.NPInumber || '',
              organizationNPINumber: data?.organizationNPINumber || '',
              licenseNumber: data?.licenseNumber || '',
              practiceName: data?.practiceName || '',
              streetAddress: data?.address?.addressLine1 || '',
              city: data?.address?.city || '',
              state: data?.address?.state || '',
              zipCode: data?.address?.zipCode || '',
              email: data?.email || '',
              phoneNumber: data?.phoneNumber || '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="validationFormik01">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      className='custom-form-notEditable-no-border'
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      plaintext
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationFormik02">
                    <Form.Label>Middle Name*</Form.Label>
                    <Form.Control
                      className='custom-form-notEditable-no-border'
                      type="text"
                      name="middleName"
                      value={values.middleName}
                      plaintext
                      readOnly
                      isInvalid={!!errors.middleName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.middleName}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationFormik03">
                    <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        className='custom-form-notEditable-no-border'
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        plaintext
                        readOnly
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="validationFormik04">
                    <Form.Label>Provider NPI Number*</Form.Label>
                      <Form.Control
                        className='custom-form-notEditable-no-border'
                        type="text"
                        plaintext
                        readOnly
                        value={data?.NPInumber}
                      />
                  </Form.Group>
                  <Form.Group as={Col} controlId="validationFormik05">
                    <Form.Label>Organization NPI Number (Optional)</Form.Label>
                    <Form.Control
                    className={`${view == 'member'?'custom-form-notEditable-no-border':'custom-form-input'}`}
                      type="text"
                      name="organizationNPINumber"
                      value={values.organizationNPINumber}
                      plaintext
                      onChange={handleChange}
                      isInvalid={!!errors.organizationNPINumber}
                      readOnly={view === 'member'}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.organizationNPINumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="validationFormik06">
                    <Form.Label>License Number*</Form.Label>
                    <Form.Control
                        className='custom-form-notEditable-no-border '
                        type="text"
                        plaintext
                        readOnly
                        value={data?.licenseNumber}
                      />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationFormik07">
                    <Form.Label>Practice Name (optional)</Form.Label>
                    <Form.Control
                    className={`${view == 'member'?'custom-form-notEditable-no-border':'custom-form-input'}`}
                      type="text"
                      name="practiceName"
                      value={values.practiceName}
                      plaintext
                      onChange={handleChange}
                      readOnly={view === 'member'}
                      // readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.practiceName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {/* <p className='heading big-p mt-5'>Address</p>
                <Form.Row>
                  <Form.Group as={Col} controlId="validationFormik08">
                    <Form.Label>Street Address*</Form.Label>
                    <Form.Control
                    className='custom-form-notEditable-no-border'
                      type="text"
                      name="streetAddress"
                      value={values.streetAddress}
                      plaintext
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.streetAddress}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md='6' controlId="validationFormik09">
                    <Form.Label>City*</Form.Label>
                    <Form.Control
                    className='custom-form-notEditable-no-border'
                      type="text"
                      name="city"
                      value={values.city}
                      plaintext
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='3' controlId="validationFormik10">
                    <Form.Label>State*</Form.Label>
                    <Form.Control
                    className='custom-form-notEditable-no-border'
                      type="text"
                      //placeholder="State*"
                      name="state"
                      value={values.state}
                      plaintext
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='3' controlId="validationFormik11">
                    <Form.Label>Zip Code*</Form.Label>
                    <Form.Control
                    className='custom-form-notEditable-no-border'
                      type="text"
                      //placeholder="Zip Code*"
                      name="zipCode"
                      value={values.zipCode}
                      plaintext
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.zipCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row> */}
                <Form.Row>
                <Form.Group as={Col} md='6' controlId="validationFormik12">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                    className='custom-form-notEditable-no-border'
                      type="text"
                      //placeholder="Email*"
                      name="email"
                      value={values.email}
                      plaintext
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='6' controlId="validationFormik13">
                    <Form.Label>Mobile Phone Number*<span className='thin-heading graphText'>(Required for  Multi-Factor Authorization)</span></Form.Label>
                    {/* <Form.Control
                    className='custom-form-notEditable-no-border'
                      type="text"
                      //placeholder="Mobile Phone Number* "
                      name="phoneNumber"
                      value={values.phoneNumber}
                      plaintext
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber}
                    </Form.Control.Feedback> */}
                    <InputMask
                    mask="+1 (999) 999-9999"
                    // maskChar={null}
                    value={values.phoneNumber}
                    readOnly
                    className='custom-form-notEditable-no-border'
                  />
                  </Form.Group>
                </Form.Row>

                {view !=='member' && <Button type='submit' className="primary-btn" disabled={isRequesting}>Save Changes</Button>}
              </Form>
            )}
            </Formik>
          ) : (<div className='h5 thick-heading'>...Loading</div>)}
          </div>
          <ToastContainer position="bottom-center"/>
        </div>
    </div>
  )
}
