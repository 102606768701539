import React, { useEffect, useState } from 'react';
import arrow_up from '../../assets/image/arrow_up.png';
import arrow_down from '../../assets/image/arrow_down.png';
import { useNavigate } from 'react-router-dom';
import { primary } from '../theme';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Blocks, TailSpin } from 'react-loader-spinner';
import { getRelationshipNameById } from '../../app/utils/getRelation';
import SendMessageModal from '../../app/component/SendMessageModal/SendMessageModal';
import AssessmentPdfModel from '../../app/component/AssessmentPdfModel/AssessmentPdfModel';
import AppServices from '../../services/appServices';
import { APIEndpoint } from '../../config/apiendpoint';
import { convertDate, convertDateFormat, timeFormat } from '../../app/utils/dateHelper';

interface Caregiver {
  uuid: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  dob: string | null;
  email: string;
  phoneNumber: string | null;
  gender: string | null;
  completedBy: boolean;
}

interface TableProps {
  data: Array<any>;
  isLoading: boolean;
}



const PatientsTable: React.FC<TableProps> = ({ data, isLoading }) => {
  const navigate = useNavigate();
  const _appServices = new AppServices()
  const [sortOrder, setSortOrder] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [sortedData, setSortedData] = useState(data);
  const [showMessgeBox, setShowMessageBox] = useState(false);
  const [patient, setPatient] = useState(null);
  const [showAssessPdfModal, setShowAssessPdfModal] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null)
  // const [ loading, setLoading ]  = useState(false)

  const handleMessageClose = () => {
    setShowMessageBox(false)
  }

  const handleAssessmentClose = () => {
    setShowAssessPdfModal(false)
  }

  const handleAssessmentModalShow = async (item: any) => {
    setAssessmentData(null)
    setShowAssessPdfModal(true)
    try {
      const resp = await _appServices.getData(`${APIEndpoint.assessmentList}/info/${item?.patientAssessmentUid}?patientUid=${item.uuid}`);
      if (resp.status === 200 && resp.success == true) {
        setAssessmentData(resp.data)
      }
      _appServices.logData({
        Severity: 'Info',
        Activity: `User viewed completed assessment.`,
        ActivityStatus: 'Success',
        ActivityResponse: 'Page View',
        Operation: 'User Activity',
      })

    } catch (error) {
      console.error("Error fetching datad:", error);
      _appServices.logData({
        Severity: 'Error',
        Activity: `Error in veiw completed assessment - ${error}.`,
        ActivityStatus: 'Failure',
        ActivityResponse: 'Page View',
        Operation: 'User Activity',
      })
    }

  }

  const handleMessageBoxShow = (item: any) => {
    setShowMessageBox(!showMessgeBox)
    setPatient(item)
  };

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleSort = (column: string) => {
    const order: "ascending" | "descending" = sortOrder === 'ascending' ? 'descending' : 'ascending';

    const sortedDataCopy = [...sortedData].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA === null || valueA === undefined) {
        return order === 'ascending' ? 1 : -1;
      }

      if (valueB === null || valueB === undefined) {
        return order === 'ascending' ? -1 : 1;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return order === 'ascending' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }

      return order === 'ascending' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    });
    setSortOrder(order);
    setSortColumn(column);
    setSortedData(sortedDataCopy);
  };


  return (
    <div className="table-responsive ">
      <table className="custom-adli-table table ">
        <thead>
          <tr className="text-left">
            <th className="h" onClick={() => handleSort('firstName')} style={{ width: "15%" }}>
              <span className="mr-3">Patient</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: '7px', width: '14px', marginTop: '-10px' }}
                  alt=""
                  className={sortColumn === 'firstName' && sortOrder === 'ascending' ? 'visible' : 'hidden'}
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: '7px',
                    width: '14px',
                    marginTop: '7px',
                    marginLeft: '-14px',
                  }}
                  alt=""
                  className={sortColumn === 'firstName' && sortOrder === 'descending' ? 'visible' : 'hidden'}
                />
              </span>
            </th>
            {/* <th style={{ width: "10%" }}>
            </th> */}
            <th onClick={() => handleSort('sortAt')}>
              <span className="mr-3">30 Day Cycle End Date</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: '7px', width: '14px', marginTop: '-10px' }}
                  alt=""
                  className={sortColumn === 'caregiver[0].lastName' && sortOrder === 'ascending' ? 'visible' : 'hidden'}
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: '7px',
                    width: '14px',
                    marginTop: '7px',
                    marginLeft: '-14px',
                  }}
                  alt=""
                  className={sortColumn === 'caregiver[0].lastName' && sortOrder === 'descending' ? 'visible' : 'hidden'}
                />
              </span>
            </th>
            <th onClick={() => handleSort('sortAt')}>
              <span className="mr-3">Measure</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: '7px', width: '14px', marginTop: '-10px' }}
                  alt=""
                  className={sortColumn === 'sortAt' && sortOrder === 'ascending' ? 'visible' : 'hidden'}
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: '7px',
                    width: '14px',
                    marginTop: '7px',
                    marginLeft: '-14px',
                  }}
                  alt=""
                  className={sortColumn === 'sortAt' && sortOrder === 'descending' ? 'visible' : 'hidden'}
                />
              </span>
            </th>
            <th onClick={() => handleSort('sortAt')}>
              <span className="mr-3">Last Reading</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: '7px', width: '14px', marginTop: '-10px' }}
                  alt=""
                  className={sortColumn === 'sortAt' && sortOrder === 'ascending' ? 'visible' : 'hidden'}
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: '7px',
                    width: '14px',
                    marginTop: '7px',
                    marginLeft: '-14px',
                  }}
                  alt=""
                  className={sortColumn === 'sortAt' && sortOrder === 'descending' ? 'visible' : 'hidden'}
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody className='tableBody text-left'>
          {isLoading ?
            (
              <tr>
                <td colSpan={4} style={{ textAlign: 'center' }}>
                  <div className='d-flex align-items-center justify-content-center' style={{ height: '100px' }}>
                    <div>
                      <TailSpin
                        visible={true}
                        height="80"
                        width="80"
                        color="#03A84E"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ) :
            (
              sortedData.length > 0 ?
                sortedData.map((item, index) => (
                  <tr
                    key={index}

                    style={{ cursor: 'pointer' }}
                  >
                    <td className="h" style={{ width: "20%" }}>
                      <div className='d-flex'>
                        <div
                          onClick={() => {
                            _appServices.logData({
                              Severity: 'Info',
                              Activity: `User viewed ${item.firstName} profile.`,
                              ActivityStatus: 'Success',
                              ActivityResponse: 'Page View',
                              Operation: 'User Activity',
                            })
                            navigate(
                              '/user-panel/patientProfile/' + `${item.uuid}`,
                              { state: item }
                            );
                          }}
                        >
                          <span className="heading big-p capitalized primary-text-color">
                            {item.lastName}, {item.firstName} {item.middleName}
                          </span>
                          <br />
                          {/* <p className="heading graphText">DOB: {convertDate(item.dob)}</p> */}
                        </div>
                      </div>
                    </td>
                    {/* <td style={{ width: "10%" }}>
                      <div className='ml-auto textColor' style={{ fontWeight: 'bold' }} onClick={() => { handleMessageBoxShow(item) }}>
                        Message
                      </div>
                    </td> */}
                    <td>
                      {item.analysisData.map((d: any) => {
                        return <p>
                          <span className="mr-3">{convertDateFormat(d.analysisEndAt)}</span>
                          <span className="mr-3">{timeFormat(d.analysisEndAt)}</span>
                        </p>
                      })}

                    </td>
                    <td>{item.analysisData.map((d: any) => { return <p>{d.measurement}</p> })}</td>
                    <td>{item.analysisData.map((d: any) => { return <p>{d.lastReading}</p> })}</td>
                  </tr>
                ))
                : (<tr>
                  <td colSpan={4} className='text-center'><span className='p-4 thick-heading'>No data</span></td>
                </tr>)
            )
          }

        </tbody>
      </table>
      <SendMessageModal
        showModal={showMessgeBox}
        data={patient}
        handleClose={handleMessageClose}
      />

      <AssessmentPdfModel
        showModal={showAssessPdfModal}
        data={assessmentData}
        handleClose={handleAssessmentClose}
      />
    </div>
  );
};

export default PatientsTable;
