// eslint-disable-next-line

import "primereact/resources/themes/lara-light-cyan/theme.css";

import { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';
import Auth from "./app/container/Authorization/authRoutes";
import Userpanel from "./app/container/User-panel";
import "./layout.scss";
import { PrivateRoute, PrivateRouteLoggedIn } from "./routes/privateRoute";
import CustomToaster from "./theme/Toaster/Toastify";


function App() {
  useEffect(() => {
    console.log(document.cookie, 'cookies')
    if (window.self !== window.top && window.top) {
      window.top.location.href = window.self.location.href;
    }
  }, [])
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/*" element={<PrivateRouteLoggedIn component={Auth} />} />
          <Route path="/user-panel/*" element={<PrivateRoute component={Userpanel} />} />
        </Routes>
      </Router>
      <CustomToaster />
      {/* <ToastContainer /> */}

    </div>
  );
}

export default App;

