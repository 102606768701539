import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import AllAssessmentTable from '../../../../theme/Table/AllAssessmentTable';
import AppServices from '../../../../services/appServices';
import { APIEndpoint } from '../../../../config/apiendpoint';

export default function AssignedAssessments() {

  const _appServices = new AppServices()
  const [ listLoading, setListLoading ] = useState(false)
  const [assessmentList,setAssessmentList]= useState([])
  
  const navigate = useNavigate()


  useEffect(() => {
    // const fetchData = async () => {
    //   setListLoading(true);
    //   try {
    //     const resp = await _appServices.getData(`${APIEndpoint.createProvider}/assessments`);
    //    if(resp?.status ===200 && resp?.success == true){
    //      setAssessmentList(resp.data)
    //       setListLoading(false);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //     setListLoading(false);
    //   }
    // };
    // fetchData();
  }, []);

  return (
    <div className='container top-margin-container'>

        <div className='row'>
          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-left pl-0'>
            <h5 className='h5 heading'>View All Assigned Assessments</h5>
          </div>
          <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 justify-content-end pr-0'>
            <button className='float-right primary-btn' onClick={()=>{navigate('/user-panel/enroll')}}>+ Enroll a New Patient</button>
          </div>

        </div>

        <AllAssessmentTable
          data={assessmentList}
          cap=''
          isLoading={listLoading}
          />

        {/* <p className='thick-heading text-left textColor ml-3' onClick={handleEditAssessment}>View/Edit Assessment Templates</p> */}
    </div>
  )
}
