import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { formatTime24hr } from "../../app/utils/dateHelper";
import { APIEndpoint } from "../../config/apiendpoint";
import AppServices from "../../services/appServices";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { GiCircle } from "react-icons/gi";
import { IoCheckmarkCircle } from 'react-icons/io5';
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  selectStyle
} from "../theme";
import './addMonitoring.css';

export default function AddMonitoring({
  show,
  onHide,
  onClick,
  editMode,
  uuidToEdit,
  isLoading,
  name,
  data,
  patient,
  monitoringSuccess
}: any) {
  const _appServices = new AppServices();
  const location = useLocation();
  const [btnActive, setIsBtnActive] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState<any>(null);
  const [measurementError, setMeasurementError] = useState("");
  const [dailyError, setDailyError] = useState("");
  const [sReminderError, setsReminderError] = useState("");
  const [tReminderError, settReminderError] = useState("");
  const [measurementId, setMeasurementId] = useState("");
  const [frequency, setFrequency] = useState(1);
  const [firstReminder, setFirstReminder] = useState<any>();
  const [secondReminder, setSecondReminder] = useState<any>();
  const [thirdReminder, setThirdReminder] = useState<any>();
  const [alertThresholds, setAlertThresholds] = useState<any>(null);
  const [error, setError] = useState('');
  const [reminderOptions, setReminderOptions] = useState([])
  const [measurementOptions, setMeasurementOptions] = useState([])

  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      setMeasurementId(selectedOption.value);
      setSelectedMeasurement(selectedOption);
      setAlertThresholds('');
      setMeasurementError("");
    }

  };


  const handleSave = async () => {
    if (!selectedMeasurement) {
      setMeasurementError("Measurement is required");
    }
    if (frequency === 1 && (firstReminder === '' || firstReminder === undefined || firstReminder == null)) {
      setDailyError("This field is required")
      setsReminderError('')
      settReminderError('')
      return;
    } else if (frequency === 2 && (firstReminder === '' || firstReminder === undefined || firstReminder == null || secondReminder === '' || secondReminder === undefined || secondReminder == null)) {

      if (firstReminder === '' || firstReminder === undefined || firstReminder == null) {
        setDailyError("This field is required")
      }
      if (secondReminder === '' || secondReminder === undefined || secondReminder == null) {
        setsReminderError("This field is required")
      }
      settReminderError('')

      return;
    } else if (frequency === 3 && (firstReminder === '' || firstReminder === undefined || firstReminder == null || secondReminder === '' || secondReminder === undefined || secondReminder == null || thirdReminder === '' || thirdReminder === undefined || thirdReminder == null)) {

      if (firstReminder === '' || firstReminder === undefined || firstReminder == null) {
        setDailyError("This field is required")
      }
      if (secondReminder === '' || secondReminder === undefined || secondReminder == null) {
        setsReminderError("This field is required")
      }
      if (secondReminder === '' || secondReminder === undefined || secondReminder == null) {
        setsReminderError("This field is required")
      }
      if (thirdReminder === '' || thirdReminder === undefined || thirdReminder == null) {
        settReminderError("This field is required")
      }
      return;
    }

    if (!selectedMeasurement) {

      return
    } else if (!alertThresholds) {
      setError('Fill all required fields');
      return
    }
    else if (selectedMeasurement?.value) {
      switch (selectedMeasurement?.value) {
        case 1:
          if (
            alertThresholds?.systolicMin == '' ||
            alertThresholds?.systolicMax == '' ||
            alertThresholds?.diastolicMin == '' ||
            alertThresholds?.diastolicMax == ''
          ) {
            setError('Fill all required fields')
            return
          }
          break;
        case 2:
          if (
            alertThresholds?.glucoseMin == '' ||
            alertThresholds?.glucoseMax == ''
          ) {
            setError('Fill all required fields')
            return
          }

          break;
        case 3:
          if (
            alertThresholds?.oxygenMin == '' ||
            alertThresholds?.oxygenMax == ''
          ) {
            setError('Fill all required fields')
            return
          }

          break;
        case 4:
          if (
            alertThresholds?.heartRateMin == '' ||
            alertThresholds?.heartRateMax == ''
          ) {
            setError('Fill all required fields')
            return
          }

          break;
        default:
          setError('Fill all required fields');
          return
      }
    } else {
      setError('Fill all required fields');
      return
    }
    if (error) {
      return
    }
    let payload = {
      "patientUid": patient.uuid,
      "measurementId": selectedMeasurement.value,
      "frequencyId": frequency,
      "remiders": {
        "first": formatTime24hr(firstReminder),
        "second": (frequency == 2 || frequency == 3) ? formatTime24hr(secondReminder) : null,
        "third": frequency == 3 ? formatTime24hr(thirdReminder) : null
      },
      "systolicMin": alertThresholds.systolicMin ?? 0,
      "systolicMax": alertThresholds.systolicMax ?? 0,
      "diastolicMin": alertThresholds.diastolicMin ?? 0,
      "diastolicMax": alertThresholds.diastolicMax ?? 0,
      "bloodGlucoseLevelMin": alertThresholds.glucoseMin ?? 0,
      "bloodGlucoseLevelMax": alertThresholds.glucoseMax ?? 0,
      "oxygenSaturationMin": alertThresholds.oxygenMin ?? 0,
      "oxygenSaturationMax": alertThresholds.oxygenMax ?? 0,
      "heartRateMin": alertThresholds.heartRateMin ?? 0,
      "heartRateMax": alertThresholds.heartRateMax ?? 0
    }
    if (editMode) {
      try {
        const resp = await _appServices.patchData(
          `${APIEndpoint.Monitoring}/${data.uuid}?patientUid=${patient.uuid}`,
          payload
        );
        if (resp.status === 200 && resp.success == true) {
          _appServices.showFlashMsg('success', 'Success', resp?.message, false);
          _appServices.logData({
            Severity: 'Info',
            Activity: `User edited assessment for patient - ${name?.firstName}.`,
            ActivityStatus: 'Success',
            ActivityResponse: 'Data Submitted',
            Operation: 'User Activity',
          })
          monitoringSuccess()
        } else {
          const message = _appServices.getErrorMessage(resp)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: message,
          })
          _appServices.logData({
            Severity: 'Info',
            Activity: `User edited assessment for patient - ${name?.firstName}.`,
            ActivityStatus: 'Success',
            ActivityResponse: 'Data Submitted',
            Operation: 'User Activity',
          })
        }
      } catch (err: any) {
        const message = _appServices.getErrorMessage(err)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message || "Something went wrong.",
        })
        _appServices.logData({
          Severity: 'Error',
          Activity: `User failed to edit assessment for patient - ${name?.firstName}.`,
          ActivityStatus: 'Failure',
          ActivityResponse: err,
          Operation: 'User Activity',
        })
      }
    } else {
      try {
        const resp = await _appServices.postData(
          `${APIEndpoint.Monitoring}?patientUid=${patient?.uuid}`,
          payload
        );
        if ((resp.status === 201 || resp.status === 200) && resp.success === true) {
          monitoringSuccess()
          _appServices.showFlashMsg('success', 'Success', resp?.message, false);
          _appServices.logData({
            Severity: 'Info',
            Activity: `Add Monitoring for patient.`,
            ActivityStatus: 'Success',
            ActivityResponse: 'Data Submitted',
            Operation: 'User Activity',
          })
        } else {
          // const message = resp?.message[0].split('T')[0]||'Something went wrong.'
          const message = _appServices.getErrorMessage(resp)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: message,
          })
            ;
        }
      } catch (error: any) {
        const message = _appServices.getErrorMessage(error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message || "Something went wrong.",
        })
        console.error("Error fetching data:", error);
        _appServices.logData({
          Severity: 'Error',
          Activity: `Add Monitoring for patient.`,
          ActivityStatus: 'Failure',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        })
      }
    }
    setIsBtnActive(false);
  };



  const handleHide = () => {
    setError("");
    setAlertThresholds({});
    setFirstReminder('');
    setSecondReminder('');
    setThirdReminder('');
    setSelectedMeasurement(null)
    setMeasurementId('')
    setFrequency(1)
    onHide();
    setMeasurementError('')
    setDailyError('')
    setsReminderError('')
    settReminderError('')
  };

  const handleFrequencyChange = (e: any) => {
    setFirstReminder('')
    setSecondReminder('')
    setThirdReminder('')
    setFrequency(e.target.value);
  };
  const handleTimeChange = (date: any, index: any) => {
    if (index === 0) {
      setFirstReminder(date)
      setDailyError("")
    };
    if (index === 1) {
      setSecondReminder(date)
      setsReminderError("")
    };
    if (index === 2) {
      setThirdReminder(date)
      settReminderError('')
    }
  };

  const handleThresholdChange = (e: any, type: any) => {
    const value: any = e.target.value ? parseInt(e.target.value, 10) : '';
    if (isNaN(value)) return;

    let newThresholds = { ...alertThresholds, [type]: value };
    if (type === 'glucoseMin' && newThresholds.glucoseMax && value > newThresholds.glucoseMax) {
      setError('Minimum value cannot be greater than Maximum value.');
    } else if (type === 'glucoseMax' && newThresholds.glucoseMin && value < newThresholds.glucoseMin) {
      setError('Maximum value cannot be less than Minimum value.');
    } else if (type === 'oxygenMin' && newThresholds.oxygenMax && value > newThresholds.oxygenMax) {
      setError('Minimum value cannot be greater than Maximum value.');
    } else if (type === 'oxygenMax' && newThresholds.oxygenMin && value < newThresholds.oxygenMin) {
      setError('Maximum value cannot be less than Minimum value.');
    } else if (type === 'heartRateMin' && newThresholds.heartRateMax && value > newThresholds.heartRateMax) {
      setError('Minimum value cannot be greater than Maximum value.');
    } else if (type === 'heartRateMax' && newThresholds.heartRateMin && value < newThresholds.heartRateMin) {
      setError('Maximum value cannot be less than Minimum value.');
    } else if (type === 'systolicMin' && newThresholds.systolicMax && value > newThresholds.systolicMax) {
      setError('Minimum value cannot be greater than Maximum value.');
    } else if (type === 'systolicMax' && newThresholds.systolicMin && value < newThresholds.systolicMin) {
      setError('Maximum value cannot be less than Minimum value.');
    } else if (type === 'diastolicMin' && newThresholds.diastolicMax && value > newThresholds.diastolicMax) {
      setError('Minimum value cannot be greater than Maximum value.');
    } else if (type === 'diastolicMax' && newThresholds.diastolicMin && value < newThresholds.diastolicMin) {
      setError('Maximum value cannot be less than Minimum value.');
    }
    else {
      setError('');
    }
    setAlertThresholds(newThresholds);
    // setAlertThresholds({
    //   ...alertThresholds,
    //   [type]: e.target.value
    // });
    // setError('')
  };

  useEffect(() => {
    onMeasurementAndFrequency()
    if (editMode) {
      // console.log(data)
      setSelectedMeasurement({ value: data?.measureId })
      setFrequency(data?.frequency)
      setAlertThresholds(data?.alertThresholds)
      switch (data?.frequency) {
        case 1:
          setFirstReminder(new Date(data?.firstReminder))
          break;
        case 2:
          setFirstReminder(new Date(data?.firstReminder))
          setSecondReminder(new Date(data?.secondReminder))
          break;
        case 3:
          setFirstReminder(new Date(data?.firstReminder))
          setSecondReminder(new Date(data?.secondReminder))
          setThirdReminder(new Date(data?.thirdReminder))
      }
    }
  }, [show])

  const renderAlertThresholdFields = () => {
    if (!selectedMeasurement) return null;

    switch (selectedMeasurement.value) {
      case 1:
        return (
          <>
            <p className="ml-0">Set Alert Threshold</p>
            <Form.Group className="row justify-content-between">
              <Form.Label className="col-4 ml-0 font-14 dark-color mt-2">Systolic Alert:</Form.Label>
              <div className="column col-4 px-0">
                <div className="row d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.systolicMin || ''}
                    onChange={(e) => handleThresholdChange(e, 'systolicMin')}
                    required
                    min={1}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />

                  <span className="d-flex justify-content-center align-items-center input-end-label" >mmHg</span>
                </div>
                <p className="font-14 dark-color ml-1">Minimum</p>
              </div>

              <div className="column col-4 px-0">
                <div className="row align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.systolicMax || ''}
                    onChange={(e) => handleThresholdChange(e, 'systolicMax')}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    min={1}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center input-end-label">mmHg</span>
                </div>
                <p className="font-14 dark-color ml-1">Maximum</p>
              </div>
            </Form.Group>
            <Form.Group className="row justify-content-between">
              <Form.Label className="col-4 ml-0 font-14 dark-color mt-2">Diastolic Alert:</Form.Label>
              <div className="column col-4 px-0">
                <div className="row d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.diastolicMin || ''}
                    onChange={(e) => handleThresholdChange(e, 'diastolicMin')}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    min={1}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center input-end-label" >mmHg</span>
                </div>
                <p className="font-14 dark-color ml-1">Minimum</p>
              </div>
              <div className="column col-4 px-0">
                <div className="row align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.diastolicMax || ''}
                    onChange={(e) => handleThresholdChange(e, 'diastolicMax')}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    min={1}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center input-end-label" >mmHg</span>
                </div>
                <p className="font-14 dark-color ml-1">Maximum</p>
              </div>
            </Form.Group>
          </>
        );
      case 2:
        return (
          <>
            <p className="ml-0">Set Alert Threshold</p>
            <Form.Group className="row justify-content-between">
              <Form.Label className="col-4 ml-0 font-14 dark-color mt-2">Blood Glucose Level:</Form.Label>
              <div className="column col-4 px-0">
                <div className="row d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.glucoseMin || ''}
                    onChange={(e) => handleThresholdChange(e, 'glucoseMin')}
                    min={1}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center input-end-label" >mg/dL</span>
                </div>
                <p className="font-14 dark-color ml-1">Minimum</p>
              </div>
              <div className="column col-4 px-0">
                <div className="row d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.glucoseMax || ''}
                    onChange={(e) => handleThresholdChange(e, 'glucoseMax')}
                    min={1}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center input-end-label" >mg/dL</span>
                </div>
                <p className="font-14 dark-color ml-1">Maximum</p>
              </div>
            </Form.Group>
          </>
        );
      case 3:
        return (
          <>
            <p className="ml-0">Set Alert Threshold</p>
            <Form.Group className="row justify-content-between">
              <Form.Label className="col-4 ml-0 font-14 dark-color mt-2">Oxygen Saturation:</Form.Label>
              <div className="column col-4 px-0">
                <div className="row d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.oxygenMin || ''}
                    onChange={(e) => handleThresholdChange(e, 'oxygenMin')}
                    min={1}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center p-2" >%</span>
                </div>
                <p className="font-14 dark-color ml-1">Minimum</p>
              </div>
              <div className="column col-4 px-0">
                <div className="row d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.oxygenMax || ''}
                    onChange={(e) => handleThresholdChange(e, 'oxygenMax')}
                    min={1}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center p-2" >%</span>
                </div>
                <p className="font-14 dark-color ml-1">Maximum</p>
              </div>
            </Form.Group>
          </>
        );
      case 4:
        return (
          <>
            <Form.Label className="ml-0" >Set Alert Threshold</Form.Label>
            <Form.Group className="row justify-content-between">
              <Form.Label className="col-4 ml-0 font-14 dark-color mt-2">Heart Rate:</Form.Label>
              <div className="column col-4 px-0">
                <div className="row d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.heartRateMin || ''}
                    onChange={(e) => handleThresholdChange(e, 'heartRateMin')}
                    min={1}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center p-2 input-end-label" >BPM</span>
                </div>
                <p className="font-14 dark-color ml-1">Minimum</p>
              </div>
              <div className="column col-4 px-0">
                <div className="row d-flex align-items-center">
                  <Form.Control
                    type="number"
                    value={alertThresholds.heartRateMax || ''}
                    onChange={(e) => handleThresholdChange(e, 'heartRateMax')}
                    min={1}
                    className={`input-border col-6 px-1 ${error ? 'error-adMonitor' : ''}`}
                    step="1"
                    onKeyPress={(e: any) => {
                      if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="d-flex justify-content-center align-items-center p-2 input-end-label" >BPM</span>
                </div>
                <p className="font-14 dark-color ml-1">Maximum</p>
              </div>
            </Form.Group>
          </>
        );
      default:
        return null;
    }
  };

  const onMeasurementAndFrequency = async () => {
    try {
      const resp = await _appServices.getData(`${APIEndpoint.getFrequency}`);
      if (resp.status === 200 && resp.success == true) {
        setReminderOptions(resp.data)
      }
    } catch (error) {
      console.error("Error fetching datad:", error);
    }
    try {
      const resp = await _appServices.getData(`${APIEndpoint.getMeasurement}`);
      if (resp.status === 200 && resp.success == true) {
        const measureOption = resp.data?.map((option: any) => ({
          value: option.id,
          label: option.name,
        }));
        setMeasurementOptions(measureOption)
      }
    } catch (error) {
      console.error("Error fetching datad:", error);
    }
  }




  return (
    <div>
      <Modal
        className="assignModal assign-assessment-pop-model"
        show={show}
        animation={false}
        onHide={handleHide}
      >
        <div className="header-popup modal-header">
          <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>
        <Modal.Body className="">
          {/* <Form onSubmit={handleSubmit}> */}
          <div className="">
            {/* <h6>Assign an Assessment</h6> */}
            <p className="pop-up-heading">{editMode ? "Edit Monitoring" : "Add Monitoring"}</p>
            <br />
            <p className="mb-0">Patient</p>
            <div className="custom-form-notEditable">
              {`${patient?.firstName} ${patient?.lastName}`}
            </div>

            <div className="select-input-wrap">
              <p className={editMode ? "mb-0" : ''}>Measurement</p>
              {editMode ? (
                <div className="custom-form-notEditable">
                  {`${data?.measure}`}
                </div>
              ) :
                (<Select
                  name="chooseMeasurement"
                  value={measurementOptions?.find(
                    (option: any) => option.id === measurementId
                  )}
                  onChange={handleSelectChange}
                  options={measurementOptions}
                  styles={selectStyle}
                  isSearchable
                  className={`${measurementError ? 'error-adMonitor' : ''}`}
                />)
              }

              {measurementError && (
                <p className="text-danger">{measurementError}</p>
              )}
            </div>

            <Form.Group >
              <p className={!editMode ? "my-3 ml-0" : "ml-0"}>Minimum Frequency</p>
              <div className="row align-items-center justify-content-around">
                {reminderOptions.map((option: any) => (
                  <div
                    key={option.id}
                    className="d-flex align-items-center mt-1 radio-black"

                    onClick={() => handleFrequencyChange({ target: { value: option.id } })}
                  >
                    {frequency === option.id ? (
                      <IoCheckmarkCircle size={24} />
                    ) : (
                      <GiCircle size={22} />
                    )}
                    <label
                      className="radio-label"
                      onClick={() => handleFrequencyChange({ target: { value: option.id } })}
                    >
                      {option.name}
                    </label>
                  </div>
                ))}
              </div>
            </Form.Group>
            <Form.Group>
              <p className="ml-0">Set Patient Reminder Time</p>
              <div className="row mt-1">
                <div className="column col-4 ">
                  <DatePicker
                    selected={firstReminder}
                    onChange={(date) => handleTimeChange(date, 0)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className={`form-control input-border ${dailyError ? 'error-adMonitor' : ''}`}
                  // required
                  />
                  <Form.Label className="font-14"> {frequency == 1 ? 'Daily' : 'First Reminder'}</Form.Label>
                </div>
                {frequency != 1 && (
                  < div className="column col-4">

                    <DatePicker
                      selected={secondReminder}
                      onChange={(date) => handleTimeChange(date, 1)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className={`form-control input-border ${sReminderError ? 'error-adMonitor' : ''}`}
                    // required={frequency !== '1'}
                    />
                    <Form.Label className="font-14">Second Reminder</Form.Label>
                  </div>

                )}
                {frequency != 1 && frequency == 3 && (
                  <div className="column col-4">

                    <DatePicker
                      selected={thirdReminder}
                      onChange={(date) => handleTimeChange(date, 2)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className={`form-control input-border ${tReminderError ? 'error-adMonitor' : ''}`}
                    // required={frequency != '2'}
                    />
                    <Form.Label className="font-14">Third Reminder</Form.Label>
                  </div>
                )}
              </div>
            </Form.Group>
            {renderAlertThresholdFields()}
            {error && <p className="text-danger">{error}</p>}
            <div className="d-flex flex-row mt-5">
              <Button
                className="primary-btn"
                onClick={handleSave}
                disabled={btnActive}
              >
                {editMode ? "Save Changes" : "Start Monitoring Now"}
              </Button>
            </div>
          </div>
          {/* </Form> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}
