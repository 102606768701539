import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Select from "react-select";
import {
  selectStyle,
} from "../../../../theme/theme"
import { useNavigate, useParams } from 'react-router-dom';
import AppServices from '../../../../services/appServices';
import { UserContext } from '../../../../context/UserContext';
import { APIEndpoint } from '../../../../config/apiendpoint';
import Swal from 'sweetalert2';
import Dropdown from '../../../component/Dropdown/Dropdown';
import { ClipLoader } from 'react-spinners';
import CustomInput from '../../../component/CustomInput/CustomInput';

// const ChangeVerificationn = (
//     {
//         show,
//         onHide,
//         onClick,
//         editMode,
//         uuidToEdit,
//         isLoading,
//         name,
//         data
//       }: any
// ) => {
//     const [btnActive, setIsBtnActive] = useState(false);
//     const [selectedOtp, setSelectedOtp] = useState<any>(null);
//     const [measurementError, setMeasurementError] = useState("");
//     const [otpId, setOtpId] = useState("");
//     const [error, setError] = useState('');
//     const otpViaOptionList=[{ id:"1",name:"Email"},{ id:"2",name:"Phone"}]
//     const otpViaOptions = otpViaOptionList?.map((option: any) => ({
//       value: option.id,
//       label: option.name,
//     }));

//     const handleSelectChange = (selectedOption: any) => {
//         if (selectedOption) {
//           setOtpId(selectedOption.value);
//           console.log("value",selectedOption)
//           setSelectedOtp(selectedOption);

//           setError('')
//         }
//       };

//     const handleSave = ()=>{
//         onHide()
//     }

//     const handleHide = ()=>{
//         onHide()
//     }
//   return (
//     <div>
//       <Modal
//         className="assignModal assign-assessment-pop-model"
//         show={show}
//         animation={false}
//         onHide={handleHide}
//       >
//         {/* <Modal.Header
//           className="header-popup"
//           closeButton
//           onHide={handleHide}
//           placeholder={null}
//         ></Modal.Header> */}
//         <div className="header-popup modal-header">
//           <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
//           </div>
//         <Modal.Body className="body-popup">
//           <div className="modal-body-inner">
//             {/* <h6>Assign an Assessment</h6> */}
//             <h4>Get Verified</h4>
//             <br />
//             <p>Patient</p>
//             <div className="custom-form-notEditable">
//                 {`Carylon Jackson`}
//               </div>

//             {/* {editMode ? (
//               <div className="custom-form-notEditable">
//                 {`${name?.firstName}` + " " + `${name?.lastName}`}
//               </div>
//             ) : (
//               <Select
//                 name="searchPatient"
//                 isSearchable
//                 onChange={(selectedOption: any) => {
//                   setPatientUuid(selectedOption?.value);
//                   setPatientError("");
//                 }}
//                 onInputChange={handleInputChange}
//                 options={formatSearchResults(searchResults)}
//                 placeholder="Enter Name or DOB"
//                 styles={selectStyleSearchWhite}
//                 isLoading={loading}
//               />
//             )} */}
//           </div>

//           <div className="select-input-wrap">
//             <p className="mt-4">Measurement</p>

//               <Select
//                 name="chooseMeasurement"
//                 value={otpViaOptions?.find(
//                   (option) => option.value === otpId
//                 )}
//                 onChange={handleSelectChange}
//                 options={otpViaOptions}
//                 styles={selectStyle}
//                 isSearchable
//                 className={`${measurementError?'error-adMonitor':''}`}
//               />

//             {measurementError && (
//               <p className="text-danger">{measurementError}</p>
//             )}
//           </div>

//           {/* <div className="select-input-wrap">
//           <p className="mt-4">Device</p>
//           <input
//             type="text"
//             className={`form-control input-f input-box ${deviceError?'error-adMonitor':''}`}
//             value={device}
//             onChange={(e)=>onDeviceChange(e)}
//           />
//           {deviceError && <p className="text-danger">{deviceError}</p>}

//         </div> */}
//           <div className="row">
//             <div className="d-flex flex-row mt-5">
//               <Button
//                 className="primary-btn"
//                 onClick={handleSave}
//                 disabled={btnActive}
//               >
//                  Submit
//               </Button>
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </div>
//   )
// }

const ChangeVerificationn = (
  {
    show,
    onHide,
    onClick,
    editMode,
    uuidToEdit,
    isLoading,
    name,
    data
  }: any
) => {
  const navigate = useNavigate();
  const [btnActive, setIsBtnActive] = useState(false);
  const _appServices = new AppServices();
  const { setSessionDetails, loggedInUserDetails } = useContext(UserContext);
  const [showOTPBox, setShowOTPBox] = useState(false);
  const [otpOption, setOtpOption] = useState("");
  const [otpOptionError, setOtpOptionError] = useState("");
  const [otpError, setotpError] = useState("");
  const [otp, setotp] = useState("");
  const [requestingOtp, setRequestingOtp] = useState(false);
  const [requestingVerified, setRequestingVerified] = useState(false);
  const [requestingResendOtp, setRequestingResendOtp] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const route: any = useParams();
  const [refresh, setRefresh] = useState(false)


  useEffect(() => {
    setOtpOption('')
    setSelectedOption('')
    setDisableButton(false);
  }, [refresh])

  // const verificationOptions = ['Email', 'Mobile Number'];
  const verificationOptions = [
    { label: `Choose One`, value: "" },
    { label: `Email: ${data?.email}`, value: "email" },
    {
      label: `Mobile number: ${data?.phoneNumber}`,
      value: "mobile",
    },
  ];

  const _onChangeTextChange = (text: any) => {
    setotpError("");
    setotp(text.target.value);
  };

  const _onChangeDropdownChange = (event: any) => {
    setOtpOptionError("");
    setSelectedOption(event.target.value);
    setOtpOption(event.target.value);
    setButtonDisabled(false);
    setDisableButton(false);
  };

  const _sendVerificationCode = () => {
    if (otpOption == "") {
      setOtpOptionError("Please Choose One");
      return;
    }
    setShowLoader(true);
    setOtpOption(selectedOption?.value);
    let postJson = {
      getVerifiedBy: otpOption,
    };

    console.log("optoption", postJson)
    setDisableButton(true);

    setRequestingOtp(true);
    _appServices.postData(APIEndpoint.getVerified, postJson).then(
      (res) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setShowOTPBox(true);
          setRequestingOtp(false);
          setButtonDisabled(true);
          setDisableButton(true);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message ? res.message : "",
          });

        } else {
          setShowOTPBox(true);
          setRequestingOtp(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err: any) => {
        setShowOTPBox(true);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  function formatPhoneNumber(phoneNumber: any) {
    if (!phoneNumber) return "";
    const countryCode = "+1";
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);
    return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  }

  const _onSubmit = async () => {
    if (otp == "") {
      setotpError("Verification code is required");
      return;
    }
    let postJson = {
      otp: otp,
      getVerifiedBy: selectedOption,
    };
    setRequestingVerified(true);
    _appServices.postData(APIEndpoint.getVerifiedConfirm, postJson).then(
      async (res: any) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setRequestingVerified(false);
          _appServices.logData({
            Severity: "Info",
            Activity: "User Viewed Home Screen",
            ActivityStatus: "Success",
            ActivityResponse: "Page View",
            Operation: "User Activity",
          });
          if (route.params && route.params.fromProfile) {
            navigate(-1);
          } else {
            // setRefresh(!refresh)
            // navigate("/user/myaccount");
            const oldSessionDetails: any = localStorage.getItem("sessionObject");
            let parseOldSessionDetails = JSON.parse(oldSessionDetails);
            parseOldSessionDetails.isFirstTimeUser = false;
            setSessionDetails(parseOldSessionDetails);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "User verified successfully",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        } else {
          setRequestingVerified(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err: any) => {
        setRequestingVerified(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  const handleResendOTP = () => {
    let postJson = {
      getVerifiedBy: selectedOption,
    };

    setShowOTPBox(false);
    setRequestingResendOtp(true);
    _appServices.postData(APIEndpoint.getVerified, postJson).then(
      (res: any) => {
        if ((res.status == 200 || res.status == 202) && res.success) {
          setShowOTPBox(true);
          setRequestingResendOtp(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res?.message ? res.message : "",
          });
        } else {
          setShowOTPBox(true);

          setRequestingResendOtp(false);
          let errMessage = _appServices.getErrorMessage(res);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },
      (err) => {
        setRequestingResendOtp(false);
        setShowOTPBox(true);
        setRequestingOtp(false);
        let errMessage = _appServices.getErrorMessage(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    );
  };

  const handleHide = () => {
    setotpError('');
    setOtpOption('');
    setOtpOptionError('')
    setSelectedOption('');
    setDisableButton(false)

    onHide()
  }

  return (
    <div>
      <Modal
        className="assignModal assign-assessment-pop-model"
        show={show}
        animation={false}
        onHide={handleHide}
      >
        <div className="header-popup modal-header">
          <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>
        <Modal.Body className="body-popup">
          <div className="profile-form mb-5">
            <div className="mb-5">
              <div className="row ">
                <h5 className="header" style={{ textAlign: "center" }}>
                  Get Verified
                </h5>
              </div>

              <div className="row mt-4">
                <p className="form-label thin" style={{ textAlign: "center" }}>
                  To verify your profile, you will need to enter a 2-step
                  verification code. How would you like to receive your code?{" "}
                </p>
              </div>

              <div className=" d-flex flex-column justify-content-center mt-5">
                <div className="col-12">
                  <Dropdown
                    value={selectedOption}
                    onChange={_onChangeDropdownChange}
                    options={verificationOptions}
                    placeholder="Choose One"
                  />
                </div>
                {otpOptionError != "" && (
                  <div className="text-danger text-center">{otpOptionError}</div>
                )}
              </div>

              <div
                className="mt-5"
                style={{
                  border: "1px solid grey",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "justify",
                }}
              >
                <p className="p-3">
                  Important: By clicking the send code button below, you consent to
                  receive email or text messages with a verification code. Message
                  and data rates may apply.
                </p>
              </div>

              <div className="col-12 d-flex justify-content-center mt-4">
                {!disableButton ? (
                  <button
                    className="primary-btn"
                    onClick={_sendVerificationCode}
                  >
                    {requestingOtp ? (
                      <ClipLoader color="white" size={30} />
                    ) : (
                      "Send Code"
                    )}
                  </button>
                ) : (
                  <button className="button-disable">Send Code</button>
                )}
              </div>

              {selectedOption && (
                <div>
                  <div className="row d-flex justify-content-center mt-2 custom-height">
                    {/* <div className="col-8"> */}
                    <CustomInput
                      label='Verification Code'
                      //   placeholder="Verification Code"
                      value={otp}
                      onChange={_onChangeTextChange}
                      type="text"
                    />
                    {/* </div> */}
                    {otpError != "" && (
                      <div className="text-danger text-center">{otpError}</div>
                    )}
                  </div>


                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      className="primary-btn"
                      onClick={_onSubmit}
                      disabled={btnActive}
                    >
                      Submit
                    </Button>
                  </div>

                  <div className="row d-flex justify-content-center w-10">
                    <button className="button-secondary" onClick={handleResendOTP}>
                      {requestingResendOtp ? (
                        <ClipLoader color="black" size={30} />
                      ) : (
                        "Resend Code"
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="row">
                <p
                  className="form-labelmfa"
                  style={{ textAlign: "center", marginTop: "15px" }}
                >
                  2-step verification keeps your account safe and protects your
                  data.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ChangeVerificationn