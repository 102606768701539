import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function BaaAgreement({
  show,
  onHide,
  isChecked,
  onCheckboxChange,
}: any) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = () => {
    const isChecked = !checked;
    setChecked(isChecked);
    onCheckboxChange(isChecked);
  };

  return (
    <Modal className="baModal modal-kyc" show={show} onHide={onHide} animation={true} size="lg">
      {/* <Modal.Header
        className="header-popup"
        closeButton 
        placeholder={null}
      >
      </Modal.Header> */}
      <div className="header-popup modal-header">
        <button onClick={onHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
      </div>
      <Modal.Title>
        <h6 className="assessques h5 thick-heading text-left mt-4">
          Business Associate Agreement:
        </h6>
      </Modal.Title>
      <Modal.Body>

        <div className="text-justify">
          <p className="graphText thick-heading">BACKGROUND</p>

          <p className="graphText">
            RPM by OtisHealth applications are powered by the OtisHealth technology platform.  All healthcare providers and other HIPAA covered entities must sign a HIPAA Business Associate Agreement with OtisHealth, Inc. prior to their use of RPM by OtisHealth applications.
          </p>

          <p className="graphText thick-heading text-center ptb-10">
            HIPAA Business Associate Agreement
          </p>

          <p className="graphText">
            <strong className="thick-heading text-center">
              THIS BUSINESS ASSOCIATE AGREEMENT
            </strong>{" "}
            (&ldquo;Agreement&rdquo;) is entered into by and between the
            healthcare provider agreeing to the terms below (&ldquo;Covered
            Entity&rdquo;) on behalf of itself, and its current and future
            subsidiaries and affiliates,
          </p>

          <p className="graphText text-center">and</p>

          <p className="graphText">
            OtisHealth, Inc. (&ldquo;Company&rdquo;), including all current and
            future lines of business, affiliates, parents and subsidiaries and
            their respective officers, directors, employees, agents and
            representatives. Covered Entity and Company may have entered into
            various arrangements and may in the future enter into additional
            arrangements (collectively, the &ldquo;Contracts&rdquo;) pursuant to
            which Company provides various items or services to Covered Entity.
            This Agreement modifies and supplements the terms of use of
            the Contracts, and the provisions set forth herein shall be deemed a
            part of the Contract(s). This BAA will be effective when Covered
            Entity clicks to accept this Agreement (the &quot;BAA Effective
            Date&quot;).
          </p>

          <p className="graphText  ptb-10">
            Covered Entity and Company mutually agree to the terms of this
            Agreement to comply with the requirements of the Standards for
            Security and Privacy of Individually Identifiable Information (the
            &ldquo;Security and Privacy Regulations&rdquo;), as applicable,
            under the Health Insurance Portability and Accountability Act of
            1996 (&ldquo;HIPAA&rdquo;), as amended, as well as with the Health
            Information Technology for Economic &amp; Clinical Health Act
            (&ldquo;HITECH&rdquo;), Subtitle D-Privacy
            (&sect;&sect;13400-13424), as part of the American Recovery and
            Reinvestment Act of 2009, as amended.
          </p>

          <p className="graphText pb-10">
            This Agreement applies to the extent Company is acting as a Business
            Associate to create, receive, maintain, or transmit PHI and to the
            extent Covered Entity is deemed under HIPAA to be acting as a
            Covered Entity.
          </p>

          <p className="graphText">
            <strong>Effect</strong>. This BA Agreement amends, restates and
            replaces in its entirety any prior business associate agreement
            between the parties. This BA Agreement supersedes all prior or
            contemporaneous written or oral contracts or understandings between
            Covered Entity and Company relating to their compliance with health
            information confidentiality laws and regulations, including HIPAA
            and HITECH. Company and Covered Entity have a business relationship
            such that Covered Entity may be deemed to be a covered entity, and
            in conducting such activities on behalf of Covered Entity, Company
            may be deemed a business associate of Covered Entity. Covered Entity
            wishes to disclose certain information to Company pursuant to the
            terms of this Agreement, some of which may constitute Protected
            Health Information (&ldquo;PHI&rdquo;) (as defined below).
          </p>

          <p className="graphText ptb-10">
            <strong>Purpose</strong>. Protected Health Information. The purpose
            of this Agreement is to provide assurances regarding Covered Entity
            responsibilities to maintain strict confidentiality under applicable
            federal and state laws and regulations relating to our
            member&rsquo;s medical information, financial information, and other
            patient identifiable health information to which Company gains
            access pursuant to the Contracts (collectively &ldquo;Protected
            Health Information&rdquo;). For purposes of this Agreement,
            Protected Health Information shall be defined consistent with 45
            CFR, Section 164.501. The provisions of this Agreement are
            specifically intended to meet the business associate contract
            requirements of the HIPAA privacy standards spelled out in Section
            45 CFR, Section 164.504.
          </p>

          <p className="graphText">
            Covered Entity and Company intend to protect the privacy and provide
            for the security of PHI disclosed to Company pursuant to this
            Agreement in compliance with the HIPAA Security and Privacy
            Regulations and HITECH.
          </p>

          <p className="graphText  ptb-10">
            HIPAA Security and Privacy Regulations and HITECH required Covered
            Entity to enter into a contract containing specific requirements
            with Company prior to the disclosure of PHI, as set forth in this
            Agreement.
          </p>

          <p className="assessques thick-heading">
            A. <em>Definitions</em>
          </p>

          <ol className="graphText ol  pb-10" type="1">
            <li>
              The following terms used in this Agreement shall have the same
              meaning as those terms in the HIPAA Rules: Breach, Data Aggregation,
              Designated Record Set, Disclosure, Health Care Operations,
              Individual, Minimum Necessary, Notice of Privacy Practices,
              Protected Health Information, Required By Law, Secretary, Security
              Incident, Subcontractor, Unsecured Protected Health Information, and
              Use.
            </li>

            <li>
              {" "}
              <em>
                <u>Breach</u>
              </em>
              . &ldquo;Breach&rdquo; has the meaning given in 45 CFR &sect;
              164.402.
            </li>

            <li>
              {" "}
              <em>
                <u>Business Associate</u>
              </em>
              . &ldquo;Business Associate&rdquo; shall generally have the same
              meaning as the term &ldquo;business associate&rdquo; at 45 CFR
              &sect; 160.103, and in reference to the party to this agreement,
              shall mean The Medical Protective Company.
            </li>

            <li>
              {" "}
              <em>
                <u>Covered Entity</u>
              </em>
              . &ldquo;Covered Entity&rdquo; shall generally have the same meaning
              as the term &ldquo;covered entity&rdquo; at 45 CFR &sect; 160.103.
            </li>

            <li>
              {" "}
              <em>
                <u>Designated Record Set</u>
              </em>
              . &ldquo;Designated Record Set&rdquo; shall have the meaning set out
              in its definition at 45 C.F.R. &sect; 164.501.
            </li>

            <li>
              {" "}
              <em>
                <u>HIPAA</u>
              </em>
              . &ldquo;HIPAA&rdquo; means the Health Insurance Portability and
              Accountability Act of 1996, as amended, and its implementing rules and regulations, including the
              HIPAA Breach Notification Rule, the HIPAA Privacy Rule, and the
              HIPAA Security Rule.
            </li>

            <li>
              {" "}
              <em>
                <u>HIPAA Rules</u>
              </em>
              . &ldquo;HIPAA Rules&rdquo; shall mean the Privacy, Security, and
              Breach Notification, and &ldquo;HIPAA Breach Notification
              Rule&rdquo; means the Breach Notification for Unsecured Protected
              Health Information issued by HHS, 45 CFR Parts 160 and 164 (Subparts
              A and D).
            </li>

            <li>
              {" "}
              <em>
                <u>HIPAA Omnibus Rule</u>
              </em>
              . &ldquo;HIPAA Omnibus Rule&rdquo; means the Modifications to the
              HIPAA Privacy, Security, Enforcement, and Breach Notification Rules
              Under the Health Information Technology for Economic and Clinical
              Health Act and the Genetic Information Nondiscrimination Act; Other
              Modifications to the HIPAA Rules; Final Rule issued by HHS, 45 CFR
              Parts 160 and 164.
            </li>

            <li>
              {" "}
              <em>
                <u>HIPAA Privacy Rule</u>
              </em>
              . &ldquo;HIPAA Privacy Rule&rdquo; means the Standards for Privacy
              of Individually Identifiable Health Information regulations issued
              by HHS, 45 CFR Parts 160 and 164 (Subparts A and E).
            </li>

            <li>
              {" "}
              <em>
                <u>HIPAA Security Rule</u>
              </em>
              . &ldquo;HIPAA Security Rule&rdquo; means the Security Standards for
              the Protection of Electronic Protected Health Information issued by
              HHS, 45 CFR Parts 160 and 164 (Subparts A and C).
            </li>

            <li>
              {" "}
              <em>
                <u>HITECH</u>
              </em>
              . &ldquo;HITECH&rdquo; means the Health Information Technology for
              Economic and Clinical Health Act, Title XIII of Division A of the
              American Recovery and Reinvestment Act of 2009 and its implementing
              regulations.
            </li>

            <li>
              {" "}
              <em>
                <u>PHI</u>
              </em>
              . &ldquo;PHI&rdquo; or &ldquo;Protected Health Information&rdquo;
              and &ldquo;Electronic PHI&rdquo; have the respective meanings given
              in 45 CFR &sect; 160.103, except that each is limited to PHI (and
              Electronic PHI) that Company creates, receives, maintains, transmits
              or collects for or on behalf of Covered Entity, and meaning as the
              term &ldquo;protected health information&rdquo; in 45 CFR &sect;
              164.501, limited to the information created or received by Company
              from or on behalf of Covered Entity.
            </li>

            <li>
              {" "}
              <em>
                <u>Required by Law</u>
              </em>
              . &ldquo;Required by Law&rdquo; has the meaning given in 45 CFR
              &sect; 164.103.
            </li>

            <li>
              {" "}
              <em>
                <u>Subcontractor</u>
              </em>
              . &ldquo;Subcontractor&rdquo; has the meaning given in 45 CFR &sect;
              160.103.
            </li>

            <li>
              {" "}
              <em>
                <u>Unsecured PHI</u>
              </em>
              . &ldquo;Unsecured PHI&rdquo; has the meaning given in 45 CFR &sect;
              164.402.
            </li>
          </ol>

          <p className="assessques thick-heading">
            B. <em>Obligations and Activities of Company</em>
          </p>

          <p className="graphText">Company agrees to:</p>

          <ol className="graphText ol pb-10" type="1">

            <li>
              Company agrees to fully comply with the requirements under the
              Privacy Rule applicable to &ldquo;business associates,&rdquo; as
              that term is defined in the Privacy Rule and not use or further
              disclose Protected Health Information other than as permitted or
              required by this Agreement, Service Contracts, or as Required by
              Law. In case of any conflict between this Agreement and Service
              Contracts, this Agreement shall govern;
            </li>

            <li>
              Not use or disclose Protected Health Information other than as
              permitted or required by the Agreement or as required by law and to
              Company agrees it must limit any use, disclosure, or request for use
              or disclosure of Protected Health Information to the minimum amount
              necessary to accomplish the intended purpose of the use, disclosure,
              or request in accordance with the requirements of the Privacy Rule;
            </li>

            <li>
              Use appropriate procedural, physical, administrative and
              electronic safeguards, and comply with Subpart C of 45 CFR Part 164,
              to prevent use or disclosure of Protected Health Information other
              than as provided for in this Agreement. Said safeguards shall
              include, but are not limited to, providing adequate training to its
              employees to ensure compliance with this BAA, requiring employees,
              agents and subcontractors to agree to use or disclose Protected
              Health Information only as permitted or required by this Agreement
              and taking related disciplinary actions for inappropriate use or
              disclosure as necessary;
            </li>

            <li>
              Report to Covered Entity any use or disclosure of Protected
              Health Information not provided for by the Agreement of which it
              becomes aware, including breaches of unsecured protected health
              information as required at 45 CFR 164.410, and any security incident
              of which it becomes aware;
            </li>

            <li>
              In accordance with 45 CFR 164.502(e)(1)(ii) and 164.308(b)(2), if
              applicable, require any agent, including a subcontractor, to whom it
              provides Protected Health Information received from, created or
              received by, Company on behalf of Covered Entity or that carries out
              any duties for the Company involving the use, custody, disclosure,
              creation of, or access to Protected Health Information, to agree, by
              written contract with Company, to the same restrictions and
              conditions that apply through this Agreement to Company with respect
              to such information;
            </li>

            <li>
              Require its employees, agents, and subcontractors to immediately
              report, to Company, any use or disclosure of Protected Health
              Information in violation of this Agreement and to report to Covered
              Entity any use or disclosure of the Protected Health Information not
              provided for by this Agreement;
            </li>

            <li>
              Mitigate, to the extent practicable, any harmful effect that is
              known to Company of a use or disclosure of Protected Health
              Information by Company in violation of the requirements of this
              Agreement;
            </li>

            <li>
              If Company receives Protected Health Information from Covered
              Entity in a Designated Record Set, then Company agrees to provide
              access, at the request of Covered Entity, to Protected Health
              Information in a Designated Record Set, to Covered Entity or, as
              directed by covered Entity, to an Individual in order to meet the
              requirements under 45 CFR &sect; 164.524, provided that Company
              shall have at least FIVE (5) days from Covered Entity notice to
              provide access to, or deliver such information;
            </li>

            <li>
              If Company receives Protected Health Information from Covered
              Entity in a Designated Record Set, then Company agrees to make any
              amendments to Protected Health Information in a Designated Record
              Set that the Covered Entity directs or agrees to pursuant to the 45
              CFR &sect; 164.526 at the request of Covered Entity or an
              Individual, and in the time and manner designated by Covered Entity,
              provided that Company shall have at least FIFTEEN (15) days from
              Covered Entity notice to make an amendment;
            </li>

            <li>
              Document disclosures of Protected Health Information and
              information related to such disclosures as would be required for
              Covered Entity to respond to a request by an Individual for an
              accounting of disclosure of Protected Health Information in
              accordance with 45 CFR &sect;164.528;
            </li>

            <li>
              To the extent Company is to carry out one or more of Covered
              Entity&#39;s obligation(s) under Subpart E of 45 CFR Part 164,
              comply with the requirements of Subpart E that apply to Covered
              Entity in the performance of such obligation(s);
            </li>

            <li>
              Company agrees to make its internal practices, books, and
              records including policies and procedures and Protected Health
              Information, relating to the use and disclosure of Protected Health
              Information received from, created by or received by Company on
              behalf of, Covered Entity available to the Covered Entity or to the
              Secretary of the United States Department of Health in Human
              Services or the Secretary&rsquo;s designee, in a time and manner
              designated by the Covered Entity or the Secretary, for purposes of
              determining Covered Entity&rsquo;s or Company&rsquo;s compliance
              with the Privacy Rule;
            </li>

            <li>
              Company agrees to provide Covered Entity or an Individual, in
              time and manner designated by Covered Entity, information collected
              in accordance with this Agreement, to permit Covered Entity to
              respond to a request by an Individual for and accounting of
              disclosures of Protected Health Information in accordance with 45
              CFR &sect; 164.528, provided that Company shall have at least
              FIFTEEN (15) days from Covered Entity notice to provide access to,
              or deliver such information which shall include, at minimum, (a)
              date of the disclosure; (b) name of the third party to whom the
              Protected Health Information was disclosed and, if known, the
              address of the third party; (c) brief description of the disclosed
              information; and (d) brief explanation of the purpose and basis for
              such disclosure;
            </li>

            <li>
              Company agrees to adequately and properly maintain all Protected
              Health Information received from, or created or received on behalf
              of, Covered Entity, document subsequent uses and disclosures of such
              information by Company as may be deemed necessary and appropriate by
              the Covered Entity, and provide Covered Entity with reasonable
              access to examine and copy such records and documents during normal
              business hours of Company; and
            </li>

            <li>
              If Company receives a request from an individual for a copy of
              the individual&rsquo;s Protected Health Information, and the
              Protected Health Information is in the sole possession of the
              Company, Company will provide the requested copies to the individual
              and notify the Covered Entity of such action. If Company receives a
              request for Protected Health Information in the possession of the
              Covered Entity, or receives a request to exercise other individual
              rights as set forth in the Privacy Rule, Company shall notify
              Covered Entity of such request and forward the request to Covered
              Entity. Company shall then assist Covered Entity in responding to
              the request.
            </li>

          </ol>

          <p className="assessques thick-heading">
            C. <em>Permitted Uses and Disclosures by Company</em>
          </p>

          <ol className="graphText ol pb-10" type="1">
            <li>
              Company may only use or disclose protected health information as
              necessary to perform any services necessary or required as Covered
              Entity&#39;s Business Associate.
            </li>

            <li>
              Company may use or disclose Protected Health Information as
              required by law.
            </li>

            <li>
              Company agrees to make uses and disclosures and requests for
              Protected Health Information subject to the following minimum
              necessary requirements: as necessary or required in order to provide
              Covered Entity services.
            </li>

            <li>
              Company may not use or disclose protected health information in a
              manner that would violate Subpart E of 45 CFR Part 164 if done by
              Covered Entity, except with regards to the data aggregation,
              management, administration and legal responsibilities of the
              Company.
            </li>

            <li>
              Company may use Protected Health Information for the
              Company&#39;s proper management and administration or to carry out
              the legal responsibilities of the Company.
            </li>

            <li>
              Company may disclose Protected Health Information for the proper
              management and administration of Company or to carry out the legal
              responsibilities of the Company, provided the disclosures are
              required by law, or Company obtains reasonable assurances from the
              person to whom the information is disclosed that the information
              will remain confidential and used or further disclosed only as
              required by law or for the purposes for which it was disclosed to
              the person, and the person notifies Company of any instances of
              which it is aware in which the confidentiality of the information
              has been breached.
            </li>

            <li>
              Company may provide data aggregation services relating to the
              health care operations of Covered Entity.
            </li>
          </ol>

          <p className="assessques thick-heading">
            D.{" "}
            <em>
              Provisions for Covered Entity to Inform Company of Privacy
              Practices and Restrictions
            </em>
          </p>

          <p className="graphText">Covered Entity shall notify Company of:</p>

          <ol className="graphText ol pb-10" type="1">

            <li>
              any limitation(s) in Covered Entity&rsquo;s notice of privacy
              practices under 45 CFR 164.520;
            </li>

            <li>
              of any changes in, or revocation of, the permission by an
              individual to use or disclose his or her protected health
              information; or,
            </li>

            <li>
              any restriction on the use or disclosure of protected health
              information that Covered Entity has agreed to or is required to
              abide by under 45 CFR 164.522, to the extent that Company&#39;s use
              or disclosure of protected health information will be affected.
            </li>



          </ol>

          <p className="assessques thick-heading">
            E. <em>Term and Termination</em>
          </p>

          <ol className="graphText ol pb-10" type="1">

            <li>
              <em>Term</em>. This Agreement shall continue in force for one
              calendar year from date of execution below, or so long as any
              underlying contract between the Covered Entity and Company remains
              in force.
            </li>

            <li>
              <em>Termination for Cause</em>. The Covered Entity shall provide
              written notice if it determines that Company has breached any
              material provision of this Agreement. The written notice must
              contain the facts necessary for Company to evaluate and cure the
              alleged breach. If the breach is not cured within 30 days, the
              Covered Entity may immediately terminate this Agreement.
            </li>

            <li>
              <em>Obligations of Company Upon Termination</em>. Upon
              termination of this Agreement for any reason, Company, with respect
              to protected health information received from Covered Entity, or
              created, maintained, or received by Company on behalf of Covered
              Entity, shall:
            </li>

            <ol className="graphText ol" type="a">
              <li>
                Retain only that Protected Health Information which is necessary
                for Company to continue its proper management and administration or
                to carry out its legal responsibilities;
              </li>

              <li>
                Destroy the remaining Protected Health Information that Company
                still maintains in any form;
              </li>

              <li>
                Continue to use appropriate safeguards and comply with Subpart C
                of 45 CFR Part 164 with respect to electronic protected health
                information to prevent use or disclosure of the protected health
                information, other than as provided for in this Section, for as long
                as Company retains the protected health information;
              </li>

              <li>
                Not use or disclose the protected health information retained by
                Company other than for the purposes for which such protected health
                information was retained and subject to the same conditions set out
                at above which applied prior to termination; and
              </li>

              <li>
                Destroy the Protected Health Information retained by Company when
                it is no longer needed by Company for its proper management and
                administration or to carry out its legal responsibilities.
              </li>
            </ol>

            <li>
              <em>Survival</em>. The obligations of Company under this Section
              shall survive the termination of this Agreement.
            </li>

          </ol>

          <p className="assessques thick-heading">
            <strong>
              F. <em>Miscellaneous</em>
            </strong>
          </p>

          <ol className="graphText ol">
            <li>
              <em>Regulatory References</em>. A reference in this Agreement to
              a section in the HIPAA Rules means the section as in effect or as
              amended.
            </li>

            <li>
              <em>Amendment; HITECH Act Compliance</em>. &nbsp;The Parties
              acknowledge that the HITECH Act includes significant changes to the
              Privacy Rule and the Security Rule. The privacy subtitle of the
              HITECH Act sets forth provisions that significantly change the
              requirements for business associates and the agreements between
              business associates and covered entities under HIPAA and these
              changes may be further clarified in forthcoming regulations and
              guidance. Each Party agrees to comply with the applicable provisions
              of the HITECH Act and any HHS regulations issued with respect to the
              HITECH Act. The Parties agree to take such action as is necessary to
              amend this Agreement from time to time as is necessary for
              compliance with the requirements of the HITECH Act, HIPAA Rules and
              any other applicable law.
            </li>

            <li>
              <em>Interpretation</em>. Any ambiguity in this Agreement shall be
              interpreted to permit compliance with the HIPAA Rules.
            </li>
          </ol>

          <div className="d-flex align-items-center">
            <div className="form-check check-box">
              <input
                checked={checked}
                // onClick={onClick}
                onChange={handleCheckboxChange}
                className="form-check form-check-input"
                type="checkbox"
                id="gridCheck1"
              />
            </div>
            <p className="ml-3 graphText mt-5">
              {/* I agree to the{" "}
              <span className="textColor">Business Associate Agreement.</span> */}
              By checking this box, the Covered Entity agrees and accepts this Business
              Associate Agreement. The Covered Entity understands and agrees that it is electronically
              signing this Business Associate Agreement as of the date this signature is submitted.
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
