const APIEndpoint = {
    idMeLogin: "provider/idme-signin",
    idMeRegistration: "provider/acceptance",
    login: "staff/login",
    logOut: "auth/logout",
    verifyOTP: "auth/verify-otp",
    forgotPassword:"auth/forgot-password",
    confirmForgetPassword:'auth/confirm-forgot-password',
    createProvider :"provider",
    resendLoginOTP :"auth/resend-otp",
    changePassword: "changePassword",
    getGender: "gender",
    enrollPatient: "provider/enroll-new-patient",
    getRelationship : "relationship",
    missingAssessments:'provider/missing-assessments',
    recentAssessments:'provider/recent-complete-assessments',
    search:'provider/search-patient?search=',
    patientMissingAssessment:'provider/patient-missing-assessments',
    patientCompleteAssessments:'provider/patient-complete-assessments',
    assessmentList : 'assessments',
    postAssessment :'assessments/assign',
    allPatient:'provider/view-all-patients',
    getManageAssignedAssessment:'assessments/assign',
    sendMessage:'messages',
    getMessageList:'messages/sendmessage?patientUid=',
    // patientDashbard:''
    getPatientByUid: 'provider/patient?patientUid=',
    upsertPatientCaregiver: 'provider/upsert-patientcare',
    getFrequency: 'frequencies',
    logging: 'logging',
    countryCode: "countryCodeList",
    getUserGuides: "communities/userguides/all",
    staff:"staff",
    recentPatientAnalysis:'provider/recent-patient-analysis',
    getVerified: 'auth/getVerified',
    getVerifiedConfirm:'auth/getVerifiedConfirm',
    Monitoring: 'monitoring',
    getMeasurement: 'measurements',
    getPaymentStatus: 'provider/payment-status',
    getPatientMeasurement: 'provider/patient-measurement',
    patientAlerts:'provider/patient-alerts',
    logAnalysis: 'monitoring/log-analysis',
    deviceTraining: 'device-training',
    reportArchives: 'monitoring/report-archives',
    manageBilling: 'provider/manage-billing',
    fetchTestData:'provider/add-patient-reading',
    getReportPdfData:'monitoring/download-report-archives'
}

export { APIEndpoint }