import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import Select from "react-select";
import Swal from "sweetalert2";
import { convertDateFormat1 } from "../../app/utils/dateHelper";
import { APIEndpoint } from "../../config/apiendpoint";
import AppServices from "../../services/appServices";
import {
  selectStyle
} from "../theme";

export default function NewTraining({
  patientData,
  show,
  onHide,
  editMode,
  staffList,
  measurementTypeList,
  getTrainingList
}: any) {
  const _appServices = new AppServices();
  const [measurementId, setMeasurementId] = useState("");
  const [dueDate, setDueDate] = useState<any>("");
  const [measurementError, setMeasurementError] = useState("");
  const [dueDateError, setDueDateError] = useState("");
  const [device, setDevice] = useState("");
  const [deviceError, setDeviceError] = useState("");
  const [btnActive, setIsBtnActive] = useState(false);
  const [staffId, setStaffId] = useState("");

  const measurementOptions = measurementTypeList?.map((option: any) => ({
    value: option.id,
    label: option.name,
  }));

  const staffMembers = staffList?.map((option: any) => ({
    value: option.uuid,
    label: option.firstName + ' ' + option.lastName,
  }));




  const handleSave = async () => {
    setMeasurementError("");
    setDueDateError("");
    setIsBtnActive(true);



    if (!measurementId) {
      setMeasurementError("Measurement is required");
    }

    if (!dueDate) {
      setDueDateError("Due Date is required");
    }
    if (!device) {
      setDeviceError("Device is required");
    }

    if (!measurementId || !dueDate) {
      setIsBtnActive(false);
      return;
    }
    console.log('measurementId', measurementId, Number(measurementId))
    let postJson = {
      patientId: patientData.uuid,
      measurementId: Number(measurementId),
      deviceName: device,
      staffUid: staffId,
      dateOfTraining: convertDateFormat1(dueDate),

    };
    try {
      const resp = await _appServices.postData(
        `${APIEndpoint.deviceTraining}?patientUid=${patientData.uuid}`,
        postJson
      );
      console.log(resp)
      if ((resp.status === 201 || resp.status === 200) && resp.success === true) {
        setMeasurementId('')
        setDueDate(new Date());
        _appServices.showFlashMsg('success', 'Success', resp?.message, false);
        _appServices.logData({
          Severity: 'Info',
          Activity: `User assigned assessment for patient.`,
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        })
        getTrainingList();
        handleHide();
      } else {
        const message = _appServices.getErrorMessage(resp)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message,
        });
      }
      setIsBtnActive(true);
    } catch (error: any) {
      const message = _appServices.getErrorMessage(error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message || "Something went wrong.",
      })
      _appServices.logData({
        Severity: 'Error',
        Activity: `User assigned assessment for patient.`,
        ActivityStatus: 'Failure',
        ActivityResponse: 'Data Submitted',
        Operation: 'User Activity',
      })
    }

    setIsBtnActive(false);
  };

  const handleHide = () => {
    setMeasurementError("");
    setDueDateError("");
    setDeviceError("");
    setDueDate("");
    setDevice("");
    setMeasurementId("");
    setStaffId("")
    onHide();
  };

  useEffect(() => {

  }, []);

  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      setMeasurementId(selectedOption.value);
    }
    setMeasurementError("");
  };
  const handleStaffChange = (selectedOption: any) => {
    if (selectedOption) {
      setStaffId(selectedOption.value);
    }
    setMeasurementError("");
  };



  const handleDateChange = (date: any) => {
    const d = new Date(date);

    setDueDate(d);
    setDueDateError("");
  };

  const handleDefaultDate = () => {
    const d = new Date();

    setDueDate(d);
    setDueDateError("");
    return d
  }



  const onDeviceChange = (e: any) => {
    setDevice(e.target.value)
    setDeviceError('')
  }

  return (
    <div>
      <Modal
        className="assignModal assign-assessment-pop-model"
        show={show}
        animation={false}
        onHide={handleHide}
      >
        <div className="header-popup modal-header">
          <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>
        <Modal.Body className="body-popup">
          <div className="modal-body-inner">
            <p className="pop-up-heading">Add Training</p>
            <br />
            <p className="mb-0" >Patient</p>
            <div className="custom-form-notEditable">
              {`${patientData?.firstName} ${patientData?.lastName}`}
            </div>
          </div>
          <div className="select-input-wrap">
            <p >Measurement</p>
            <Select
              name="chooseMeasurement"
              value={measurementOptions?.find(
                (option: any) => option.value === measurementId
              )}
              onChange={handleSelectChange}
              options={measurementOptions}
              styles={selectStyle}
              isSearchable
              className={`${measurementError ? 'error-adMonitor' : ''}`}
            />
            {measurementError && (
              <p className="text-danger">{measurementError}</p>
            )}
          </div>

          <div className="select-input-wrap">
            <p className="mt-3">Device</p>
            <input
              type="text"
              className={`form-control input-f input-box ${deviceError ? 'error-adMonitor' : ''}`}
              value={device}
              onChange={(e) => onDeviceChange(e)}
            />
            {deviceError && <p className="text-danger">{deviceError}</p>}
          </div>
          <div className="select-input-wrap">
            <p className="mt-3">Staff Member</p>
            <Select
              name="Choose Staff"
              value={staffMembers?.find(
                (option: any) => option.value === staffId
              )}
              onChange={handleStaffChange}
              options={staffMembers}
              styles={selectStyle}
              isSearchable
            />
          </div>
          <div className="row">
            <div className="col-12 pl-0 pr-0">
              <p className="mt-3">Date of Training</p>
              <DatePicker
                className="custom-form-input-date1 react-date-picker__wrapper "
                value={dueDate ? new Date(dueDate) : handleDefaultDate()}
                onChange={(date: any) => {
                  handleDateChange(date);
                }}
                clearIcon={null}
                format="MM/dd/yyyy"
              />
              {dueDateError && <p className="text-danger">{dueDateError}</p>}
            </div>
            <div className="d-flex flex-row mt-5">
              <Button
                className="primary-btn"
                onClick={handleSave}
                disabled={btnActive}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
