import React, { useRef, useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { APIEndpoint } from '../../../../config/apiendpoint';
import AppServices from '../../../../services/appServices';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { notifyError, notifyInfo, notifySuccess } from '../../../../theme/Toaster/quickToasts';
import { TailSpin } from 'react-loader-spinner';

export default function Otpverify() {

  const _appServices = new AppServices()
  const { setSessionDetails, isLoggedIn, setIsLoggedIn } = useContext(UserContext)
  const location = useLocation();
  const sendOtpVia = location?.state.res.data[0].sendOtpVia;
  const email = location?.state?.email || ''
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleInput = (e: React.FormEvent<HTMLInputElement>, index: number) => {
    const target = e.currentTarget;
    const value = target.value.slice(0, 1);

    target.value = value;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);


    // Move to next input if a character is entered and not at the last input
    if (value && index < 5) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const maskMobileNumber = (number: any) => {
    const regex = /^(\d{7})(\d{3})$/;
    if (number && regex.test(number)) {
        const maskedPart = 'x'.repeat(7);
        const lastThreeDigits = number.slice(-3);
        return `${maskedPart}${lastThreeDigits}`;
    }
    return number;
};
const maskedSendOtpVia = maskMobileNumber(sendOtpVia);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text').slice(0, 6).split('');
    const newOtp = [...otp];
    paste.forEach((char, i) => {
      if (i < 6) {
        newOtp[i] = char;
        if (inputRefs.current[i]) {
          inputRefs.current[i]!.value = char;
        }
      }
    });
    setOtp(newOtp);

    if (inputRefs.current[paste.length - 1]) {
      inputRefs.current[paste.length - 1]!.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      const prevInput = inputRefs.current[index - 1];
      if (prevInput) {
        prevInput.focus();
        const newOtp = [...otp];
        newOtp[index - 1] = '';
        setOtp(newOtp);
        prevInput.value = '';
      }
    }
  };

  function handleVerify() {
    const enteredOtp = otp.join('');
    let postjson = { email: email, otp: enteredOtp }
    if (email && email != '' && enteredOtp && enteredOtp != '') {
      setLoading(true)
      _appServices.postData(`${APIEndpoint.verifyOTP}`, postjson).then(res => {
        setLoading(false)
        console.log('res', res)
        if (res.status === 200 && res.success) {
          let _res = res.data[0];
          let sessionObj = {
            authToken: _res?.tokens,
            isLoggedIn: true,
            ..._res
          }
          setIsLoggedIn(true)
          localStorage.setItem('sessionObject', JSON.stringify(sessionObj));
          setSessionDetails(sessionObj);
          setTimeout(() => {
            navigate("/user-panel/dashboard")
          }, 100);
        } else {
          setLoading(false)
          if (typeof res.message == "object") {
            if (res.message && res.message.length) {
              notifyError(res.message['0'])
            } else {
              notifyError("Something went wrong!")
            }
          } else {
            notifyError(res.message)
          }
        }
      }, err => {
        console.log('err', err)
        setLoading(false)
        notifyError(err.message)
      });
    } else {
      notifyError((!email || email == "") ? "Email is not blank" : (!enteredOtp || enteredOtp == "") ? "OTP is not blank" : "Something went wrong!")
    }
  }

  const handleResendCode = async () => {
    setLoading(true)
    let postjson = { email: email }
    _appServices.postData(`${APIEndpoint.resendLoginOTP}`, postjson).then(res => {
      setLoading(false)
      if (res.status === 200 && res.success) {
        notifySuccess("OTP resent successfully!")
      } else {
        notifyError("Can't resend the Otp! please try again")
      }
    }, err => {
      setLoading(false)
      notifyError(err.message)
    });
  }


  return (
    <div className='container top-margin-container'>
      <div className='d-flex flex-column align-items-center p-2 '>
        <h6 className='h6 thin-heading headlineColor'>Enter 6-digit Passcode</h6>
        <p className='heading assessques mt-2 mb-4'>We have sent a 6-digit passcode via text <br />to : {maskedSendOtpVia}</p>

        <div className='d-flex flex-row'>
          {[...Array(6)].map((_, index) => (
            <input
              className='otp-box'
              key={index}
              name={`otp${index + 1}`}
              type="text"
              inputMode="text"
              maxLength={1}
              ref={el => inputRefs.current[index] = el}
              onInput={(e) => handleInput(e, index)}
              onPaste={handlePaste}
              onKeyDown={(e) => handleKeyDown(e, index)}
              autoFocus={index === 0}
            />
          ))}
        </div>

        <button type="button" onClick={() => { handleVerify() }} className="btn btn-lg mt-4 primary-btn login-btn" disabled={loading}>
          <span>Continue</span>
          <TailSpin
            visible={loading}
            height="20"
            width="20"
            color="#03A84E"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="tail-spin-loading"
          /></button>
        <p className='graphText thick-heading textColor mt-4' onClick={handleResendCode} style={{ cursor: "pointer" }}>click to resend code</p>
      </div>
      <ToastContainer />
    </div>
  )
}
