import React,{useContext, useEffect} from 'react';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';


export const NoPageFound = () => {
  const navigate = useNavigate();
  const sessionObj = localStorage.getItem("sessionObject")||''


  return (
    <div className='container top-margin-container'>
      <h1>No Page Found</h1>
    </div>
  );
};
