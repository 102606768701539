import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DashboardTemplate from '../../../../theme/Dashboard/DashboardTemplate';
import AppServices from '../../../../services/appServices';
import { APIEndpoint } from '../../../../config/apiendpoint';
import Select from 'react-select'
import { selectStyleSearch } from '../../../../theme/theme';
import { convertDate, convertDateFormat } from '../../../utils/dateHelper';
import NewAssessment from '../../../../theme/CreateAssessment/assignNewAssessment';
import SendMessageModal from '../../../component/SendMessageModal/SendMessageModal';
import MessageHistory from '../../../../theme/Table/MessageHistoryTable';
import PatientHeader from '../../../component/PatientHeader/PatientHeader';

export default function ViewMessages() {
  const _appServices = new AppServices()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams();
  const [pateintLoading, setPatientLoading] = useState(false)
  const [sendMessage, setSendMessage] = useState('');
  const [messageList, setMessageList] = useState([])
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedOption, setSelectedOption] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [messageListLoading, setMessageListLoading] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [show, setShow] = useState(false);

  const [patientDatas, setPatientDatas] = useState<any>(location && location.state)

  const getMessageList = async () => {
    const patId = patientDatas?.uuid
    console.log("***patId**",patId)
    setMessageListLoading(true)
    try {
      const resp = await _appServices.getData(`${APIEndpoint.getMessageList}${patId}`);
      console.log("message resp", resp)
      if (resp.status === 200 && resp.success == true) {
        setMessageList(resp.data)
        setMessageListLoading(false)
      }
    } catch (error) {
      setMessageListLoading(false)
      console.error("Error fetching datad:", error);
    }
  };

  useEffect(() => {
    console.log("***location***", location)
    getMessageList();
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await _appServices.getData(`${APIEndpoint.search}${searchQuery}`);
        setSearchResults(resp.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }

    };

    if (searchQuery.trim() !== '') {
      fetchData();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handlePatientProfile = () => {
    navigate(-1)
  }

  // const handleInputChange = (value: any, { action }: any) => {

  //   setSelectedOption(null)
  //   if (action === 'input-change') {
  //     setSearchQuery(value);
  //     setSelectedOption(null)
  //   }
  // };

  // function formatSearchResults(results: any[]) {
  //   return results.map((result: { firstName: any; uuid: any, dob: any, lastName: any }) => ({
  //     label: `${result.lastName}, ${result.firstName} DOB: ${convertDate(result.dob)}`,
  //     value: result.uuid,
  //   }));
  // }

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const handleCloseMessage = () => {
    setShowMessage(false)
  }

  const handleShowMessage = () => {
    setShowMessage(true)
  }

  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => { setShow(false) };
  const handleCloseModal = () => {
    console.log('Modal closed');
  };

  return (
    (patientDatas && patientDatas.uuid) ? <>
      {/* <div className='home'> */}
      <PatientHeader
        patientData={patientDatas}
        onCloseModal={handleCloseModal}
        sendMessageSuccess={getMessageList}
        isViewAll={true}
      />

      {/*main dashboard content*/}

      {/* <DashboardTemplate
          caregiversData={care}
          patientData={pat}
         /> */}
      {/* </div> */}

      {/* message table */}

      <div style={{ backgroundColor: '#FFFFFF', marginTop: '-50px', marginBottom: '-50px' }}>

        <div className='m-2 m-md-5 p-2 pt-1'>
          <h5 className='textColor col-6 text-left mt-5 pl-0 mb-4' onClick={handlePatientProfile} style={{ cursor: 'pointer' }}>&lt; Back to Patient Profile</h5>
          <h6 className='h6 heading text-left'>Full Message History</h6>

          {/* need to make a seperate table */}

          <MessageHistory
            data={messageList}
            isLoading={messageListLoading}
          />
        </div>
      </div>

      {/* <NewAssessment show={show} onHide={handleClose} onClick={handleShow} editMode={false} /> */}

      {/* <SendMessageModal
        showModal={showMessage}
        handleClose={handleCloseMessage}
        data={location?.state}
        />  */}

    </> : null
  )
}
