import React, { useEffect, useState } from 'react';
import arrow_up from '../../assets/image/arrow_up.png';
import arrow_down from '../../assets/image/arrow_down.png';
import { useNavigate } from 'react-router-dom';
import { primary } from '../theme';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Blocks,TailSpin } from 'react-loader-spinner';
import { getRelationshipNameById } from '../../app/utils/getRelation';
import AppServices from '../../services/appServices';
import { APIEndpoint } from '../../config/apiendpoint';
import { convertDateFormat, timeFormat } from '../../app/utils/dateHelper';


interface TableProps {
  data: Array<any>;
  cap: string;
  isLoading: boolean;
}



const AllAssessmentTable: React.FC<TableProps> = ({ data, cap, isLoading }) => {
  const navigate = useNavigate();
  const _appServices = new AppServices()
  const [sortOrder, setSortOrder] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [sortedData, setSortedData] = useState(data);
  const [patientProfile,setPatientProfile]= useState<Record<string, any>>({});
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleClick=async(item:any)=>{
    const uuid = item.patientUid
    _appServices.logData({
      Severity: 'Info',
      Activity: `User viewed ${item.firstName} patient profile.`,
      ActivityStatus: 'Success',
      ActivityResponse: 'Page View',
      Operation: 'User Activity',
    }) 
    navigate(`/user-panel/patientProfile/${uuid}`) 
      
  }

  const handleSort = (column: string) => {

    _appServices.logData({
      Severity: 'Info',
      Activity: `User sorted patinet data in assessment table.`,
      ActivityStatus: 'Success',
      ActivityResponse: 'Page View',
      Operation: 'User Activity',
    }) 

    const order: "ascending" | "descending" = sortOrder === 'ascending' ? 'descending' : 'ascending';

    const sortedDataCopy = [...sortedData].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA === null || valueA === undefined) {
        return order === 'ascending' ? 1 : -1;
      }

      if (valueB === null || valueB === undefined) {
        return order === 'ascending' ? -1 : 1;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return order === 'ascending' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }

      return order === 'ascending' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    });
    setSortOrder(order);
    setSortColumn(column);
    setSortedData(sortedDataCopy);
  };



  return (
    <div className="table-responsive mt-5">
      <table className="custom-adli-table table ">
        <thead>
          <tr className=" text-left">
            <th className="h" onClick={() => handleSort('firstName')}>
              <span className="mr-3">Assessment</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: '7px', width: '14px', marginTop: '-10px' }}
                  alt=""
                  className={sortColumn === 'firstName' && sortOrder === 'ascending' ? 'visible' : 'hidden'}
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: '7px',
                    width: '14px',
                    marginTop: '7px',
                    marginLeft: '-14px',
                  }}
                  alt=""
                  className={sortColumn === 'firstName' && sortOrder === 'descending' ? 'visible' : 'hidden'}
                />
              </span>
            </th>
            <th onClick={() => handleSort('caregiver[0].lastName')}>
              <span className="mr-3">Date</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: '7px', width: '14px', marginTop: '-10px' }}
                  alt=""
                  className={sortColumn === 'caregiver[0].lastName' && sortOrder === 'ascending' ? 'visible' : 'hidden'}
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: '7px',
                    width: '14px',
                    marginTop: '7px',
                    marginLeft: '-14px',
                  }}
                  alt=""
                  className={sortColumn === 'caregiver[0].lastName' && sortOrder === 'descending' ? 'visible' : 'hidden'}
                />
              </span>
            </th>
            <th onClick={() => handleSort('completedAt')}>
              <span className="mr-3">Time</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: '7px', width: '14px', marginTop: '-10px' }}
                  alt=""
                  className={sortColumn === 'completedAt' && sortOrder === 'ascending' ? 'visible' : 'hidden'}
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: '7px',
                    width: '14px',
                    marginTop: '7px',
                    marginLeft: '-14px',
                  }}
                  alt=""
                  className={sortColumn === 'completedAt' && sortOrder === 'descending' ? 'visible' : 'hidden'}
                />
              </span>
            </th>
            <th onClick={() => handleSort('completedAt')}>
              <span className="mr-3">Patient</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: '7px', width: '14px', marginTop: '-10px' }}
                  alt=""
                  className={sortColumn === 'firstName' && sortOrder === 'ascending' ? 'visible' : 'hidden'}
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: '7px',
                    width: '14px',
                    marginTop: '7px',
                    marginLeft: '-14px',
                  }}
                  alt=""
                  className={sortColumn === 'firstName' && sortOrder === 'descending' ? 'visible' : 'hidden'}
                />
              </span>
            </th>
            <th onClick={() => handleSort('completedAt')}>
              <span className="mr-3"></span>
            </th>
          </tr>
        </thead>
        <tbody className='tableBody text-left'>
          {isLoading ?
            ( 
              <tr>
                <td colSpan={5} style={{ textAlign: 'center' }}>
                  <div className='d-flex align-items-center justify-content-center' style={{ height: '100px' }}>
                    <div>
                      <TailSpin
                        visible={true}
                        height="80"
                        width="80"
                        color="#03A84E"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        />
                    </div>
                  </div>
                </td>
              </tr> 
            ) :
            (
              sortedData.length>0? 
              sortedData.map((item, index) => (
                <tr
                  key={index}
                //   onClick={() => {
                //     navigate(
                //       '/user-panel/patientProfile/' + `${item.uuid}`,
                //       { state: item }
                //     );
                //   }}
                
                >
                  <td className="h">
                    <p className='assessques heading big-p d-flex mt-3'>
                    <span className="mr-3">
                      {item.name}
                    </span>
                    </p>
                    
                  </td>
                  <td>
                  
                  <p className="assessques heading big-p d-flex mt-3">
                    <span className='mr-3'>
                     {convertDateFormat(item.createdAt)}
                    </span>
                  </p>
     
                  </td>
                  <td>
                    <p className="assessques heading  d-flex mt-3">
                      <span className="mr-3">{timeFormat(item.createdAt)}</span>
                    </p>
                  </td>
                  <td>
                    <p className="assessques heading  d-flex mt-3">
                      <span className="mr-3 capitalized">{item.lastName}, {item.firstName} {item.middleName}</span>
                    </p>
                  </td>
                  <td>
                    <p className="assessques heading textColor d-flex mt-3">
                      <span className="mr-3" style={{cursor: loading?'progress':'pointer'}} onClick={()=>handleClick(item)} >View Patient</span>
                    </p>
                  </td>
                </tr>
              ))
              :(
                <tr>
                  <td colSpan={5} className='text-center'>
                    <span className='p-4 thick-heading'>No data</span>
                  </td>
                </tr>
                   )
              )
              }

        </tbody>
      </table>
    </div>
  );
};

export default AllAssessmentTable;
