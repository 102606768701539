import React, { useState } from "react";
import { IoEye } from "react-icons/io5";
import {  top_header_color } from "../../../theme/theme";
import { IoMdEyeOff } from "react-icons/io";

interface CustomInputProps {
  placeholder?: string;
  label?: string;
  type: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactNode;
  onClick?: () => void;
  errorText?: string;
  readonly?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  placeholder,
  label,
  type,
  value,
  onChange,
  icon,
  onClick,
  errorText,
  readonly,
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const iconPressed = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="input-f">
      {label && <label className="" style={{float:'left',color: '#9AA5B5',fontWeight:"700"}}>{label}</label>}
      <div style={{ position: "relative" }}>
        {icon && (
          <div
            style={{
              position: "absolute",
              right: 5,
              top: "63%",
              transform: "translateY(-50%)",
              padding: "2px",
              backgroundColor: top_header_color,
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              marginRight: 10,
              alignItems: "center",
            }}
          >
            {!showPassword ? (
              <IoMdEyeOff
                size={20}
                color={"rgb(154, 165, 181)"}
                // className="rpm-icon"
                onClick={() => iconPressed()}
              />
            ) : (
              <IoEye
                size={20}
                color={"rgb(154, 165, 181)"}
                // className="rpm-icon"
                onClick={() => iconPressed()}
              />
            )}
          </div>
        )}
        <input
          type={showPassword ? "text" : type}
          className={`input-box ${errorText ? "input-is-invalid" : ""}`}
          value={value}
          onChange={onChange}
          onClick={onClick}
          placeholder={placeholder}
          readOnly={readonly}
          {...restProps}
        />
      </div>
      {errorText && <div className="invalid-input text-left">{errorText}</div>}
    </div>
  );
};

export default CustomInput;
