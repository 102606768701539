import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props{
  message : string,
  info: string
}

const CustomToaster: any = ({ message, info }:Props) => {

  useEffect(() => {
    if (info === 'error') {
      toast.error(message);
    } else {
      toast.info(message);
    }
  }, []);

  return (
    <ToastContainer
      position="bottom-center"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      // pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
  );
};

export default CustomToaster;
