import React from 'react';
// import { assess_box, assess_quesEdit, assess_question, horizontal_line, white_font_button } from '../theme';
import {  assess_quesEdit, assess_question, white_font_button } from '../theme';
import edit from '../../assets/image/editProvider.png';
import Delete from '../../assets/image/Delete.png';

interface AssessmentQuestionProps {
  index: number;
  isActive: boolean;
  onClickEdit: (index: number) => void;
  onDelete: (index: number) => void;
}

export const AssessmentQuestion: React.FC<AssessmentQuestionProps> = ({ index, isActive, onClickEdit, onDelete }) => {
  const questionContent = (
    <div style={assess_quesEdit}>
      <input
        className='custom-form-input'
        style={{ height: '103px' }}
        // value={'In the past 4 weeks, did {#Patient#} usually manage to find his/her personal belongings at home? If yes, which best describes how he/she usually performed:'}
      />

      <div className='mt-4 ml-4 d-flex align-items-center'>
        <p className='editAssessquest mt-3'>Score:</p>
        <input className='otp-box headings' style={{ height: '50px', borderRadius: "12px" }} />
        <input className='custom-form-input' style={{ height: '50px', borderRadius: "12px" }} />
      </div>
      <div className='ml-4 d-flex align-items-center'>
        <p className='editAssessquest mt-3'>Score:</p>
        <input className='otp-box headings' style={{ height: '50px', borderRadius: "12px" }} />
        <input className='custom-form-input' style={{ height: '50px', borderRadius: "12px" }} />
      </div>
      <div className="d-flex justify-content-end">
        <button  style={white_font_button}>Save</button>
      </div>
    </div>
  );

  return (
    <div style={isActive ? assess_question : undefined}>
      <div className='d-flex justify-content-between'>
        <p className='big-p heading'>Question {index}</p>
        <span>
          <img className='mr-4' src={Delete} alt='delete' style={{ height: '27px', width: '27px' }} onClick={() => onDelete(index)} />
          <img src={edit} alt='edit' style={{ height: '28px', width: '28px' }} onClick={() => onClickEdit(index)} />
        </span>
      </div>
      
      {isActive ? questionContent : 
      (
        <div className='ml-3 mt-5 mb-5'>
               <p className='thin-heading assessques'>In the past 4 weeks, did  select his/her first set of clothes for the day? If yes, which best describes his/her usual performance:</p>

               <div className='mt-4 ml-4 d-flex align-items-center'>
                 <p className='thin-heading assessques mr-4'>Score:</p>
                 <p className='thin-heading assessques mr-5'>3</p>
                 <p className='thin-heading assessques'>Without supervision or help </p>
               </div>
               <div className='mt-4 ml-4 d-flex align-items-center'>
                 <p className='thin-heading assessques mr-4'>Score:</p>
                 <p className='thin-heading assessques mr-5'>2</p>
                 <p className='thin-heading assessques'>Without supervision or help </p>
               </div>
               <div className='mt-4 ml-4 d-flex align-items-center'>
                 <p className='thin-heading assessques mr-4'>Score:</p>
                 <p className='thin-heading assessques mr-5'>1</p>
                 <p className='thin-heading assessques'>Without supervision or help </p>
               </div>

         </div>
      )}
    </div>
  );
};