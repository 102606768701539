import React from 'react';
import signIDme from '../../../../assets/image/signIDme.png';
import createIdme from '../../../../assets/image/createIDme.png'
import { envConfig } from "../../../../config/env_config";


export default function IdentityVerification() {
  const { clientId, idMeUrl, idMeCallBackUrl, redirectedUri } = envConfig;

  const handleRegistration = () => {
    let redirectedUrl = idMeUrl + clientId + `&redirect_uri=` + redirectedUri + `&response_type=code&scope=openid%20` + idMeCallBackUrl + '&op=signup';
    window.location.replace(redirectedUrl); 
  };
  const handleLogin = () => {
    let redirectedUrl = idMeUrl + clientId + `&redirect_uri=` + redirectedUri + `&response_type=code&scope=openid%20` + idMeCallBackUrl;
    window.location.replace(redirectedUrl); 
  };

  return (
    <div className='container top-margin-container mb-5 px-5'>
    <div className='text-left' >
    <h6 className='h6 heading'><strong className='h5'>Step 2</strong>: Identity verification and data registration</h6>
    <p className='thin-heading graphText mt-4' style={{alignItems: 'center'}}>
    Complete the ID verification process using our trusted identify service provider, ID.me. OtisHealth conducts provider identify<br/>
    verification to ensure that only authenticated providers can enroll patients on the platform. You may verify using your existing<br/>
    ID.me provider account or to create a new ID.me account.
    </p>
    <p className='thin-heading graphText mt-4'>
    After your ID verification is complete, you will be asked to share information with OtisHealth to complete your registration.
    </p>
    </div>
    <div className='row mt-5'>
    <div className='col d-flex justify-content-center align-items-center'>
      <div className=''>
      <h6 className='h6 heading mb-4'><br />Verify using an existing <br/>ID.me provider account <br/></h6>
        <button onClick={handleLogin}  style={{border:'none', cursor:'pointer', background:'transparent'}} ><img src={signIDme} alt='no availavle' style={{height:'55px', width:'284px'}} /></button>
      </div>
    </div> 
    <div className='vertical-line'/>
    <div className='col d-flex justify-content-center align-items-center'>
      <div className=''>
        <h6 className='h6 heading mb-4'>Create a new ID.me <br/>provider account to <br/>complete registration</h6>
        <button onClick={handleRegistration} style={{border:'none',cursor:'pointer', background:'transparent'}} ><img src={createIdme} alt='no availavle' style={{height:'55px', width:'284px'}} /></button>
      </div>
    </div>
  </div>
</div>
  )
}
