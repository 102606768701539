type EnvConfig = {
  baseURL: string;
  clientId: string;
  idMeUrl: string;
  idMeCallBackUrl: string;
  redirectedUri: string;
  chatWidgetUrl: string;
  privacyUrl: string;
  termsConditions: string;
  faq: string;
  publicKey: string;
  captchaKey: string;
};

type EnvOptions = {
  dev: EnvConfig;
  uat: EnvConfig;
  prod: EnvConfig;
  default: EnvConfig;
};

const selectEnv = (env: keyof EnvOptions): EnvConfig => {
  const envOptions: EnvOptions = {
    dev: {
      baseURL: 'https://otis-health-rpm-dev.uw.r.appspot.com/v1/',
      clientId: '90008cb169a2f2cca6a6154014a4ad3f',
      idMeUrl: 'https://api.idmelabs.com/oauth/authorize?client_id=',
      idMeCallBackUrl: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx/registration',
      redirectedUri: 'https://dev.rpm.otishealth.net/idmehandler',
      chatWidgetUrl: "https://desk.zoho.com/portal/api/web/inapp/931467000000617009?orgId=838132203",
      privacyUrl: "https://otishealth.net/rpm_privacy",
      termsConditions: "https://otishealth.net/rpm_terms-of-use",
      faq: "https://otishealth.net/faqs/",
      publicKey: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQKsghPh9Ct9cNzUMVXITkWG9aKIVKgXMh6AV1zH1yY+SSuSJbydTJKvCDtHtZt4gExeS7x+H38+FtJP6YpTtKq8Wpb5WZVGcIgBUJc7k547N6MVjoctK5T59OcJ+2G5Mm5Uc9YSmymentIxDcDhcBEN16JgFC3APbvtwXTz0xPwIDAQAB",
      captchaKey: '6LdTNTAqAAAAACX-fLhbsvkhfNPmCTCzc_TqqRlj'
    },
    uat: {
      baseURL: 'https://rpmuat-dot-otis-health-rpm-uat.uw.r.appspot.com/v1/',
      clientId: '90008cb169a2f2cca6a6154014a4ad3f',
      idMeUrl: 'https://api.idmelabs.com/oauth/authorize?client_id=',
      idMeCallBackUrl: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx/registration',
      redirectedUri: 'https://uat.rpm.otishealth.net/idmehandler',
      chatWidgetUrl: "https://desk.zoho.com/portal/api/web/inapp/931467000000617009?orgId=838132203",
      privacyUrl: "https://otishealth.net/rpm_privacy",
      termsConditions: "https://otishealth.net/rpm_terms-of-use",
      faq: "https://otishealth.net/faqs/",
      publicKey: "MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHyWOAKxpgpPO4n+FKT4b87mzOrmR+JJ9DoTzUrDfpUS7lIXAmSuqDljVVaWQ3+jzuyOCwGKmgauD8OE1TG90WXjZx5SQKiZV5evoF3Xfs8KuFFaM+RmYVd05B5wsJlPcew0qQgYYVhYlwKS/wDB8c5AsyUxxrZJNcsEe+2tME8jAgMBAAE=",
      captchaKey: '6LeX2jAqAAAAAIuZ8NMt2MR8N8ARLt7idIZPWVo_'
    },
    prod: {
      baseURL: 'https://rpmuat-dot-otis-health-rpm-uat.uw.r.appspot.com/v1/',
      clientId: '1703d634f3f1490cfc4dfb5f9e50cbb5',
      idMeUrl: 'https://api.idmelabs.com/oauth/authorize?client_id=',
      idMeCallBackUrl: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx/registration',
      redirectedUri: 'https://rpm.otishealth.net/idmehandler',
      chatWidgetUrl: "https://desk.zoho.com/portal/api/web/inapp/931467000000701001?orgId=838132203",
      privacyUrl: "https://otishealth.net/rpm_privacy",
      termsConditions: "https://otishealth.net/rpm_terms-of-use",
      faq: "https://otishealth.net/faqs/",
      publicKey: "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUFvY2sxTmQyc3Awc2FHQVdKNWxsQgphYUZKbmVCVFQ2cUZyaUZpK2JiZkZpOTJXS0N5NFIrdytpNDFtcmpzVTNDZE5jVGQ1WGFhNWJhR3dPbDhRZnpwCjNtZlBkWnVrSmZCc0dPUVdJMlQrUkVCZVk3c01JSkNKdGFvYUtNVGtwTi8xYVJaR0RnVEFkbE55cVVFYU95OVQKb1FBbVhsTFVVWE41Zy9tcG1hY0NOK25KbXM0dk4zZ1ZHaWpiVDJManFSMDRoT3BwNWd6blZBWmVNT2RUaVpVRwpPMUZ4N1NzMDVIdGtNVHp5V0ZKZVNqUitMVHFNOVFWOUZ3YXozY29yWHdiU1NLVGNvSXdZeXBzb0cvVjF2cU5nCjVHNjVKUEZxemg0TmVzUlJPS0lBY3N1SDJydHNsckdZZ2cvNUc4OGdTUFZUMENFaDN1Sm1XWEFOWUJTWlJSeGgKZFFJREFRQUIKLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tCg==",
      captchaKey: '6LdBryUqAAAAABnfL22t97GHpoUZ_ThTuG2PiSpc'
    },
    default: {
      baseURL: 'http://localhost:8000/v1/',
      clientId: '90008cb169a2f2cca6a6154014a4ad3f',
      idMeUrl: 'https://api.idmelabs.com/oauth/authorize?client_id=',
      idMeCallBackUrl: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx/registration',
      redirectedUri: 'http://localhost:3000/idmehandler',
      chatWidgetUrl: "https://desk.zoho.com/portal/api/web/inapp/931467000000617009?orgId=838132203",
      privacyUrl: "https://otishealth.net/privacy/",
      termsConditions: "https://otishealth.net/terms-of-use/",
      faq: "https://otishealth.net/faqs/",
      publicKey: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQKsghPh9Ct9cNzUMVXITkWG9aKIVKgXMh6AV1zH1yY+SSuSJbydTJKvCDtHtZt4gExeS7x+H38+FtJP6YpTtKq8Wpb5WZVGcIgBUJc7k547N6MVjoctK5T59OcJ+2G5Mm5Uc9YSmymentIxDcDhcBEN16JgFC3APbvtwXTz0xPwIDAQAB",
      captchaKey: '6LdTNTAqAAAAACX-fLhbsvkhfNPmCTCzc_TqqRlj'
    }
  };

  return envOptions[env] || envOptions.default;
};

const envConfig: EnvConfig = selectEnv('dev');

export { envConfig };

