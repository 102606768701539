import axios from 'axios';
import Swal from 'sweetalert2'
import { envConfig } from '../config/env_config';
import ContextStore from '../context/clearContext';
// import { logData } from '../app/logsHelper';
import { UserContext } from '../context/UserContext';
import { useContext } from 'react';
import { APIEndpoint } from '../config/apiendpoint';
import { info } from '../theme/Toaster/quickToasts';
import CryptoJS from 'crypto-js';
class AppServices {

    public _contextStore = new ContextStore()
    public appContext = useContext(UserContext)

    public ipAddress: any;
    // public logData = useLogger()

    public userData: any = this.appContext.userDetails


    public client = axios.create({
        baseURL: envConfig.baseURL
    });

    public api_config: any = {
        headers: {}
    };

    getErrorMessage(error: any) {
        let captureErrorWord = ['sql', 'salt', 'cannot read properties of null']
        let checkMessage = typeof error.message == "string" ? captureErrorWord.some(i => error?.message.toLowerCase().includes(i)):true
        const errorMessages: { [key: string]: string | any } = {
            409: "Something went wrong please try again later",
            500: "Something went wrong please try again later",
            404: "Not Found",
            406: "Something went wrong please try again later",
            400: error?.message,
            401: "Unauthorised access please re-login again"
        };

        return (checkMessage == false && error.message != undefined) ? error.message : errorMessages[error?.status] || "Something went wrong please contact to support team";
    }

    async encryptText(plainText: any) {
        // console.log(APIEndpoint.public, "APIEndpoint.public")
        const encrypted = CryptoJS.AES.encrypt(plainText, envConfig.publicKey).toString();
        return encrypted;
    }

    async decryptText(encryptedText: any) {
        const decrypted = CryptoJS.AES.decrypt(encryptedText, envConfig.publicKey).toString(CryptoJS.enc.Utf8);
        if (decrypted) {
            return JSON.parse(decrypted);
        }
    }

    showFlashMsg(icon: string = 'success', title: string = '', msg: string = '', showConfirmButton: boolean = false) {

        Swal.fire({
            // position: "bottom",
            icon: "success",
            title: title,
            text: msg,
            showConfirmButton: showConfirmButton,
            timer: 3000
        });
    }

    async logData({ Severity, Activity, ActivityStatus, ActivityResponse, Operation, email = '' }: any) {
        const browserInfo = {
            name: this.getBrowserName(),
            version: this.getBrowserVersion(),
        };

        if (!this.ipAddress) {
            await this.getIpAddress()
        }

        const obj = {
            UserEmail: email != '' ? email : (this.userData ? this.userData?.email : ''),
            AppName: "OtisHealth RPM Providers",
            Severity: Severity,
            // User: this.userData ? this.userData?.uuid : '',
            Activity: Activity,
            ActivityStatus: ActivityStatus,
            ActivityResponse: ActivityResponse,
            Operation: Operation,
            Platform: "Web",
            TimeStamp: new Date().valueOf(),
            deviceVersion: `${browserInfo.version}`,
            ipAddress: this.ipAddress,
            deviceName: `${browserInfo.name}`,
        };

        if (obj.UserEmail != '') {
            await this.getSessionDetails();
            try {
                const encryptedPayload = { encryptedData: await this.encryptText(JSON.stringify(obj)) };
                const response = await this.client.post(APIEndpoint.logging, encryptedPayload, this.api_config);
            } catch (err) {
                console.log(err)
            }

        }
    }
    async getIpAddress() {
        try {

            const res = await axios.get('https://api.ipify.org?format=json')
            this.ipAddress = res.data.ip
        } catch (error) {
            console.log(error)
        }
    }

    getBrowserName() {
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Edge") !== -1) return "Edge";
        if (userAgent.indexOf("Chrome") !== -1) return "Chrome";
        if (userAgent.indexOf("Safari") !== -1) return "Safari";
        if (userAgent.indexOf("Firefox") !== -1) return "Firefox";
        if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident") !== -1) return "IE";
        return "Unknown";
    }


    getBrowserVersion() {
        const userAgent = navigator.userAgent;
        const match = userAgent.match(/(?:Chrome|Safari|Firefox|MSIE|Trident|Edge)\/([0-9.]+)/);
        return match ? match[1] : "Unknown";
    }

    async getSessionDetails() {
        let sessionDetails = localStorage.getItem('sessionObject');
        if (sessionDetails) {
            let parseSessionDetails = JSON.parse(sessionDetails);
            // //////console.log('parseSessionDetails',parseSessionDetails.authToken.access.token)
            this.api_config.headers['Authorization'] = `Bearer ${parseSessionDetails.authToken.access.token}`
            return this.api_config
        } else {
            delete this.api_config.headers['Authorization'];
        }
    }

    async getData(url: string) {
        try {
            // console.log("call Api ===> ", envConfig.baseURL+url);
            const sessionDetail = await this.getSessionDetails();
            // console.log(sessionDetail, "After this.api config ===> ", this.api_config);
            const response = await this.client.get(url, this.api_config);
            this.logData({
                Severity: 'Info',
                Activity: `Call API Request ${envConfig.baseURL + url}`,
                ActivityStatus: 'Success',
                ActivityResponse: "",
                Operation: 'API Call'
            })
            // return response.data;
            return this.decryptText(response?.data)
        } catch (error: any) {
            const er = await this.decryptText(error?.response?.data)
            this.logData({
                Severity: 'Error',
                Activity: `Error from requested URL - ${envConfig.baseURL + url}`,
                ActivityStatus: 'Failure',
                ActivityResponse: error,
                Operation: 'API Call'
            })
            if (error && error.response && error?.response?.data && error?.response?.data.error) {
                if (String(error?.response?.data.error).toLowerCase().includes("please authenticate")) {
                    info()
                    this._contextStore.clearContext()

                }
            }
            else { throw er; }
        }
    };

    async postData(url: string, payload: any) {
        try {
            await this.getSessionDetails();
            const encryptedPayload = { encryptedData: await this.encryptText(JSON.stringify(payload)) };
            const response = await this.client.post(url, encryptedPayload, this.api_config);
            // console.log(response)
            this.logData({
                Severity: 'Info',
                Activity: `Call API Request ${envConfig.baseURL + url}`,
                ActivityStatus: 'Success',
                ActivityResponse: "",
                Operation: 'API Call'
            })
            // console.log(response, '========================================>', this.decryptText(response?.data))
            return this.decryptText(response?.data)
            // return response.data;
        } catch (error: any) {
            // console.log(error,"error postdata")
            const er = await this.decryptText(error?.response?.data)
            // console.log(er, "asdfghjkl;")
            this.logData({
                Severity: 'Error',
                Activity: `Error from requested URL - ${envConfig.baseURL + url}`,
                ActivityStatus: 'Failure',
                ActivityResponse: error,
                Operation: 'API Call'
            })
            if (error && error.response && error?.response?.data && error?.response?.data.error && String(error?.response?.data.error).toLowerCase().includes("please authenticate")) {
                info()
                this._contextStore.clearContext()
            } else {
                throw er;
            }
        }
    };

    async postData1(url: string, payload: any) {
        try {
            await this.getSessionDetails();
            const encryptedPayload = { encryptedData: await this.encryptText(JSON.stringify(payload)) };
            const response = await this.client.post(url, payload, this.api_config);
            this.logData({
                Severity: 'Info',
                Activity: `Call API Request ${envConfig.baseURL + url}`,
                ActivityStatus: 'Success',
                ActivityResponse: "",
                Operation: 'API Call'
            })
            // return this.decryptText(response?.data)
            return response.data;
        } catch (error: any) {
            const er = await this.decryptText(error?.response?.data)
            this.logData({
                Severity: 'Error',
                Activity: `Error from requested URL - ${envConfig.baseURL + url}`,
                ActivityStatus: 'Failure',
                ActivityResponse: error,
                Operation: 'API Call'
            })
            if (error && error.response && error?.response?.data && error?.response?.data.error && String(error?.response?.data.error).toLowerCase().includes("please authenticate")) {
                info()
                this._contextStore.clearContext()
            } else {
                throw error;
            }
        }
    };

    async patchData(url: string, payload: any) {
        try {
            await this.getSessionDetails();
            const encryptedPayload = { encryptedData: await this.encryptText(JSON.stringify(payload)) };
            const response = await this.client.patch(url, encryptedPayload, this.api_config);
            this.logData({
                Severity: 'Info',
                Activity: `Call API Request ${envConfig.baseURL + url}`,
                ActivityStatus: 'Success',
                ActivityResponse: "",
                Operation: 'API Call'
            })
            // return response.data;
            return this.decryptText(response?.data)
        } catch (error: any) {
            const er = await this.decryptText(error?.response?.data)
            this.logData({
                Severity: 'Error',
                Activity: `Error from requested URL - ${envConfig.baseURL + url}`,
                ActivityStatus: 'Failure',
                ActivityResponse: error,
                Operation: 'API Call'
            })
            if (error && error.response && error?.response?.data && error?.response?.data.error && String(error?.response?.data.error).toLowerCase().includes("please authenticate")) {
                info()
                this._contextStore.clearContext()

            } else {
                throw er;
            }
        }
    };

    async putData(url: string, payload: any = null) {
        try {
            await this.getSessionDetails();
            const encryptedPayload = { encryptedData: await this.encryptText(JSON.stringify(payload)) };
            const response = await this.client.put(url, encryptedPayload, this.api_config);
            this.logData({
                Severity: 'Info',
                Activity: `Call API Request : ${envConfig.baseURL + url}`,
                ActivityStatus: 'Success',
                ActivityResponse: "",
                Operation: 'API Call'
            })
            return this.decryptText(response?.data)
            // return response.data;
        } catch (error: any) {
            const er = await this.decryptText(error?.response?.data)
            this.logData({
                Severity: 'Error',
                Activity: `Error from requested URL - ${envConfig.baseURL + url}`,
                ActivityStatus: 'Failure',
                ActivityResponse: error,
                Operation: 'API Call'
            })
            if (error && error.response && error?.response?.data && error?.response?.data.error && String(error?.response?.data.error).toLowerCase().includes("please authenticate")) {
                info()
                this._contextStore.clearContext()

            } else {
                throw er;
            }
        }
    };

    async deleteData(url: string) {
        try {
            await this.getSessionDetails();
            const response = await this.client.delete(url, this.api_config);
            this.logData({
                Severity: 'Info',
                Activity: `Call API Request: ${envConfig.baseURL + url}`,
                ActivityStatus: 'Success',
                ActivityResponse: "",
                Operation: 'API Call'
            })
            // return response.data;
            return this.decryptText(response?.data)
        } catch (error: any) {
            const er = await this.decryptText(error?.response?.data)
            this.logData({
                Severity: 'Error',
                Activity: `Error from requested URL - ${envConfig.baseURL + url}`,
                ActivityStatus: 'Failure',
                ActivityResponse: error,
                Operation: 'API Call'
            })
            if (error && error.response && error?.response?.data && error?.response?.data.error && String(error?.response?.data.error).toLowerCase().includes("please authenticate")) {
                info()
                this._contextStore.clearContext()

            } else {
                throw er;
            }
        }
    };

}

export default AppServices;