import { useEffect, useRef } from 'react';
import {
  Dot,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis, YAxis
} from 'recharts';
import { convertDate, getDDDMMDD, getDDMM, getTime12hrsData } from '../../utils/dateHelper';
import "../graphs/styles.css";

const BloodGraphPdf = ({ data, usePdf }) => {
  const bloodPressureGraphRef = useRef(null);

  const CustomDot = ({ cx, cy, fill, payload, dataKey }) => {
    let dotColor = fill;

    if (dataKey === 'diastolic') {
      dotColor = payload.isAbnormalDiastolic ? 'red' : '#E2B71F';
    } else if (dataKey === 'systolic') {
      dotColor = payload.isAbnormalSystolic ? 'red' : '#89C9F8';
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={dotColor}
        stroke="none"
        // Ensure no hover effect
        style={{ pointerEvents: 'none' }}
      />
    );
  };

  useEffect(() => {
    if (bloodPressureGraphRef.current) {
      bloodPressureGraphRef.current.scrollLeft = bloodPressureGraphRef.current.scrollWidth;
    }
  }, [data]);
  const formattedData = (data)?.map(entry => ({
    date: getDDMM(entry.date),
    systolic: entry.systolic,
    diastolic: entry.diastolic,
    isAbnormalDiastolic: entry.isAbnormalDiastolic,
    isAbnormalSystolic: entry.isAbnormalSystolic,
  }));



  return (
    <div className="graph-container">
      {(data && data.length) ? <div style={{ display: 'flex' }}>
        <div style={{ width: '65px' }}>
          <ResponsiveContainer width={'100%'} height={275}>
            <LineChart data={formattedData}>
              <YAxis
                dy={20}
                type="number"
                dataKey='systolic'
                axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                tick={{ fill: '#000', fontSize: 12 }}
                padding={{ right: 20 }}
                tickLine={false}
                style={{ fontFamily: 'Open Sans, sans-serif' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ overflowX: 'auto', width: 'calc(100% - 70px)' }}
          ref={bloodPressureGraphRef}
        >
          <ResponsiveContainer height={300}>
            <LineChart data={formattedData}>
              <XAxis
                dataKey="date"
                angle={90} // Rotate labels to vertical
                dx={5} // Center the labels along the axis
                dy={10}
                minTickGap={-50}
                textAnchor="middle" // Center the text on the axis line
                tick={{ fill: '#000', fontSize: 12, transform: 'rotate(90deg)' }} // Ensure text is rotated
                tickLine={false}
                axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                padding={{
                  right: 20,
                }}
                style={{ fontFamily: 'Open Sans, sans-serif' }}
              />

              <Tooltip />
              <Legend
                verticalAlign="top"
                align="right"
                iconType="circle"
                iconSize={26}
                wrapperStyle={{ paddingBottom: 20, paddingLeft: 20, }}
                formatter={(value, entry) => (
                  <span style={{ color: '#000', fontSize: 14 }}>
                    {value}
                  </span>
                )}
              />
              <Line
                type="monotone"
                dataKey="systolic"
                stroke="#89C9F8"
                name="Systolic"
                strokeWidth={3}
                dot={(props) => <CustomDot {...props} dataKey="systolic" />}
              />
              <Line
                type="monotone"
                dataKey="diastolic"
                stroke="#E2B71F"
                name="Diastolic"
                strokeWidth={3}
                dot={(props) => <CustomDot {...props} dataKey="diastolic" />}
              />
              <ReferenceLine y={140} stroke="black" strokeDasharray="3 3" />
              <ReferenceLine y={70} stroke="black" strokeDasharray="3 3" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div> : <p className='d-flex justify-content-center align-items-center' style={{ minHeight: 300 }}>No Chart Data Found</p>}


    </div>
  );
};

export default BloodGraphPdf;
