import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Delete from "../../../assets/image/Delete.png";
import editProvider from "../../../assets/image/editProvider.png";
import AddMonitoring from "../../../theme/AddMonitoring/addMonitoring";
import AppServices from "../../../services/appServices";
import { APIEndpoint } from "../../../config/apiendpoint";
import Swal from "sweetalert2";
import { convertDate, getTimeStringToDate } from "../../utils/dateHelper";

const ManageMonitoring = ({ patientData, handleshowTrainingPopup }) => {
  const _appServices = new AppServices();
  const [monitoringData, setMonitoringData] = useState([]);

  const [data, setData] = useState();
  const [isShowMonitoringPopup, setIsShowMonitoringPopup] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    setPatientDetails(patientData);
    getMonitoringData();
  }, []);

  const getMonitoringData = async () => {
    setIsShowMonitoringPopup(false);
    const patId = patientData?.uuid;
    try {
      const resp = await _appServices.getData(
        `${APIEndpoint.Monitoring}?patientUid=${patId}`
      );
      if (resp.status === 200 && resp.success == true) {
        let monitoringDatas = [];
        if (resp.data && resp.data.length) {
          resp.data.map((item) => {
            let alertThresholds = {};
            switch (item.measurement) {
              case 1:
                alertThresholds["systolicMin"] = item.systolicMin;
                alertThresholds["systolicMax"] = item.systolicMax;
                alertThresholds["diastolicMin"] = item.diastolicMin;
                alertThresholds["diastolicMax"] = item.diastolicMax;
                break;
              case 2:
                alertThresholds["glucoseMin"] = item.bloodGlucoseLevelMin;
                alertThresholds["glucoseMax"] = item.bloodGlucoseLevelMax;
                break;
              case 3:
                alertThresholds["oxygenMin"] = item.oxygenSaturationMin;
                alertThresholds["oxygenMax"] = item.oxygenSaturationMax;
                break;
              case 4:
                alertThresholds["heartRateMin"] = item.heartRateMin;
                alertThresholds["heartRateMax"] = item.heartRateMax;
                break;

              default:
                break;
            }
            monitoringDatas.push({
              uuid: item.uuid,
              measureId: item.measurement,
              measure: item.measurementName,
              alertThresholds: alertThresholds,
              startDate: item.startDate,
              frequency: item.frequency,
              frequencyName: item.frequencyName,
              firstReminder: item.remiders.first
                ? getTimeStringToDate(item.remiders.first)
                : null,
              secondReminder: item.remiders.second
                ? getTimeStringToDate(item.remiders.second)
                : null,
              thirdReminder: item.remiders.third
                ? getTimeStringToDate(item.remiders.third)
                : null,
              onOff: item.status ? true : false,
            });
          });
          setMonitoringData(monitoringDatas);
        }else{
          setMonitoringData([]);
        }
      }
    } catch (error) {
      console.error("Error fetching datad:", error);
    }
  };

  const handleSwitchChange = (index) => async (checked) => {
    const updatedData = [...monitoringData];
    updatedData[index].onOff = checked;
    setMonitoringData(updatedData);
    let postJson = {
      status: updatedData[index].onOff == true ? 1 : 0,
    };
    const patId = patientData?.uuid;
    try {
      const resp = await _appServices.patchData(
        `${APIEndpoint.Monitoring}/${updatedData[index].uuid}?patientUid=${patId}`,
        postJson
      );
      if (resp.status === 200 && resp.success == true) {
        _appServices.showFlashMsg("success", "Success", resp?.message, false);
        _appServices.logData({
          Severity: 'Info',
          Activity: `User edited monitoring for patient`,
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        })
        getMonitoringData();
      } else {
        const message = _appServices.getErrorMessage(resp);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message,
        });
      }
    } catch (err) {
      const message = _appServices.getErrorMessage(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message || "Something went wrong.",
      });
      _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
      _appServices.logData({
        Severity: 'Error',
        Activity: `User failed to edit assessment for patient`,
        ActivityStatus: 'Failure',
        ActivityResponse: err,
        Operation: 'User Activity',
      })
    }
  };

  const handleCloseMonitoringPopup = () => {
    setIsShowMonitoringPopup(false);
  };

  const onEditMonitoring = (entry) => {
    setData(entry);
    setEditMode(true);
    setIsShowMonitoringPopup(true);
  };

  const onDeleteMonitoring = async (item) => {
    const patId = patientData?.uuid;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A6317B",
      cancelButtonColor: "#202656",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const resp = await _appServices.deleteData(
            `${APIEndpoint.Monitoring}/${item.uuid}?patientUid=${patId}`
          );
          if (resp.status === 200 && resp.success == true) {
            Swal.fire({
              title: "Deleted!",
              text: resp.message,
              icon: "success",
            });
            getMonitoringData();
            _appServices.logData({
              Severity: "Info",
              Activity: `User deleted Monitoring.`,
              ActivityStatus: "Success",
              ActivityResponse: "Data Submitted.",
              Operation: "User Activity",
            });
          } else {
            const message = _appServices.getErrorMessage(resp);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: message,
            });
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err?.message || "Something went wrong.",
          });
        }
      }
    });
  };

  const onAddMonitorPopup = () => {
    setEditMode(false);
    setIsShowMonitoringPopup(true);
  };
  return (
    <div className="graph-container">
      <div className="header-container">
        <h2 className='graphHeading ml-5 mb-4'>Manage Monitoring</h2>
        <button className="training-button ml-5 mb-4" onClick={()=>onAddMonitorPopup()}> + Add Monitoring</button>
      </div>

      <div className="bordertable-container ml-5">
          <table className="borderdata-table">
            <thead>
              <tr>
                <th>Measure</th>
                <th>Start Date</th>
                <th>Frequency</th>
                <th>On/Off</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {monitoringData.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.measure}</td>
                  <td>{convertDate(entry.startDate)}</td>
                  <td>{entry.frequencyName}</td>
                  <td>
                    <Switch
                      className="react-switch"
                      borderRadius={6}
                      onColor="#00A10B"
                      height={32}
                      width={70}
                      onChange={handleSwitchChange(index)}
                      checked={entry.onOff}
                      uncheckedIcon={<div className="Switch p-2">OFF</div>}
                      checkedIcon={<div className="Switch p-2">ON</div>}
                    />
                  </td>
                  <td>
                    <span className="d-flex mt-1">
                      <img
                        src={Delete}
                        className="mr-3"
                        style={{
                          height: "22px",
                          width: "22px",
                          cursor: "pointer",
                        }}
                        alt="Delete"
                        onClick={() => onDeleteMonitoring(entry)}
                      />
                      <img
                        className="mt-1"
                        src={editProvider}
                        style={{
                          height: "17px",
                          width: "17px",
                          cursor: "pointer",
                        }}
                        alt="Edit"
                        onClick={() => onEditMonitoring(entry)}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {monitoringData.length === 0 && (
          <div className="d-flex align-items-center justify-content-center pt-2">
            <p className="graphText thin-heading">No Data found</p>
          </div>
        )}
        {isShowMonitoringPopup ? (
          <AddMonitoring
            patient={patientDetails}
            show={isShowMonitoringPopup}
            onHide={handleCloseMonitoringPopup}
            editMode={editMode}
            data={data}
            monitoringSuccess={getMonitoringData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ManageMonitoring;
