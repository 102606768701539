import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import careGiver from "../../../../assets/image/careGiver.png";
import addPatient from "../../../../assets/image/addPatient.png";

import { Field, FieldArray, Formik } from "formik";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import {
  top_header_button,
  btn_primary,
  selectStyle,
  selectStyleMobile,
} from "../../../../theme/theme";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { UserContext } from "../../../../context/UserContext";
import AppServices from "../../../../services/appServices";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "../../../../theme/Toaster/quickToasts";
import { APIEndpoint } from "../../../../config/apiendpoint";
import { useLocation } from "react-router-dom";
import { manageSchema, manageSchema2 } from "../validationData";
import Swal from "sweetalert2";
import { convertDate, convertDateFormat1 } from "../../../utils/dateHelper";
import InputMask from 'react-input-mask';

export default function ManagePatientsInfo() {
  const navigate = useNavigate();
  const location = useLocation();

  const [patientData, setPatientData] = useState<string | undefined>(undefined);

  const { genderInfo, relationshipInfo, countryCode } = useContext(UserContext);
  const _appServices = new AppServices();

  const [gender, setGender] = useState([]);
  const [relationship, setRelationship] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState<any[]>([]);


  const [patient, setPatient] = useState<any>();
  const [schemaN, setSchemaN] = useState<any>(manageSchema);

  useEffect(() => {
    if (genderInfo && genderInfo.length) {
      let genderDatas: any = genderInfo.map((d: any) => ({
        value: d.id,
        label: d.name,
      }));
      setGender(genderDatas);
    }
    if (relationshipInfo && relationshipInfo.length) {
      let relationshipDatas: any = relationshipInfo.map((d: any) => ({
        value: d.id,
        label: d.name,
      }));
      setRelationship(relationshipDatas);
    }
    if (countryCode && countryCode.length) {
      let countries: any = countryCode.map((d: any) => ({
        value: d.countryCode,
        label: d.countryCode,
      }));
      setCountryCodeList(countries);
    }
  }, [genderInfo, relationshipInfo, countryCode]);

  useEffect(() => {
    setPatientData(location.state?.patientUid);
  }, [location.state?.patientUid]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (patientData) {
          const res = await _appServices.getData(
            `${APIEndpoint.getPatientByUid}${patientData}`
          );
          if (res.status === 200 && res.success) {
            setPatient(res.data[0]);
          } else {
            notifyInfo(res.message[0]);
          }
        }
      } catch (err: any) {
        notifyError(err.message[0]);
      }
    };

    fetchData();
  }, [patientData]);

  const handlePatientProfile = () => {
    navigate(-1);
  };

  const handleEnrollPatient = async (values: any, resetForm: any, setSubmitting:any) => {
    setSubmitting(true);
    const patientData = {
      patientUid: patient?.uuid,
      isPatientManage: values.isPatientManage,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.mobileNumber,
      gender: parseInt(values.gender),
      dob: convertDateFormat1(values.dob),
    };

    let caregiverData:any = []
    values.caregivers.map((caregiver: any, index:any) => {
       if (caregiver.careGiverEmail && caregiver.careGiverEmail.length &&             
        typeof caregiver.careGiverFirstName !== undefined &&
        // typeof caregiver.careGiverMiddleName !== undefined &&
        typeof caregiver.careGiverLastName !== undefined &&
        typeof caregiver.careGiverRelation !== undefined &&
        // typeof caregiver.careGiverDob !== undefined &&
        // typeof caregiver.careGiverGender !== undefined &&
        typeof caregiver.careGiverPhone !== undefined &&
        typeof caregiver.careGiverEmail !== undefined) {
          caregiverData.push ({
             isAuthToCondPatAssess: caregiver.careGiverisAuthToCondPatAssess,
             firstName: caregiver.careGiverFirstName,
             middleName: caregiver.careGiverMiddleName,
             lastName: caregiver.careGiverLastName,
             email: caregiver.careGiverEmail,
             phoneNumber: caregiver.careGiverPhone,
             gender: caregiver.careGiverGender,
             dob:  caregiver.careGiverDob !== '' ? convertDateFormat1(caregiver.careGiverDob) : '',
             relationshipToPatient: caregiver.careGiverRelation
         })
          if(caregiver.careGiverUid ){
            caregiverData[index]["careGiverUid"] = caregiver.careGiverUid
          }

       }
   });

   const allEmails = [patientData.email, ...caregiverData.map((caregiver:any) => caregiver.email)];

   const uniqueEmails = new Set();
   const duplicateEmails:any = [];
   
   allEmails.forEach(email => {
       if (uniqueEmails.has(email)) {
           duplicateEmails.push(email);
       } else {
           uniqueEmails.add(email);
       }
   });
   
   if (duplicateEmails.length > 0) {
       const errorMessage = `<b> ${duplicateEmails.join(",<br/> ")} </b> <br/> Please enter unique email for patient/caregiver.`;
       // notifyError(errorMessage);
       Swal.fire({
          icon: "error",
         title: `Duplicate email(s) found:`,
         html: errorMessage,
       });
       setSubmitting(false);
       return;
   }

   let requestData = {}
   if(caregiverData.length > 0) {
      requestData = {
        ...patientData,
        careGiverInfo: caregiverData,
      };
   }else{
    requestData = {
      ...patientData,
    };
   }

   

    try {
      const res = await _appServices.postData(
        `${APIEndpoint.upsertPatientCaregiver}`,
        requestData
      );

      if (res.status === 200 && res.success) {
        // notifySuccess(res.message);
        _appServices.showFlashMsg('success', 'Success', res.message, false);
        resetForm();
        setSubmitting(false)
        navigate("/user-panel/invitation");
        _appServices.logData({
          Severity: 'Info',
          Activity: `User updated patient in manage patient profile.`,
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        })
      } else {
        // notifyInfo(res.message[0]);
        // navigate("/user-panel/invitation");
        const message = _appServices.getErrorMessage(res)
        _appServices.showFlashMsg('info', 'Success', message, false);
        setSubmitting(false)
      }
    } catch (err: any) {
      setSubmitting(false)
      // notifyError(err.message[0]);
      const message = _appServices.getErrorMessage(err)
      // let captureErrorWord = ['sql','salt', 'cannot read properties of null']
      Swal.fire({
        icon: "error",
        title: "Oops...",
        // text: (err && err?.message) ? captureErrorWord.some(i => err?.message.toLowerCase().includes(i)) ? "An error occurred while calling the service, please try after some time.": err?.message:"An error occurred while calling the service, please try after some time.",
        text: message || "Something went wrong.",
      })
      // _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
      _appServices.logData({
        Severity: 'Error',
        Activity: `User failed to enroll new patient in manage profile.`,
        ActivityStatus: 'Failed',
        ActivityResponse: err,
        Operation: 'User Activity',
      })
    }
    setSubmitting(false)
  };

  const optionalFieldValidation = (d: any) => {
    return (
      d.careGiverFirstName.length ||
      d.careGiverMiddleName.length ||
      d.careGiverLastName.length ||
      d.careGiverRelation ||
      d.careGiverDob ||
      d.careGiverGender ||
      d.careGiverEmail.length ||
      d.careGiverPhone.length
    );
  };

const schema = schemaN;

const checkDOB=(d:any)=>{
  return typeof d== "string" ? convertDate(d) : new Date(d)
}


const getCountryCode = (phone:any) => {
  if (phone === null) {
    return ;
  }
  const countryCodeMatch = phone.match(/\(\+(\d+)\)/);
  const countryCode = countryCodeMatch ? countryCodeMatch[0] : '';

  return countryCode;
};

const getPhoneNumber = (phone:any) => {
  if (phone === null) {
    return '';
  }
  const countryCodeMatch = phone.match(/\(\+(\d+)\)/);
  const countryCode = countryCodeMatch ? countryCodeMatch[0] : '';
  const formattedPhoneNumber = phone.replace(countryCode, '');

  return formattedPhoneNumber;
};
  return (
    <div className="container top-margin-container">
      <div className="text-left">
        <h6
          className="h6 heading textColor ml-0"
          onClick={handlePatientProfile}
          style={{cursor:'pointer'}}
        >
          &lt; Back to Patient Profile
        </h6>
        <div className="mb-5 ml-3">
          <h5 className="h5 heading mt-4">
            Manage Patient and Caregiver Information
          </h5>

          <div className="text-left mt-5 mb-4">
            {patient ? (
              <Formik
                validationSchema={schema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  handleEnrollPatient(values, resetForm, setSubmitting);
                }}
                initialValues={{
                  isPatientManage: patient.isManage === true ? true : false,
                  firstName: patient.firstName || "",
                  middleName: patient.middleName || "",
                  lastName: patient.lastName || "",
                  dob: patient.dob || "",
                  gender: parseInt(patient.gender) || "",
                  // countryCode: getCountryCode(patient?.phoneNumber) || "(+1)",
                  mobileNumber: getPhoneNumber(patient.phoneNumber) || "",
                  email: patient.email || "",
                  caregivers: patient.caregiver.map((caregiver: any) => ({
                    careGiverUid: caregiver.uuid || "",
                    careGiverisAuthToCondPatAssess: caregiver.isAuthCPA === true ? true : false,
                    careGiverFirstName: caregiver.firstName || "",
                    careGiverMiddleName: caregiver.middleName || "",
                    careGiverLastName: caregiver.lastName || "",
                    careGiverRelation: parseInt(caregiver.relationshipToPatient) || "",
                    careGiverDob: caregiver.dob || "",
                    careGiverGender: parseInt(caregiver.gender) || "",
                    // careGiverCountryCode: getCountryCode(caregiver?.phoneNumber) || "(+1)",
                    careGiverPhone: getPhoneNumber(caregiver.phoneNumber) || "",
                    careGiverEmail: caregiver.email || "",
                  })),
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  touched,
                  isSubmitting
                }: any) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <div className="text-left mt-2 d-flex flex-row">
                        <img
                          src={addPatient}
                          style={{ height: "32px", width: "26px" }}
                          alt=""
                        />
                        <h6 className="h6 heading ml-3 mr-5">
                          Patient Information
                        </h6>

                        <Form.Group>
                          <Form.Check
                            // required
                            label={
                              <>
                                <p className="assessques thick-heading mt-2 ml-3">
                                  Patient is able to manage their own profile
                                </p>
                              </>
                            }
                            checked={values.isPatientManage}
                            id="isPatientManage"
                            onChange={handleChange}
                            className="thin-heading graphText"
                          />
                        </Form.Group>
                      </div>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik01"
                      >
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          className="custom-form-input"
                          //placeholder="First Name"
                          type="text"
                          name="firstName"
                          value={values.firstName.trim("")}
                          onChange={handleChange}
                          // isValid={touched.firstName && !errors.firstName}
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik02"
                      >
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          className="custom-form-input"
                          //placeholder="Middle Name"
                          type="text"
                          name="middleName"
                          value={values.middleName.trim("")}
                          onChange={handleChange}
                          // isValid={touched.middleName && !errors.middleName}
                          isInvalid={!!errors.middleName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.middleName}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik03"
                      >
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          className="custom-form-input"
                          type="text"
                          //placeholder="Last Name"
                          name="lastName"
                          value={values.lastName.trim("")}
                          onChange={handleChange}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik04"
                      >
                        <Form.Label>Date of Birth *</Form.Label>
                        <DatePicker
                          className="react-date-picker custom-form-input-date"
                          name="dob"
                          value={values.dob ? checkDOB(values.dob): ""}
                          format="MM/dd/yyyy"
                          onChange={(date: any) => {
                            if (date) {
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: date,
                                },
                              });
                            } else {
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: "",
                                },
                              });
                            }
                          }}
                          maxDate={new Date()}
                        />
                        {errors.dob && (
                          <div className="errorText">{errors.dob}</div>
                        )}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik05"
                      >
                        <Form.Label>Gender*</Form.Label>

                        {gender.length > 0 ? (
                          <Field
                            as={Select}
                            name="gender"
                            options={gender}
                            onChange={(selectedOption: { value: any }) => {
                              handleChange({
                                target: {
                                  name: "gender",
                                  value: selectedOption.value,
                                },
                              });
                            }}
                            styles={selectStyle}
                            value={gender.find(
                              (option: any) => option.value == values.gender
                            )}
                            placeholder="Choose One"
                            isSearchable
                          />
                        ) : (
                          <p>Loading...</p>
                        )}
                        {errors.gender && (
                          <div className="errorText">{errors.gender}</div>
                        )}
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationFormik06"
                      >
                        <Form.Label>Mobile Phone Number *</Form.Label>
                        {/* {countryCode.length > 0 ? (
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="countryCode">
                          <Field
                            as={Select}
                            name="countryCode"
                            options={countryCodeList}
                            onChange={(selectedOption: { value: any }) => {
                              handleChange({
                                target: {
                                  name: "countryCode",
                                  value: selectedOption.value,
                                },
                              });
                            }}
                            styles={selectStyleMobile}
                            value={ values.countryCode ? countryCodeList.find(
                              (option) => option.value === values.countryCode
                            ) : countryCodeList[0]
                          }
                            placeholder="Choose One"
                            isSearchable
                          />
                        </InputGroup.Text> */}
                        {/* <Form.Control
                          className="custom-form-input"
                          type="text"
                          name="mobileNumber"
                          value={values.mobileNumber.trim("")}
                          onChange={handleChange}
                          isInvalid={!!errors.mobileNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mobileNumber}
                        </Form.Control.Feedback> */}
                        <InputMask
                          mask="+1 (999) 999-9999"
                          placeholder="+1 (999) 999-9999"
                          // maskChar={null}
                          value={values.mobileNumber.trim("")}
                          onChange={(e) => {
                            const maskedValue = e.target.value;
                            const numericValue = maskedValue.replace(/[^\d]/g, '');
                            const trimmedValue = numericValue.substring(1); 
                            handleChange({
                              target: {
                                name: 'mobileNumber',
                                value: trimmedValue
                              }
                            });
                          }}
                          className="custom-form-input"
                          type="text"
                          name="mobileNumber"
                        />
                          {errors.mobileNumber && (
                            <div className="errorText">{errors.mobileNumber}</div>
                          )}
                      {/* </InputGroup>
                    ) : (
                      <div>...Loading</div>
                    )} */}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationFormik07"
                      >
                        <Form.Label>Email *</Form.Label>
                        <Form.Control
                          className="custom-form-input"
                          //placeholder="Email"
                          type="text"
                          name="email"
                          value={values.email.trim("")}
                          onChange={handleChange}
                          // isValid={touched.email && !errors.email}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <div>
                      <FieldArray name="caregivers">
                        {({ remove, push }) => (
                          <div>
                            {values.caregivers.map((item: any, index: any) => (
                              <div className="horizontal-line mt-5" key={index}>
                                <div className="d-flex flex-row mt-5">
                                  <img
                                    src={careGiver}
                                    alt=""
                                    style={{ height: "35px", width: "39px" }}
                                  />
                                  <h6 className="h6 heading ml-3 mt-2 mr-5">
                                    Caregiver Information
                                  </h6>
                                  <Form.Group>
                                    <Form.Check
                                      // required
                                      label={
                                        <>
                                          <p className="assessques thick-heading mt-2 ml-3">
                                            Authorized to Conduct Patient
                                            Assessments
                                          </p>
                                        </>
                                      }
                                      id="careGiverisAuthToCondPatAssess"
                                      name={`caregivers.${index}.careGiverisAuthToCondPatAssess`}
                                      checked={
                                        values.caregivers[index]
                                          ?.careGiverisAuthToCondPatAssess
                                      }
                                      onChange={handleChange}
                                      className="thin-heading graphText mt-2"
                                    />
                                  </Form.Group>

                                  <div className="d-flex ml-auto">
                                    {index > 0 && (
                                      <button
                                        // className="primary-btn"
                                        className="cross"
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        X
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <Form.Row>
                                  <Form.Group
                                    as={Col}
                                    md="4"
                                    controlId={`validationFormikFirstName${index}`}
                                  >
                                    <Form.Label>First Name*</Form.Label>
                                    <Form.Control
                                      className="custom-form-input"
                                      type="text"
                                      name={`caregivers.${index}.careGiverFirstName`}
                                      value={
                                        values.caregivers[index]
                                          ?.careGiverFirstName || ""
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                        setSchemaN(manageSchema2);
                                      }}
                                      isInvalid={
                                        values.caregivers[index]
                                          .careGiverisAuthToCondPatAssess
                                          ? errors.caregivers?.[index]
                                              ?.careGiverFirstName &&
                                            values.caregivers[index]
                                              .careGiverisAuthToCondPatAssess
                                          : optionalFieldValidation(
                                              values.caregivers[index]
                                            )
                                          ? errors.caregivers?.[index]
                                              ?.careGiverFirstName
                                          : ""
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.caregivers?.[index]
                                        ?.careGiverFirstName || ""}
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="4"
                                    controlId="validationFormik02"
                                  >
                                    <Form.Label>Middle Name</Form.Label>
                                    <Form.Control
                                      className="custom-form-input"
                                      type="text"
                                      name={`caregivers.${index}.careGiverMiddleName`}
                                      value={
                                        values.caregivers[index]
                                          ?.careGiverMiddleName || ""
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                        setSchemaN(manageSchema2);
                                      }}
                                      isInvalid={
                                        values.caregivers[index]
                                          .careGiverisAuthToCondPatAssess
                                          ? errors.caregivers?.[index]
                                              ?.careGiverMiddleName &&
                                            values.caregivers[index]
                                              .careGiverisAuthToCondPatAssess
                                          : optionalFieldValidation(
                                              values.caregivers[index]
                                            )
                                          ? errors.caregivers?.[index]
                                              ?.careGiverMiddleName
                                          : ""
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.caregivers?.[index]
                                        ?.careGiverMiddleName || ""}
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="4"
                                    controlId="validationFormik03"
                                  >
                                    <Form.Label>Last Name*</Form.Label>
                                    <Form.Control
                                      className="custom-form-input"
                                      type="text"
                                      name={`caregivers.${index}.careGiverLastName`}
                                      value={
                                        values.caregivers[index]
                                          ?.careGiverLastName || ""
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                        setSchemaN(manageSchema2);
                                      }}
                                      isInvalid={
                                        values.caregivers[index]
                                          .careGiverisAuthToCondPatAssess
                                          ? errors.caregivers?.[index]
                                              ?.careGiverLastName &&
                                            values.caregivers[index]
                                              .careGiverisAuthToCondPatAssess
                                          : optionalFieldValidation(
                                              values.caregivers[index]
                                            )
                                          ? errors.caregivers?.[index]
                                              ?.careGiverLastName
                                          : ""
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.caregivers?.[index]
                                        ?.careGiverLastName || ""}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                  <Form.Group
                                    as={Col}
                                    md="4"
                                    controlId="validationFormik01"
                                  >
                                    <Form.Label>
                                      Relationship to Patient*
                                    </Form.Label>
                                    <Field
                                      as={Select}
                                      name={`caregivers.${index}.careGiverRelation`}
                                      options={relationship}
                                      isSearchable
                                      placeholder="Choose One"
                                      onChange={(selectedOption: {
                                        value: any;
                                      }) => {
                                        handleChange({
                                          target: {
                                            name: `caregivers.${index}.careGiverRelation`,
                                            value: selectedOption.value,
                                          },
                                        });
                                        setSchemaN(manageSchema2);
                                      }}
                                      styles={selectStyle}
                                      value={relationship.find(
                                        (option: any) =>
                                          option.value ==
                                          values.caregivers[index]
                                            .careGiverRelation
                                      )}
                                    ></Field>

                                    {(values.caregivers[index]
                                      .careGiverisAuthToCondPatAssess
                                      ? errors.caregivers?.[index]
                                          ?.careGiverRelation &&
                                        values.caregivers[index]
                                          .careGiverisAuthToCondPatAssess
                                      : optionalFieldValidation(
                                          values.caregivers[index]
                                        )
                                      ? errors.caregivers?.[index]
                                          ?.careGiverRelation
                                      : false) && (
                                      <div className="errorText">
                                        {
                                          errors.caregivers[index]
                                            ?.careGiverRelation
                                        }
                                      </div>
                                    )}
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="4"
                                    controlId="validationFormik01"
                                  >
                                    <Form.Label>Date of Birth *</Form.Label>

                                    <DatePicker
                                      className="react-date-picker custom-form-input-date"
                                      name={`caregivers.${index}.careGiverDob`}
                                      maxDate={new Date()}
                                      format="MM/dd/yyyy"
                                      onChange={(date: any) => {
                                        if (date) {
                                          handleChange({
                                            target: {
                                              name: `caregivers.${index}.careGiverDob`,
                                              value: date
                                                // .toISOString()
                                                // .split("T")[0],
                                            },
                                          });
                                        } else {
                                          handleChange({
                                            target: {
                                              name: `caregivers.${index}.careGiverDob`,
                                              value: "",
                                            },
                                          });
                                        }
                                        setSchemaN(manageSchema2);
                                      }}
                                      value={ values.caregivers[index]
                                        ?.careGiverDob ?
                                        checkDOB(values.caregivers[index]
                                          ?.careGiverDob) : ""
                                      }
                                    />
                                    {(values.caregivers[index]
                                      .careGiverisAuthToCondPatAssess
                                      ? errors.caregivers?.[index]
                                          ?.careGiverDob &&
                                        values.caregivers[index]
                                          .careGiverisAuthToCondPatAssess
                                      : optionalFieldValidation(
                                          values.caregivers[index]
                                        )
                                      ? errors.caregivers?.[index]?.careGiverDob
                                      : false) && (
                                      <div className="errorText">
                                        {errors.caregivers[index]
                                          ?.careGiverDob || ""}
                                      </div>
                                    )}
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="4"
                                    controlId="validationFormik01"
                                  >
                                    <Form.Label>Gender*</Form.Label>
                                    {gender.length > 0 ? (
                                      <Field
                                        as={Select}
                                        placeholder="Choose One"
                                        name={`caregivers.${index}.careGiverGender`}
                                        options={gender}
                                        onChange={(selectedOption: {
                                          value: any;
                                        }) => {
                                          handleChange({
                                            target: {
                                              name: `caregivers.${index}.careGiverGender`,
                                              value: selectedOption.value,
                                            },
                                          });
                                          setSchemaN(manageSchema2);
                                        }}
                                        isSearchable
                                        styles={selectStyle}
                                        value={gender.find(
                                          (option: any) =>
                                            option.value ==
                                            values.caregivers[index]
                                              .careGiverGender
                                        )}
                                      ></Field>
                                    ) : (
                                      <p>Loading...</p>
                                    )}
                                    {(values.caregivers[index]
                                      .careGiverisAuthToCondPatAssess
                                      ? errors.caregivers?.[index]
                                          ?.careGiverGender &&
                                        values.caregivers[index]
                                          .careGiverisAuthToCondPatAssess
                                      : optionalFieldValidation(
                                          values.caregivers[index]
                                        )
                                      ? errors.caregivers?.[index]
                                          ?.careGiverGender
                                      : false) && (
                                      <div className="errorText">
                                        {
                                          errors.caregivers[index]
                                            ?.careGiverGender
                                        }
                                      </div>
                                    )}
                                  </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationFormik01"
                                  >
                                    <Form.Label>
                                      Mobile Phone Number *
                                    </Form.Label>
                                {/* {countryCode.length > 0 ? (
                                    <InputGroup className="mb-3">
                                      <InputGroup.Text id="countryCode">
                                        <Field
                                          as={Select}
                                          name={`caregivers.${index}.careGiverCountryCode`}
                                          options={countryCodeList}
                                          onChange={(selectedOption: { value: any }) => {
                                            handleChange({
                                              target: {
                                                name: `caregivers.${index}.careGiverCountryCode`,
                                                value: selectedOption.value,
                                              },
                                            });
                                          }}
                                          styles={selectStyleMobile}
                                          value={values.caregivers[index].careGiverCountryCode ? countryCodeList.find(
                                            (option) => option.value === values.caregivers[index].careGiverCountryCode
                                          ) : "(+1)"}
                                          
                                          placeholder="Choose One"
                                          isSearchable
                                        />
                                      </InputGroup.Text> */}
                                      {/* <Form.Control
                                                className="custom-form-input"
                                                type="text"
                                                name={`caregivers.${index}.careGiverPhone`}
                                                value={
                                                  values.caregivers[
                                                    index
                                                  ]?.careGiverPhone.trim("") || ""
                                                }
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setSchemaN(manageSchema2);
                                                }}
                                                isInvalid={
                                                  values.caregivers[index]
                                                    .careGiverisAuthToCondPatAssess
                                                    ? errors.caregivers?.[index]
                                                        ?.careGiverPhone &&
                                                      values.caregivers[index]
                                                        .careGiverisAuthToCondPatAssess
                                                    : optionalFieldValidation(
                                                        values.caregivers[index]
                                                      )
                                                    ? errors.caregivers?.[index]
                                                        ?.careGiverPhone
                                                    : ""
                                                }
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.caregivers?.[index]?.careGiverPhone ||
                                                  ""}
                                              </Form.Control.Feedback> */}
                                    
                                    {/* </InputGroup>
                                  ) : (
                                    <div>...Loading</div>
                                  )} */}

                                  <InputMask
                                      mask="+1 (999) 999-9999"
                                      placeholder="+1 (999) 999-9999"
                                      // maskChar={null}
                                      className="custom-form-input"
                                      type="text"
                                      name={`caregivers.${index}.careGiverPhone`}
                                      value={
                                        values.caregivers[
                                          index
                                        ]?.careGiverPhone.trim("") || ""
                                      }
                                      // onChange={(e) => {
                                      //   handleChange(e);
                                      //   setSchemaN(enrollSchema2);
                                      // }}
                                      onChange={(e) => {
                                        const maskedValue = e.target.value;
                                        const numericValue = maskedValue.replace(/[^\d]/g, '');
                                        const trimmedValue = numericValue.substring(1); 
                                        handleChange({
                                          target: {
                                            name: `caregivers.${index}.careGiverPhone`,
                                            value: trimmedValue
                                          }
                                        });
                                        setSchemaN(manageSchema2);
                                      }}
                                      />
                                {(values.caregivers[index]
                                  .careGiverisAuthToCondPatAssess
                                  ? errors.caregivers?.[index]
                                      ?.careGiverPhone &&
                                    values.caregivers[index]
                                      .careGiverisAuthToCondPatAssess
                                  : optionalFieldValidation(
                                      values.caregivers[index]
                                    )
                                  ? errors.caregivers?.[index]?.careGiverPhone
                                  : false) && (
                                  <div className="errorText">
                                    {errors.caregivers[index]?.careGiverPhone}
                                  </div>
                                )}
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationFormik01"
                                  >
                                    <Form.Label>Email *</Form.Label>
                                    <Form.Control
                                      className="custom-form-input"
                                      type="text"
                                      name={`caregivers.${index}.careGiverEmail`}
                                      value={
                                        values.caregivers[index]
                                          ?.careGiverEmail || ""
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                        setSchemaN(manageSchema2);
                                      }}
                                      isInvalid={
                                        values.caregivers[index]
                                          .careGiverisAuthToCondPatAssess
                                          ? errors.caregivers?.[index]
                                              ?.careGiverEmail &&
                                            values.caregivers[index]
                                              .careGiverisAuthToCondPatAssess
                                          : optionalFieldValidation(
                                              values.caregivers[index]
                                            )
                                          ? errors.caregivers?.[index]
                                              ?.careGiverEmail
                                          : ""
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.caregivers?.[index]
                                        ?.careGiverEmail || ""}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Form.Row>
                              </div>
                            ))}
                            <button
                              className="secondary-btn"
                              type="button"
                              onClick={() => {
                                if (
                                  values.caregivers.every(
                                    (caregiver: any) =>
                                    typeof caregiver.careGiverFirstName !== undefined &&
                                    typeof caregiver.careGiverLastName !== undefined &&
                                    typeof caregiver.careGiverRelation !== undefined &&
                                    // typeof caregiver.careGiverDob !== undefined &&
                                    // typeof caregiver.careGiverGender !== undefined &&
                                    typeof caregiver.careGiverPhone !== undefined &&
                                    typeof caregiver.careGiverEmail !== undefined &&
                                      !!caregiver.careGiverFirstName &&
                                      !!caregiver.careGiverLastName &&
                                      !!caregiver.careGiverRelation &&
                                      // !!caregiver.careGiverDob &&
                                      // !!caregiver.careGiverGender &&
                                      !!caregiver.careGiverPhone &&
                                      !!caregiver.careGiverEmail
                                  )
                                ) {
                                  push({
                                    careGiverisAuthToCondPatAssess: true,
                                    careGiverFirstName: "",
                                    careGiverMiddleName: "",
                                    careGiverLastName: "",
                                    careGiverRelation: "",
                                    careGiverDob: "",
                                    careGiverGender: "",
                                    // careGiverCountryCode:"(+1)",
                                    careGiverPhone: "",
                                    careGiverEmail: "",
                                  });
                                } else {
                                  // Swal.fire("Please fill the above caregiver form!");
                                  // notifyError(
                                  //   "Please fill the above caregiver form."
                                  // );
                                  // _appServices.showFlashMsg('info', 'Success', 'Please fill the above caregiver form.', false);
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: 'Please fill the above caregiver form.',
                                  })
                                }
                              }}
                            >
                              + Add Additional Caregiver
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      <p className="thin-heading graphText mt-4">
                        Before submitting enrollment, please be sure that the
                        Caregiver has provided the proper email address and cell
                        phone number. Once you click ENROLL, these contact
                        methods will provide direct access to the patient s
                        account.
                      </p>
                    </div>
                    <Button
                      type="submit"
                      className="primary-btn"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <div>
                <h3>...Loading</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
