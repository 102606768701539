import React from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function UserGuideDetails() {
    const navigate = useNavigate()
    const location = useLocation();
    const data = location.state?.data;
    const handlePatientProfile = () => {
        navigate(-1)
      }

    return (
        <div className='top-margin-container mb-5'>
            <div>
                <h5 className='textColor col-6 text-left mt-5 pl-0 mb-4' onClick={handlePatientProfile} style={{ cursor: 'pointer' }}>&lt; Back</h5>
                <h5 className="h5 thick-heading">{data?.title}</h5>
                <div className='mt-5 d-flex justify-content-center'>
                    <div className='col col-xs-12 col-md-6 col-lg-8'>
                        <img
                            className='card-img-top'
                            src={data?.post_image ? data?.post_image : `https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=170667a&w=0&k=20&c=Q7gLG-xfScdlTlPGFohllqpNqpxsU1jy8feD_fob87U=`}
                            alt={data?.post_image ? "Card image cap" : "Placeholder Image"}
                        />
                    </div>
                </div>
                <div className='text-left mt-5 graphText'>
                    <h5 className="card-title thick-heading">{data?.title}</h5>
                    <p className="card-text" dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                </div>
            </div>
        </div>
    )
}
