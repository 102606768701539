import React, { useEffect, useState } from "react";
import arrow_up from "../../assets/image/arrow_up.png";
import arrow_down from "../../assets/image/arrow_down.png";
import { useNavigate } from "react-router-dom";
import { primary } from "../theme";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { TailSpin } from "react-loader-spinner";
import { getRelationshipNameById } from "../../app/utils/getRelation";
import SendMessageModal from "../../app/component/SendMessageModal/SendMessageModal";
import AppServices from "../../services/appServices";
import { APIEndpoint } from "../../config/apiendpoint";
import AssessmentPdfModel from "../../app/component/AssessmentPdfModel/AssessmentPdfModel";
import { convertDate, convertDateFormat, timeFormat } from "../../app/utils/dateHelper";

interface TableProps {
  data: Array<any>;
  isLoading: boolean;
}

const ViewAllPatientTable: React.FC<TableProps> = ({
  data,
  isLoading,
}) => {

  const navigate = useNavigate();
  const _appServices = new AppServices();
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [patient, setPatient] = useState(null);

  const [showMessageModal, setShowMessageMOdal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showAssessPdfModal, setShowAssessPdfModal] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleSort = (column: string) => {
    _appServices.logData({
      Severity: 'Info',
      Activity: `User sorted patinet data in patient table.`,
      ActivityStatus: 'Success',
      ActivityResponse: 'Page View',
      Operation: 'User Activity',
    })
    const order: "ascending" | "descending" =
      sortOrder === "ascending" ? "descending" : "ascending";

    const sortedDataCopy = [...sortedData].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA === null || valueA === undefined) {
        return order === "ascending" ? 1 : -1;
      }

      if (valueB === null || valueB === undefined) {
        return order === "ascending" ? -1 : 1;
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return order === "ascending"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return order === "ascending"
        ? Number(valueA) - Number(valueB)
        : Number(valueB) - Number(valueA);
    });

    setSortOrder(order);
    setSortColumn(column);
    setSortedData(sortedDataCopy);
  };

  const handleMessageModal = (item: any) => {
    setShowMessageMOdal(true);
    const data = {
      ...item.patient,
      caregiver: [...item.caregiverDatas]
    };
    setPatient(data);
  };
  const handleCloseMessage = () => {
    setShowMessageMOdal(false);
  };



  const handleAssessmentModalShow = async (item: any, uid: any) => {
    setShowAssessPdfModal(true);
    setAssessmentData(null)
    const patientUid = item?.patient?.uuid;
    const patientAssessmentUid = uid;
    try {
      const resp = await _appServices.getData(
        `${APIEndpoint.assessmentList}/info/${patientAssessmentUid}?patientUid=${patientUid}`
      );
      if (resp.status === 200 && resp.success == true) {
        setAssessmentData(resp.data);
      }
      _appServices.logData({
        Severity: 'Info',
        Activity: `User viewed completed assessment pdf.`,
        ActivityStatus: 'Success',
        ActivityResponse: 'Page View',
        Operation: 'User Activity',
      })
    } catch (error) {
      console.error("Error fetching datad:", error);
      _appServices.logData({
        Severity: 'Error',
        Activity: `User failed to view completed assessment pdf.`,
        ActivityStatus: 'Failure',
        ActivityResponse: 'Page View',
        Operation: 'User Activity',
      })
    }
  };

  const handleAssessmentClose = () => {
    setShowAssessPdfModal(false);
  };

  return (
    <div className="table-responsive custom-adli-table">
      <table className="table">
        <thead>
          <tr className="table th text-left">
            <th
              className="h"
              onClick={() => handleSort("firstName")}
              style={{ width: "15%" }}
            >
              <span className="mr-3">Patient</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: "7px", width: "14px", marginTop: "-10px" }}
                  alt=""
                  className={
                    sortColumn === "firstName" && sortOrder === "ascending"
                      ? "visible"
                      : "hidden"
                  }
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: "7px",
                    width: "14px",
                    marginTop: "7px",
                    marginLeft: "-14px",
                  }}
                  alt=""
                  className={
                    sortColumn === "firstName" && sortOrder === "descending"
                      ? "visible"
                      : "hidden"
                  }
                />
              </span>
            </th>
            <th style={{ width: "10%" }}></th>
            <th
              onClick={() => handleSort("caregiver[0].lastName")}
              style={{ width: "25%" }}
            >
              <span className="mr-3">DOB</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: "7px", width: "14px", marginTop: "-10px" }}
                  alt=""
                  className={
                    sortColumn === "caregiver[0].lastName" &&
                      sortOrder === "ascending"
                      ? "visible"
                      : "hidden"
                  }
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: "7px",
                    width: "14px",
                    marginTop: "7px",
                    marginLeft: "-14px",
                  }}
                  alt=""
                  className={
                    sortColumn === "caregiver[0].lastName" &&
                      sortOrder === "descending"
                      ? "visible"
                      : "hidden"
                  }
                />
              </span>
            </th>
            <th
              onClick={() => handleSort("completedAt")}
              style={{ width: "40%" }}
            >
              <span className="mr-3">Last Readings</span>
              <span>
                <img
                  src={arrow_up}
                  style={{ height: "7px", width: "14px", marginTop: "-10px" }}
                  alt=""
                  className={
                    sortColumn === "completedAt" && sortOrder === "ascending"
                      ? "visible"
                      : "hidden"
                  }
                />
              </span>
              <span>
                <img
                  src={arrow_down}
                  style={{
                    height: "7px",
                    width: "14px",
                    marginTop: "7px",
                    marginLeft: "-14px",
                  }}
                  alt=""
                  className={
                    sortColumn === "completedAt" && sortOrder === "descending"
                      ? "visible"
                      : "hidden"
                  }
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody className="text-left">
          {isLoading ? (
            <tr key={sortedData?.length}>
              <td colSpan={4} style={{ textAlign: "center" }}>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "100px" }}
                >
                  <div>
                    <TailSpin
                      visible={true}
                      height="80"
                      width="80"
                      color="#03A84E"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>
              </td>
            </tr>
          ) : sortedData.length > 0 ? (
            sortedData.map((item, index) => (
              <tr
                key={index}
              >
                <td className="h" style={{ width: "15%" }}>
                  <div className="d-flex flex-row">
                    <div>
                      <span className="heading big-p capitalized">
                        {item?.patient?.lastName || ""},{" "}
                        {item?.patient?.firstName || ""}{" "}
                        {item?.patient?.middleName || ""}
                      </span>
                      <br />
                    </div>
                  </div>
                </td>
                <td style={{ width: "20%", cursor: "pointer" }}>
                  <div
                    className="ml-3 textColor"
                    style={{ fontWeight: "bold", cursor: loading ? "progress" : "pointer" }}
                    onClick={() => {
                      let selected = item.patient
                      navigate(
                        `/user-panel/patientProfile/${selected.uuid}`,
                        { state: selected }
                    )
                    }}
                  >
                    View Profile
                  </div>
                </td>

                <td>
                  <p className="heading">
                    {convertDate(item?.patient?.dob)}
                  </p>
                </td>

                <td>
                {item?.lastReading?.map(
                    (reading: any, readingIndex: any) => (
                      <div className="completed_assessment_container">
                         {reading?.lastReading}
                      {/* <div className="completed_assessment_div assessques heading graphColor d-flex" key={readingIndex} >
                        <p className="profile-name ml-5 mb-0"
                        >
                          {reading?.lastReading}
                        </p>
                      </div> */}
                      </div>
                    )
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center">
                <span className="p-4 thick-heading">No data</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <SendMessageModal
        showModal={showMessageModal}
        handleClose={handleCloseMessage}
        data={patient}
      />

      <AssessmentPdfModel
        showModal={showAssessPdfModal}
        data={assessmentData}
        handleClose={handleAssessmentClose}
      />
    </div>
  );
};

export default ViewAllPatientTable;
