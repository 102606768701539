import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot
} from 'recharts';
import './styles.css';
import { getDDDMMDD, convertDate, getTime12hrsData } from '../../utils/dateHelper';

const RestingHeartRateGraph = ({ data }) => {


  const calculateBarchartWidth = (numberOfItem = 7) => { if (numberOfItem > 6) return numberOfItem * 75; return '100%'; };


  const formattedData = (data).map(entry => ({
    date: getDDDMMDD(entry.date),
    bpm: entry.pulse
  }));


  const tableData = (data).map(entry => ({
    dateTime: `${convertDate(entry.date)} ${getTime12hrsData(entry.date)}`,
    device: entry.device,
    bpm: `${entry.range} ${entry.unit}`
  }));

  return (
    <div className="graph-container">
      <h2 className='graphHeading'>Resting Heart Rate</h2>
      {(data && data.length)?<div style={{ display: 'flex' }}>
        <div style={{ width: '65px' }}>
          <ResponsiveContainer width={'100%'} height={275}>
            <LineChart data={formattedData}>
              <YAxis
                dataKey="bpm"
                axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                tick={{ fill: '#000', fontSize: 12 }}
                padding={{ right: 20 }}
                tickLine={false}
                tickMargin={20}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ overflowX: 'auto', width: 'calc(100% - 70px)' }}>
          <ResponsiveContainer width={calculateBarchartWidth(formattedData.length)} height={300}>
            <LineChart data={formattedData}>
              <CartesianGrid
                vertical={false}
                strokeDasharray="2 2"
                stroke="#000"
              />
              <XAxis
                dataKey="date"
                dx={-30}
                // dy={25}
                minTickGap={-200}
                textAnchor="start"
                tick={{ fill: '#000', fontSize: 12 }}
                tickLine={false}
                axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                padding={{ left: 10 }}
                tickMargin={10}
              />
              <Tooltip />
              <Legend
                verticalAlign="top"
                align="right"
                iconType="circle"
                iconSize={26}
                wrapperStyle={{ paddingLeft: 20, }}
                formatter={(value, entry) => (
                  <span style={{ color: '#000', fontSize: 14 }}>
                    {value}
                  </span>
                )}
              />
              <Line
                type="monotone"
                dataKey="bpm"
                stroke="#E2B71F"
                name="Beats Per Minute (BPM)"
                strokeWidth={3}
                dot={<Dot r={5} fill="#E2B71F" stroke="#E2B71F" strokeWidth={2} />}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>:
      <p className='d-flex justify-content-center align-items-center' style={{minHeight:300}}>No Chart Data Found</p>
      }

      {<div className='graphtable-container'>
        <table className='graphdata-table'>
          <thead className='graphTableHead'>
            <tr className='graphTableborder'>
              <th style={{fontSize: 18, fontWeight: '700', color: '#000000'}}>Date & Time</th>
              <th style={{fontSize: 18, fontWeight: '700', color: '#000000'}}>Device</th>
              <th style={{fontSize: 18, fontWeight: '700', color: '#000000'}}>BPM</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((entry, index) => (
              <tr key={index}>
                <td style={{fontSize: 16, fontWeight: '400', color: '#000000'}}>{entry.dateTime}</td>
                <td style={{fontSize: 16, fontWeight: '400', color: '#000000'}}>{entry.device}</td>
                <td style={{fontSize: 16, fontWeight: '400', color: '#000000'}}>{entry.bpm}</td>
              </tr>
            ))}
          </tbody>
        </table>
       { tableData.length == 0 && <p>
          No Data Found
          </p>}
      </div>}
    </div>
  );
}

export default RestingHeartRateGraph;
