// eslint-disable-next-line
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { primary, top_header_color, top_header_button, bottom_header_color } from '../../../theme/theme';
import { useNavigate } from 'react-router-dom';

export const AuthHeader = () => {
  const [sideBarWidth, setSideBarWidth] = useState("0%")
  // const [staffLogin,setStaffLogin]= useState(true)
  const navigate = useNavigate();
  const openNav = () => {
    setSideBarWidth('80%');
  }

  const closeNav = () => {
    setSideBarWidth('0%');
  }
  const handleStaffMemberLogin = () => {
    // setStaffLogin(!staffLogin)
    // if(staffLogin){
    navigate('/staffMember')
    // }else{
    //   navigate('/')
    // }    
  }
  const handleLandingPage = () => {
    navigate('/')
  }
  const gotoMainPage = () => {
    navigate('/')
  }

  return (
    <div className='nav_container'>
      <div className='top_backGround' style={{ background: top_header_color }} />

      <div className='top_navbar flex-container shadow p-1 mb-5 bg-white rounded' style={{ backgroundColor: bottom_header_color }}>
        <div className='nav_logo'>
          <img className='top_logo' src={require('../../../assets/image/App_logo.png')} alt='' onClick={handleLandingPage} />
        </div>
        <div className='top_right_menu web_menu'>
          {/* <button className="primary-btn mr-5 float-right" onClick={handleStaffMemberLogin}> 
            Staff Member Login
          </button> */}
        </div>
        <div className='top_right_menu mobile_menu'>
          {/* <button className="primary-btn " onClick={handleStaffMemberLogin}> 
            Staff Member Login
          </button> */}
        </div>
      </div>
      <div className='backDrop' style={{ display: sideBarWidth == '0%' ? 'none' : 'block' }} onClick={closeNav}></div>
      <div id="mySidenav" className="sidenav" style={{ width: sideBarWidth, borderRight: sideBarWidth == "0%" ? "none":"2pt solid whitesmoke" }}>
        <span className="closebtn" onClick={closeNav}>&times;</span>
        <div className='top_right_menu' style={{ justifyContent: 'center' }}>
          <a href='.' className='mt-4 mb-4 mr-4' style={{ color: primary }}>Login </a>&nbsp;&nbsp;
          <Button href='./createProvider' className='btn mt-4 mb-4 ' style={top_header_button}>Create Account</Button>
        </div>
      </div>
    </div>
  );
};
