import React from "react";
import msgSent from "../../../../assets/image/msgSent.png";
import { btn_primary } from "../../../../theme/theme";
import { useNavigate } from "react-router-dom";

export default function InvitationSent() {
  const navigate = useNavigate();

  const handleEnrollNew = () => {
    navigate("/user-panel/enroll");
  };

  const handleViewDashboard = () => {
    navigate("/user-panel/dashboard");
  };

  return (
    <div className="container top-margin-container">
      <div>
        <img src={msgSent} alt="" style={{ height: "42px", width: "76px" }} />
        <h5 className="h5 heading mt-4 mb-4">Your Invite Has Been Sent</h5>
        <p className="graphText thin-heading mb-5 pb-3">
        Your invite to enroll has been sent. Once the patient enrolls, you will be <br/>notified and their activity will appear on patient’s profile.
        </p>
        <button
          type="button"
          onClick={handleEnrollNew}
          className="primary-btn hover mr-3"
        >
          Enroll Another Patient
        </button>
        <button
          type="button"
          onClick={handleViewDashboard}
          className="primary-btn"
        >
          View Dashboard
        </button>
      </div>
    </div>
  );
}
