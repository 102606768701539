import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const notifyInfo = (message: any) => toast.info(message,{
  position: 'bottom-center',
});
export const notifySuccess = (message: any) => {
  toast.success(message, {
    position: 'bottom-center',
  });
};
export const notifyError = (message: any) => toast.error(message,{
  position: 'bottom-center',
});
export const failedWarning = () => toast.error("Sorry, someting went wrong.");
 export const info = () => Swal.fire({
    position: "bottom",
    icon: "info",
    title: "Session Expired!",
    text: "Your session expired. Login again!",
    showConfirmButton: false,
    timer: 3000
  });
