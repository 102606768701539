import React, { useEffect, useState } from 'react';
import { TailSpin } from "react-loader-spinner";
import './ManageStyle.css';
import Delete from "../../../../assets/image/Delete.png";
import Swal from 'sweetalert2';
import InviteStaffMemberPopup from './InviteStaffMemberPopup';
import AppServices from "../../../../services/appServices";
import { APIEndpoint } from '../../../../config/apiendpoint';

const ManageStaffMember = () => {
  const _appServices = new AppServices();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [memberLists, setMemberLists] = useState<any>([]);

  const handlePendingClick = (entry: any) => {
    setPopupContent(`Details for entry with Cycle End Date: ${entry.cycleEndDate}`);
    setShowPopup(true);
  };


  const handleClose = () => {
    setShowPopup(false);
  };
  const handleShow = () => {
    setShowPopup(false)
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {    
    fetchStaffData();
  }, []);

  const fetchStaffData = async () => {
    try {
      setLoading(true);
      const resp = await _appServices.getData(
        `${APIEndpoint.staff}?type=all`
      );
      setMemberLists(resp?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteStaffMember = async (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A6317B",
      cancelButtonColor: "#202656",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          const resp = await _appServices.deleteData(
            `${APIEndpoint.staff}/${id}`
          );
          if (resp.status === 200 && resp.success == true) {
            fetchStaffData()
            Swal.fire({
              title: "Deleted!",
              text: resp.message,
              icon: "success",
            });
            _appServices.logData({
              Severity: "Info",
              Activity: resp?.message,
              ActivityStatus: "Success",
              ActivityResponse: "Data Submitted.",
              Operation: "Staff Delete Activity",
            });
          } else {
            // const message = _appServices.getErrorMessage(resp)
            const message = 'Something went wrong.'
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: message,
            })
          }
        } catch (err: any) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err?.message || "Something went wrong.",
          });
          // notifyError(err)
          // _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
          // _appServices.logData({
          //   Severity: "Error",
          //   Activity: `User deleted completed assessment through patient profile.`,
          //   ActivityStatus: "Failure",
          //   ActivityResponse: err,
          //   Operation: "User Activity",
          // });
        }
      }
    });
  };


  return (
    <div className="graph-container mt-5">
      <div className='d-flex justify-content-between align-items-center px-5'> <h2 className='graphHeading mb-4'>Manage Staff Access</h2>  <button onClick={() => { setShowPopup(true) }} className="btn btn-lg mb-3 primary-btn">Invite Staff Member</button></div>
      <div className="px-5">
        <table className="managestaff-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Title</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr >
                <td colSpan={4} style={{ textAlign: "center" }}>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "100px" }}
                  >
                    <div>
                      <TailSpin
                        visible={true}
                        height="80"
                        width="80"
                        color="#03A84E"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ) : memberLists.length ? memberLists.map((entry: any, index: any) => (
              <tr key={index}>
                <td>{entry?.firstName} {entry?.middleName} {entry?.lastName}</td>
                <td>{entry?.title}</td>
                <td>{entry?.email}</td>
                <td style={{ color: '#03A84E' }}>
                  {entry?.status === 'Invite Pending' ? (
                    <span className="pending" style={{cursor:'default'}} >
                      {entry?.status}
                    </span>

                  ) : (
                    !entry.isNotDelete && <div className='d-flex justify-content-between'>
                      <span style={{cursor:'default'}}>{entry?.status}</span>
                      <span className="d-flex mt-1">
                        <img
                          src={Delete}
                          className="mr-3"
                          style={{
                            height: "22px",
                            width: "22px",
                            cursor: "pointer",
                          }}
                          alt="Delete"
                          onClick={() => onDeleteStaffMember(entry?.uuid)}
                        />
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            )) :
              <tr>
                <td colSpan={4} className="text-center">
                  <span className="p-4 thick-heading">No data</span>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      {/* {showPopup && (
      <div className="popup-overlay">
        <div className="popup-content">
          <h3>Popup Title</h3>
          <p>{popupContent}</p>
          <button onClick={handleClosePopup}>Close</button>
        </div>
      </div>
    )} */}
      <InviteStaffMemberPopup show={showPopup} onHide={handleClose} onClick={handleShow} addStaffEvent={fetchStaffData} editMode={false} />
    </div>
  );
}

export default ManageStaffMember