import React,{useContext, useEffect} from 'react';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';


export const Loader = () => {  
  return (
    <div className='text-center'>
        <div className="loader"></div> 
        <p className='mt-2'>
            Please wait ...
        </p> 
    </div>

  );
};
