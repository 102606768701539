import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import AppServices from '../../../../services/appServices';
import { APIEndpoint } from '../../../../config/apiendpoint';
import { Loader } from '../../../component/loader/loader';
import { useNavigate } from 'react-router-dom';
export default function UserGuides() {

  const _appServices = new AppServices()
  const navigate = useNavigate()

  const [userGuides, setUserGuides] = useState([])


  useEffect(() => {

    const fetchData = async () => {
      try {
        const resp = await _appServices.getData(`${APIEndpoint.getUserGuides}`)
        setUserGuides(resp?.data[0]?.posts)
      } catch (error) {
        console.log(error)
      }
    }
    if (userGuides && userGuides.length == 0) {
      fetchData()
    }
  }, [userGuides])

  const handleUserGuides = (data: any) => {
    navigate('/user-panel/userGuideDetails', { state: { data } })
  }

  const truncateText = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    } else {
      return text;
    }
  };
  const handlePatientProfile = () => {
    navigate(-1)
  }
  return (
    <div className='top-margin-container mb-5'>
      <h5 className='textColor col-6 text-left mt-5 pl-0 mb-4' onClick={handlePatientProfile} style={{ cursor: 'pointer' }}>&lt; Back</h5>
      <h1 className='h1 thick-heading' style={{ color: '#010743' }}>User guide</h1>
      {/* <br/> <br/> <br/> <br/> <br/> */}
      <Row>
        {userGuides?.length > 0 ? userGuides.map((data: any, key: any) => {
          return (
            <Col md={4} xs={12} key={key} className="d-flex justify-content-center align-items-center">
              <div className="card mt-5 text-left" onClick={() => handleUserGuides(data)} style={{ cursor: 'pointer' }}>
                <img
                  className="card-img-top card-img"
                  src={data?.post_image ? data?.post_image : `https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=170667a&w=0&k=20&c=Q7gLG-xfScdlTlPGFohllqpNqpxsU1jy8feD_fob87U=`}
                  alt={data?.post_image ? "Card image cap" : "Placeholder Image"}
                />
                <div className="card-body graphText">
                  <h5 className="card-title thick-heading">{data?.title}</h5>
                  <p className="card-text txt" dangerouslySetInnerHTML={{ __html: truncateText(data?.description, 50) }}></p>
                </div>
              </div>
            </Col>
          )
        })
          :
          <Col xs={12} className="d-flex justify-content-center mt-5">
            <Loader />
          </Col>
        }
      </Row>
    </div>
  )
}
