import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { APIEndpoint } from "../../config/apiendpoint";
import AppServices from "../../services/appServices";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import { convertDate, convertDateFormat1 } from "../../app/utils/dateHelper";
import { UserContext } from "../../context/UserContext";
import {
  selectStyle,
  selectStyleSearchWhite
} from "../theme";

export default function NewAssessment({
  show,
  onHide,
  onClick,
  editMode,
  uuidToEdit,
  isLoading,
  name
}: any) {
  const _appServices = new AppServices();
  const [patientUuid, setPatientUuid] = useState("");
  const [assessmentUuid, setAssessmentUuid] = useState("");
  const [frequency, setFrequency] = useState("");
  const [dueDate, setDueDate] = useState<any>("");
  const [assessmentList, setAssessmentList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assessmentError, setAssessmentError] = useState("");
  const [patientError, setPatientError] = useState("");
  const [dueDateError, setDueDateError] = useState("");
  const [frequncyError, setFrequencyError] = useState("");

  const { frequencyInfo } = useContext(UserContext);

  const [frequencyId, setFrequencyId] = useState([]);

  const uidToEdit = uuidToEdit;
  const location = useLocation();
  const [btnActive, setIsBtnActive] = useState(false);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const formattedDate = date.toLocaleDateString("en-US");
    return formattedDate;
  };

  useEffect(() => {
    if (uidToEdit) {
      if (editMode) {
        setAssessmentUuid(uidToEdit?.assessmentUid);
        setPatientUuid(uidToEdit?.patientUid);
        setDueDate(formatDate(uidToEdit?.dueDate));
        setFrequency(uidToEdit?.frequencyName);
      }
    }
  }, [uidToEdit, editMode]);

  const getAssessmentToEdit = async () => {
    const assessmentEditData = await _appServices.getData(
      `${APIEndpoint.postAssessment}/${uidToEdit}`
    );
  };

  useEffect(() => {
    if (frequencyInfo && frequencyInfo.length) {
      let frequencyDatas: any = frequencyInfo?.map((d: any) => ({
        value: d.id,
        label: d.name,
      }));
      setFrequencyId(frequencyDatas);
    }
  }, [frequencyInfo]);

  const getFrequencyName = (id: any) => {
    const foundOption: any = frequencyId?.find(
      (option: any) => option.value === id
    );
    return foundOption?.label;
  };



  const handleSeachOptionClick = (result: any) => {
    setSearchQuery(`${result.lastName}, ${result.firstName}`);
    setPatientUuid(result.uuid);
  };

  const getDate = (date: any) => {
    const re = date.split("T")[0];
    return re;
  };

  const handleSave = async () => {
    setAssessmentError("");
    setPatientError("");
    setDueDateError("");
    setFrequencyError("");
    setIsBtnActive(true);

    if (!frequency) {
      setFrequencyError("Frequency is required");
    }
    if (!assessmentUuid) {
      setAssessmentError("Assessment is required");
    }

    if (!patientUuid) {
      setPatientError("Patient is required");
    }

    if (!dueDate) {
      setDueDateError("Due Date is required");
    }
    if (!assessmentUuid || !patientUuid || !dueDate || !frequency) {
      setIsBtnActive(false);
      return;
    }

    if (editMode) {
      let postJson = {
        assignUid: uidToEdit?.uuid,
        assessmentUid: assessmentUuid,
        patientUid: patientUuid,
        dueDate: getDate(uidToEdit.dueDate),
        frequencyName: frequency,
      };

      try {
        const resp = await _appServices.patchData(
          `${APIEndpoint.getManageAssignedAssessment}/${patientUuid}`,
          postJson
        );
        if (resp.status === 200 && resp.success == true) {
          // notifySuccess(resp.message);
          setFrequency("");
          onClick();
          _appServices.showFlashMsg('success', 'Success', resp?.message, false);
          _appServices.logData({
            Severity: 'Info',
            Activity: `User edited assessment for patient - ${name?.firstName}.`,
            ActivityStatus: 'Success',
            ActivityResponse: 'Data Submitted',
            Operation: 'User Activity',
          })
        }else{
          const message = _appServices.getErrorMessage(resp)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: message,
          })
        }
      } catch (err:any) {
        console.log(err);
        // let captureErrorWord = ['sql','salt', 'cannot read properties of null']
        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: (err && err?.message) ? captureErrorWord.some(i => err?.message.toLowerCase().includes(i)) ? "An error occurred while calling the service, please try after some time.": err?.message:"An error occurred while calling the service, please try after some time.",
        // })
        const message = _appServices.getErrorMessage(err)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message || "Something went wrong.",
        })
        // _appServices.showFlashMsg('error', 'Success', 'Something went wrong.', false);
        _appServices.logData({
          Severity: 'Error',
          Activity: `User failed to edit assessment for patient - ${name?.firstName}.`,
          ActivityStatus: 'Failure',
          ActivityResponse: err,
          Operation: 'User Activity',
        })
      }
    } else {
      let postJson = {
        assessmentUid: assessmentUuid,
        patientUid: patientUuid,
        dueDate: convertDateFormat1(dueDate),
        frequencyName: frequency,
      };
      console.log("postJson", postJson)
      try {
        const resp = await _appServices.postData(
          `${APIEndpoint.postAssessment}`,
          postJson
        );
        if ((resp.status === 201 || resp.status === 200) && resp.success === true) {
          setSearchQuery("");
          setSelectedValue("");
          setDueDate("");
          setFrequency("");
          onClick();
          _appServices.showFlashMsg('success', 'Success', resp?.message, false);
          _appServices.logData({
            Severity: 'Info',
            Activity: `User assigned assessment for patient.`,
            ActivityStatus: 'Success',
            ActivityResponse: 'Data Submitted',
            Operation: 'User Activity',
          })
        } else {
          // const message = resp?.message[0].split('T')[0]||'Something went wrong.'
          const message = _appServices.getErrorMessage(resp)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: message,
          })
          ;
        }
      } catch (error:any) {
        // let captureErrorWord = ['sql','salt', 'cannot read properties of null']
        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text:  (error && error?.message) ? captureErrorWord.some(i => error?.message.toLowerCase().includes(i)) ? "An error occurred while calling the service, please try after some time.": error?.message:"An error occurred while calling the service, please try after some time.",
        // })
        const message = _appServices.getErrorMessage(error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: message || "Something went wrong.",
        })
        console.error("Error fetching data:", error);
        _appServices.logData({
          Severity: 'Error',
          Activity: `User assigned assessment for patient.`,
          ActivityStatus: 'Failure',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        })
      }
    }
    setIsBtnActive(false);
  };

  const handleHide = () => {
    setAssessmentError("");
    setPatientError("");
    setDueDateError("");
    setSearchQuery("");
    setSelectedValue("");
    setDueDate("");
    setFrequency("");
    onHide();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await _appServices.getData(
          `${APIEndpoint.search}${searchQuery}`
        );
        setSearchResults(resp.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery.trim() !== "") {
      fetchData();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const resp = await _appServices.getData(
    //       `${APIEndpoint.assessmentList}`
    //     );
    //     setAssessmentList(resp?.data);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // fetchData();
  }, []);

  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      setAssessmentUuid(selectedOption.value);
    }
    setSelectedValue(selectedOption.value);
    setAssessmentError("");
  };

  const assessmentOptions = assessmentList?.map((option: any) => ({
    value: option.uuid,
    label: option.name,
    unit: option.unit,
  }));

  const handleDateChange = (date: any) => {
    const d = new Date(date);
    
    setDueDate(d);
    setDueDateError("");
  };

  const handleInputChange = (value: any, { action }: any) => {
    if (action === "input-change") {
      setSearchQuery(value);
    }
  };

  function formatSearchResults(results: any) {
    return results?.map(
      (result: { firstName: any; uuid: any; dob: any; lastName: any }) => ({
        // label: result.firstName,
        label: `${result.lastName}, ${
          result.firstName
        } DOB: ${convertDate(result.dob)}`,
        value: result.uuid,
      })
    );
  }

  return (
    <div>
      <Modal
        className="assignModal assign-assessment-pop-model"
        show={show}
        animation={false}
        onHide={handleHide}
      >
        {/* <Modal.Header
          className="header-popup"
          closeButton
          onHide={handleHide}
          placeholder={null}
        ></Modal.Header> */}
        <div className="header-popup modal-header">
          <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
          </div>
        <Modal.Body className="body-popup">
          <div className="modal-body-inner">
            {/* <h6>Assign an Assessment</h6> */}
            <h4>{editMode ? "Edit Assessment" : "Assign an Assessment*"}</h4>
            <br />
            <p>{editMode ? "Patient" : "Search Patient"}</p>

            {editMode ? (
              <div className="custom-form-notEditable">
                {`${name?.firstName}` + " " + `${name?.lastName}`}
              </div>
            ) : (
              <Select
                name="searchPatient"
                isSearchable
                onChange={(selectedOption: any) => {
                  setPatientUuid(selectedOption?.value);
                  setPatientError("");
                }}
                onInputChange={handleInputChange}
                options={formatSearchResults(searchResults)}
                placeholder="Enter Name or DOB"
                styles={selectStyleSearchWhite}
                isLoading={loading}
              />
            )}
            {patientError && <p className="text-danger">{patientError}</p>}
          </div>

          <div className="select-input-wrap">
            <p className="mt-4">Choose Assessment</p>
            {editMode ? (
              <div className="custom-form-notEditable">
                {`${uidToEdit?.templateName}`}
              </div>
            ) : (
              <Select
                name="chooseAssessment"
                value={assessmentOptions?.find(
                  (option) => option.value === assessmentUuid
                )}
                onChange={handleSelectChange}
                options={assessmentOptions}
                styles={selectStyle}
                isSearchable
              />
            )}

            {assessmentError && (
              <p className="text-danger">{assessmentError}</p>
            )}
          </div>
          <div className="row">
            <div className="col-6 pl-0 pr-0">
              <p className="mt-4">Due Date</p> 
                {editMode ? (
                  <div className="custom-form-notEditable">
                    {`${formatDate(uidToEdit?.dueDate)}`}
                  </div>
                ) : (
                  <DatePicker
                    className="custom-form-input-date1 react-date-picker__wrapper"
                    value={dueDate ? new Date(dueDate) : ""}
                    onChange={(date: any) => {
                      handleDateChange(date);
                    }}
                    clearIcon={null}
                    minDate={new Date()}
                    format="MM/dd/yyyy"
                    // monthPlaceholder={''}
                    // dayPlaceholder={''}
                    // yearPlaceholder={''}
                  />
                )}
                {dueDateError && <p className="text-danger">{dueDateError}</p>} 
            </div>
            <div className="col-6 pr-0">
              <p className="mt-4">Frequency</p>
              <Select
                name="frequency"
                value={frequencyId?.find(
                  (option: any) => option.value === frequency
                )}
                onChange={(selectedOption: any) => {
                  setFrequency(selectedOption?.value);
                  setFrequencyError("");
                }}
                options={frequencyId}
                styles={selectStyle}
                isSearchable
              />
              {frequncyError && <p className="text-danger">{frequncyError}</p>}
            </div>
            <div className="d-flex flex-row mt-5">
              <Button
                className="primary-btn"
                onClick={handleSave}
                disabled={btnActive}
              >
                {editMode ? "Save Changes" : "Assign Assessment"}
              </Button>
            </div>
            {!editMode && <div className="regular-p mt-5">
              <span className="regular-p">*ADCS-MCI-ADL used with permission from the NIA Alzheimer’s Disease Cooperative Study (NIA Grant AG10483).</span> <br/>
              <span className="regular-p">Galasko, D.; Bennett, D.; Sano, M.; Ernesto, C.; Thomas, R.; Grundman, M.; Ferris, S.; and the ADCS. “An Inventory to Assess Activities of Daily Living for Clinical Trials in Alzheimer’s Disease.” Alzheimer’s Disease and Associated Disorders, 1997. Volume 11(2): S33-S39.
            </span>
            </div>}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
