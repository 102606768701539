import React, { useContext, useEffect, useState } from 'react'
import Swal from "sweetalert2";
import { Loader } from '../../../component/loader/loader'
import AppServices from '../../../../services/appServices'
import { APIEndpoint } from '../../../../config/apiendpoint';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import axios from 'axios';

export default function IdMeHandler() {
  const navigate = useNavigate();
  const _appServices = new AppServices();

  const { setSessionDetails, isLoggedIn, setIsLoggedIn } = useContext(UserContext)

  const [isRequesting, setIsRequesting] = useState(false);
  const [responseMessage, setResponseMessage] = useState<any>();


  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const code = queryParameters.get("code");
    if (code != '') {
      let postJson: any = {};
      let apiUrl = APIEndpoint.idMeLogin;
      let signedSignature = sessionStorage.getItem('registrationConfirmation');
      if (signedSignature && signedSignature != '') {
        postJson = JSON.parse(signedSignature);
        postJson['code'] = code;
      } else {
        postJson = { 'code': code }
        // apiUrl = APIEndpoint.idMeLogin
      }
      setIsRequesting(true)
      _appServices.postData(apiUrl, postJson).then((_res: any) => {
        if (_res.status == 200 || _res.status == 201) {
          let data = _res.data[0] 
          _appServices.logData({
            Severity: 'Info',
            Activity: `${_res.data[0].email} logged into the application.`,
            ActivityStatus: 'Success',
            ActivityResponse: 'Logged In',
            Operation: 'User Activity',
            email: `${_res.data[0].email}`
          })

          let sessionObj = { 
            authToken: data.tokens, 
            isLoggedIn: false,
            stripeCustomerId: data.stripeCustomerId,
            email: data.email 
          } 
          
          if(data.paymentStatus) {
            sessionObj.isLoggedIn = true
            setIsLoggedIn(true)
            localStorage.setItem('sessionObject', JSON.stringify(sessionObj));
            setSessionDetails(sessionObj);
            navigate("/user-panel/dashboard")
            setIsRequesting(false)
          } else {
            setIsLoggedIn(false)
            localStorage.setItem('sessionObject', JSON.stringify(sessionObj));
            window.location.replace(data.registrationCheckoutUrl);
          }
        } else {
          setIsRequesting(false)
          setResponseMessage(JSON.stringify(_res.message))
        } 
      }, (err) => {
        const errorMessage = _appServices.getErrorMessage(err)
        setResponseMessage(errorMessage)
        setIsRequesting(false)
      });
    }
  }, [])


  const goToLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div className='conatiner top-margin-container'>
      <div className='row justify-content-center p-5'>
        <div className='col shadow' style={{ minHeight: '200px', maxWidth: '500px', padding: '30px', background: '#fff' }}>
        {isRequesting ? <><Loader></Loader> <p> Do not close the window</p></> :
            <div>
              <p>
                {responseMessage}
              </p>
              <button className="primary-btn mt-3" onClick={goToLogin}>
                Ok
              </button>
            </div>
          }
        </div>

      </div>
    </div>
  )
}
