import React, { useState } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import { LuDownload } from "react-icons/lu";
import { MdOutlinePrint } from "react-icons/md";
import { TailSpin } from "react-loader-spinner";
import AppServices from "../../../services/appServices";
declare var kendo:any;

interface ModalProps {
  showModal: boolean;
  handleClose: () => void;
  data: any;
}

const AssessmentPdfModel: React.FC<ModalProps> = ({
  showModal,
  handleClose,
  data,
}) => {

  const _appServices = new AppServices();

  const handlePrint = () => {
    // window.print();
    const contentElement = document.getElementById("assessment-content");
    if (contentElement) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      const iframeDocument = iframe.contentDocument || iframe?.contentWindow?.document;
      const clonedContent = contentElement.cloneNode(true);
      iframeDocument?.body.appendChild(clonedContent);
      iframe?.contentWindow?.print();

      _appServices.logData({
        Severity: 'Info',
        Activity: `User printed the completed assessment pdf.`,
        ActivityStatus: 'Success',
        ActivityResponse: 'Data Submitted',
        Operation: 'User Activity',
      })
    } else {
      console.error("Element with ID 'assessment-content' not found.");
    }
  };

  const handleDownload = async () => {
    try {
      kendo.drawing.drawDOM("#assessment-content", {
        paperSize: "A4",
        margin: "1cm",
        scale:0.5,
      }).then(function(group:any){
        // console.log("pdf generated")
        kendo.drawing.pdf.saveAs(group, `${Date.now()} - assessment.pdf`);
      });

      _appServices.logData({
        Severity: 'Info',
        Activity: `User downloaded completed assessment pdf.`,
        ActivityStatus: 'Success',
        ActivityResponse: 'Data Submitted',
        Operation: 'User Activity',
      })
    } catch (error) {
      console.error("Error generating PDF:", error);
      _appServices.logData({
        Severity: 'Error',
        Activity: `User failed to download completed assessment pdf.`,
        ActivityStatus: 'Failure',
        ActivityResponse: 'Data Submitted',
        Operation: 'User Activity',
      })
    }
  };

  return (
    <Modal
    className="assessment-modal"
      show={showModal}
      onHide={handleClose}
      dialogClassName="modal-full-width"
    >
      {/* <Modal.Header
        placeholder={'Assessment modal'}
        closeButton= {false}
        
        className={`modalTitle p-0 ${showModal && "print-hide"}`}
      ></Modal.Header> */}
      <Modal.Title className="d-flex justify-content-center w-100 modalTitle p-0">
          <div
            className="d-flex justify-content-center w-100 modalTitle p-0"
            style={{ marginRight: -20 }}
          >
            <div
              className="p-3"
              onClick={handlePrint}
              style={{ cursor: "pointer" }}
            >
              <span>
                <MdOutlinePrint />
              </span>
              <span
                className="big-p heading ml-2 mt-2 "
                style={{ cursor: "pointer" }}
              >
                View/Print
              </span>
            </div>
            <div
              className="p-3"
              onClick={handleDownload}
              style={{ cursor: "pointer" }}
            >
              <span>
                <LuDownload />
              </span>
              <span
                className="big-p heading ml-2 mt-2"
                style={{ cursor: "pointer" }}
              >
                Download
              </span>
            </div>
          </div>
          <div
            className={"p-3 pr-5 ml-auto"}
            onClick={handleClose}
            style={{ cursor: "pointer", paddingRight: "20px" }}
          >
            <span>X</span>
          </div>
      </Modal.Title>
      <Modal.Body
        id="assessment-content"
        className="d-flex justify-content-center w-100 flex-column"
      >
        <div
          className="text-center pt-4"
          style={{ fontWeight: 600 }}
        >
          CONFIDENTIAL - PATIENT HEALTH INFORMATION
        </div>

        {data && data?.questions && data?.questions?.length > 0 ? (
          <div className="ml-5 mr-5">
            <div>
              <img
                // className="top_logo"
                style={{ width: '210px', height: '31.819px', paddingRight: '98px', alignItems: 'center' }}
                src={require("../../../assets/image/App_logo.png")}
                alt="Logo"
              />
            </div>
            <div>
              <h3
                style={{
                  marginTop: 30, 
                  fontSize: 18,
                }}
              >
                Completed ADL Assessment*
              </h3>
              <table className="">
                <tbody>
                  <tr>
                    <td>
                      <span className="thick-subheading">Patient </span>
                    </td>
                    <td style={{ paddingLeft: "40px" }}>
                      <span className="thick-title capitalized">
                        {data?.patient?.firstName} {""} {data?.patient?.middleName} {data?.patient?.lastName}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="thick-subheading">Caregiver </span>
                    </td>
                    <td style={{ paddingLeft: "40px" }}>
                      <span className="capitalized"> {""} {data?.caregiver?.firstName} {""} {data?.caregiver?.middleName} {data?.caregiver?.lastName}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="thick-subheading">Provider </span>
                    </td>
                    <td style={{ paddingLeft: "40px" }}>
                      <span className="thick-subheading capitalized">
                        {data?.provider?.firstName} {""} {data?.provider?.middleName} {data?.provider?.lastName}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="thick-subheading">Practice Name </span>
                    </td>
                    <td style={{ paddingLeft: "40px" }}>
                      <span className="thick-subheading">{data?.practice}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="thick-subheading">Assessment </span>
                    </td>
                    <td style={{ paddingLeft: "40px" }}>
                      <span className="thick-subheading">
                        {data?.assessment}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="thick-subheading">Administered By </span>
                    </td>
                    <td style={{ paddingLeft: "40px" }}>
                      <span className="thick-subheading capitalized">
                        {data?.adminby?.firstName} {""} {data?.adminby?.middleName} {data?.adminby?.lastName}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="thick-subheading">Completion Date </span>
                    </td>
                    <td style={{ paddingLeft: "40px" }}>
                      <span>
                        {new Date(data?.completiondate).toLocaleDateString(
                          "en-US",
                          { month: "numeric", day: "numeric", year: "numeric" }
                        )}{" "}
                        {new Date(data?.completiondate).toLocaleTimeString(
                          "en-US",
                          { hour: "numeric", minute: "numeric", hour12: true }
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: 20 }}>
                      <span
                        style={{ lineHeight: 2 }}
                      >
                        Total Score{" "}
                      </span>
                    </td>
                    <td style={{ paddingLeft: "40px", paddingBottom: 20 }}>
                      <span style={{ fontWeight: "bold" }}>
                        {data?.totalScore}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table
              className="table table-bordered w-100"
              style={{ borderLeft: "1px solid #fff", borderRight: "none", borderTop: 'none' }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      width: "10%",
                      borderBottom: "1px solid #000",
                      borderLeft: "1px solid #fff",
                      borderRight: "1px solid #fff",
                      borderTop: "1px solid #fff",
                      textAlign: "left",
                      lineHeight: 0.5,
                    }}
                  >
                    Number
                  </th>
                  <th
                    style={{
                      width: "70%",
                      borderBottom: "1px solid #000",
                      borderLeft: "1px solid #fff",
                      borderRight: "1px solid #fff",
                      borderTop: "1px solid #fff",
                      textAlign: "center",
                      lineHeight: 0.5,
                    }}
                  >
                    Question and Response
                  </th>
                  <th
                    style={{
                      width: "20%",
                      borderBottom: "1px solid #000",
                      borderLeft: "1px solid #fff",
                      borderRight: "1px solid #fff",
                      borderTop: "1px solid #fff",
                      textAlign: "right",
                      lineHeight: 0.5,
                    }}
                  >
                    Score
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.questions.map((question: any, chabhi: any) => (
                  <tr key={chabhi}>
                    <td
                      style={{
                        width: "10%",
                        borderBottom: "1px solid #000",
                        borderLeft: "1px solid #fff",
                        borderRight: "1px solid #fff",
                        textAlign: "center",
                        paddingRight: 70,
                        paddingTop: 0,
                        paddingBottom: 0, 
                        fontSize: "10",
                        fontWeight: "600",
                      }}
                    >
                      {question.id}
                    </td>
                    <td
                      style={{
                        width: "70%",
                        borderBottom: "1px solid #000",
                        borderLeft: "1px solid #fff",
                        borderRight: "1px solid #fff",
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <div>
                        <strong>{question.question}</strong>
                      </div>
                      <div
                        style={{ 
                          fontStyle: "italic",
                        }}
                      >
                        {question.answer}
                      </div>
                      {question?.subQuestionAndAnswer.lenght > 1 ?
                        (
                          <div
                            style={{
                              marginLeft: 50,
                              paddingTop: 0,
                            }}
                          >
                            <div className="mt-3">
                              <strong>
                                {question.subQuestionAndAnswer.question}
                              </strong>
                            </div>

                            <div
                              style={{ 
                                fontStyle: "italic",
                              }}
                            >
                              {question.subQuestionAndAnswer.answer} { }
                            </div>
                          </div>
                        )
                        :
                        question?.subQuestionAndAnswer.map((subQuestion: any, index: any) => (
                          <div
                            style={{
                              marginLeft: 50,
                              paddingTop: 0,
                            }}
                          >
                            <div className="mt-3">
                              <strong>
                                {subQuestion?.question}
                              </strong>
                            </div>

                            <div
                              style={{ 
                                fontStyle: "italic",
                              }}
                            >
                              {subQuestion?.answer} { }
                            </div>
                          </div>
                        ))
                      }
                    </td>

                    <td
                      style={{
                        width: "20%",
                        borderBottom: "1px solid #000",
                        borderLeft: "1px solid #fff",
                        borderRight: "1px solid #fff",
                        textAlign: "right",
                        paddingRight: 25,
                        paddingTop: 0,
                        paddingBottom: 0, 
                        fontSize: "10",
                        fontWeight: "600",
                      }}
                    >
                      {question.score}
                      <br />
                      {question?.subQuestionAndAnswer.lenght > 1 ?
                        (<div style={{ marginTop: 30 }}>
                          {question.subQuestionAndAnswer.score}
                        </div>) :
                        (
                          <div>
                            {question?.subQuestionAndAnswer.map((num: any, index: any) => (
                              <div style={{ marginTop: 25 }}>
                                <br />
                                {num.score}
                              </div>
                            ))}
                          </div>
                        )
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) :
          data && data?.questions && data?.questions.length === 0 ?
            (
              <div className="d-flex align-items-center justify-content-center"
                style={{ height: "100px" }}>
                <h5 className="h5 thick-heading">...No Data Available</h5>
              </div>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100px" }}
              >
                <div>
                  <TailSpin
                    visible={true}
                    height="80"
                    width="80"
                    color="#03A84E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              </div>
            )}
        <div className="regular-p ml-5 mt-5">
          <p className="regular-p">*ADCS-MCI-ADL used with permission from the NIA Alzheimer’s Disease Cooperative Study (NIA Grant AG10483).</p>
          <p className="regular-p">Galasko, D.; Bennett, D.; Sano, M.; Ernesto, C.; Thomas, R.; Grundman, M.; Ferris, S.; and the ADCS. “An Inventory to Assess Activities of Daily
            Living for
            Clinical Trials in Alzheimer’s Disease.”
            Alzheimer’s Disease and Associated Disorders, 1997. Volume 11(2): S33-S39.
          </p>
        </div>

        <div className="mt-5">
          <div className="regular-p m-auto text-center">
            RPM is powered by OtisHealth
            © Copyright 2024, Eisai Inc. All Rights Reserved
            US3908 03/2024
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssessmentPdfModel;
