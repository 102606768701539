import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import PatientHeader from '../../../component/PatientHeader/PatientHeader';
import AppServices from '../../../../services/appServices';
import BloodPressureGraph from '../../../component/graphs/BloodPressureGraph';
import RestingHeartRateGraph from '../../../component/graphs/RestingHeartRateGraph';
import ThirtyDayAnalysis from '../../../component/graphs/ThirtyDayAnalysis';
import DeviceTraining from '../../../component/graphs/DeviceTraining';
import ReportArchives from '../../../component/graphs/ReportArchives';
import ManageMonitoring from '../../../component/graphs/ManageMonitoring';
import './styles.css'
import NewTraining from '../../../../theme/AddTraining/addTraining';
import AddMonitoring from '../../../../theme/AddMonitoring/addMonitoring';
import { APIEndpoint } from '../../../../config/apiendpoint';
import BloodGlucoseGraph from '../../../component/graphs/BloodGlucoseGraph';
// import MeasurementModal from '../../../component/graphs/MoniteringModal';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function PatientProfile() {
  const location = useLocation();
  const { state } = location;
  const [bloodPressureData, setBloodPressureData] = useState<any>([]);
  const [bloodGlucoseData, setBloodGlucoseData] = useState<any>([]);
  const [heartRateData, setHeartRateData] = useState<any>([]);
  const [patientData, setPatientData] = useState<any>(state);
  const [isShowTrainingPopup, setIsShowTrainingPopup] = useState(false);
  const [isShowMonitoringPopup, setIsShowMonitoringPopup] = useState(false);
  const [staffList, setStaffList] = useState([])
  const [measurementTypeList, setMeasurementTypeList] = useState()

  const [getTraining, setGetTraining] = useState(false);
  const [updateReportList, setUpdateReport] = useState(false);
  const _appServices = new AppServices();

  useEffect(() => {
    onGraphMeasurementData()
    get30DayAnalysisData()
    getAllStaffList()
    getMesurementTypeList()
  }, [])

  const onGraphMeasurementData = async () => {
    const patId = patientData?.uuid
    try {
      const resp = await _appServices.getData(`${APIEndpoint.getPatientMeasurement}?patientUid=${patId}`);
      if (resp.status === 200 && resp.success == true) {
        let data = resp.data
        console.log('graph', data)
        setHeartRateData(data?.heartRate ? data.heartRate : [])
        setBloodPressureData(data?.bloodPressure ? data.bloodPressure : [])
        setBloodGlucoseData(data?.bloodGlucose ? data.bloodGlucose : [])
      }
    } catch (error) {
      console.error("Error fetching datad:", error);
    }
  }
  const getTrainingList = () => {
    setGetTraining(!getTraining);
  }
  const updateReport = () => {
    setUpdateReport(!updateReportList);
  }
  const getMesurementTypeList = async () => {
    try {
      const resp = await _appServices.getData(`${APIEndpoint.getMeasurement}`);
      if (resp.status === 200 && resp.success == true) {
        let data = resp.data
        setMeasurementTypeList(data)
      }
    } catch (error) {
      console.error("Error fetching datad:", error);
    }
  }

  const getAllStaffList = async () => {
    try {
      const resp = await _appServices.getData(`${APIEndpoint.staff}?type=all`);
      if (resp.status === 200 && resp.success == true) {
        setStaffList(resp.data)
        // setHeartRateData(data.heartRate ? data.heartRate:[])
        // setBloodPressureData(data.bloodPressure ? data.bloodPressure:[])
      }
    } catch (error) {
      console.error("Error fetching datad:", error);
    }
  }

  const get30DayAnalysisData = async () => {
    const patId = patientData?.uuid
    try {
      const resp = await _appServices.getData(`${APIEndpoint.logAnalysis}?patientUid=${patId}`);
      if (resp.status === 200 && resp.success == true) {
        let data = resp.data
        // setHeartRateData(data.heartRate ? data.heartRate:[])
        // setBloodPressureData(data.bloodPressure ? data.bloodPressure:[])
      }
    } catch (error) {
      console.error("Error fetching datad:", error);
    }
  }


  const handleCloseModal = () => {
    console.log('Modal closed');
  };

  const addMonitoring = () => {
    setIsShowTrainingPopup(true);
  }

  const handleClose = () => {
    setIsShowTrainingPopup(false);
  };

  const handleshowTrainingPopup = () => {
    setIsShowMonitoringPopup(true);
  }

  const handleCloseTrainingPopup = () => {
    setIsShowMonitoringPopup(false);
  };


  return (
    <div className='outContainer'>
      <PatientHeader
        patientData={patientData}
        onCloseModal={handleCloseModal}
      />
      <div className="mb-5 profile_page_container" >
        {/* <h2 className='mt-5 mb-5'>Under development</h2> */}
        <div className="row mt-3 mb-5">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pr-5" >
            <BloodPressureGraph data={bloodPressureData} usePdf={false} />
            <RestingHeartRateGraph data={heartRateData} />
            <BloodGlucoseGraph data={bloodGlucoseData} />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0" >
            <ThirtyDayAnalysis patientData={patientData} staffList={staffList} updateLog={updateReport} />
            <ManageMonitoring patientData={patientData} handleshowTrainingPopup={handleshowTrainingPopup} />
            <DeviceTraining patientData={patientData} addMonitoring={addMonitoring} setGetTraining={getTraining} />
            <ReportArchives patientData={patientData} updateReport={updateReportList} />
          </div>
        </div>
      </div>
      <AddMonitoring show={isShowMonitoringPopup} onHide={handleCloseTrainingPopup} editMode={false} />
      <NewTraining patientData={patientData} show={isShowTrainingPopup} onHide={handleClose} editMode={false} measurementTypeList={measurementTypeList} staffList={staffList} getTrainingList={getTrainingList} />
      {/* <MeasurementModal show={isShowMonitoringPopup} onHide={handleCloseTrainingPopup} /> */}
    </div>
  );
}
