import React, { useState } from 'react';
import { assess_box, horizontal_line, white_font_button } from '../../../../theme/theme';
import edit from '../../../../assets/image/editProvider.png';
import { AssessmentQuestion } from '../../../../theme/AssessmentQuestion/AssessmentQuestion';

export const EditAssessment = () => {
  const [activeQuestion, setActiveQuestion] = useState<number | null>(null);

  const handleEditClick = (index: number) => {
    setActiveQuestion(index);
  };

  const handleDeleteClick = (index: number) => {
    // delete
  };

  return (
    <div className='container top-margin-container'>
      <h5 className='h5 text-left heading'>Create New Assessment Template</h5>

      <div className='text-left mt-5 ml-5'>
        <h6 className='h6 heading'>Assessment Name and Title: </h6>

        <p className='ml-2' style={horizontal_line}></p>
        <div className='d-flex flex-row justify-content-between mt-5 ml-2 mb-3'>
          <p className='big-p heading'>Template Name:</p>
          <img src={edit} alt='' style={{ height: '28px', width: '28px' }} />
        </div>

        <p className='ml-4 assessques thin-heading  mt-4'>ADCS-MCI-ADLL Assessment Standard - Copy</p>

        <div style={assess_box}>
          <div className='row'>
            <div className='col-10'>
              <p className='heading big-p'>Assessment Title:</p>
              <input
                className='custom-form-input'
                placeholder=''
                // value={'ADCS-MCI Activities of Daily Living Inventory - Month 6 Visit'}
              />
            </div>
            <div className='col-2 d-flex flex-column align-items-center p-2'>
              <img src={edit} alt='' style={{ height: '28px', width: '28px', marginBottom: '10px' }} />
              <button style={white_font_button}>Save</button>
            </div>
          </div>
        </div>

        <h6 className='mt-5 mb-5 h6'>Assessment Questions:</h6>

        {[1, 2, 3, 4].map((index) => (
          <AssessmentQuestion
            key={index}
            index={index}
            isActive={index === activeQuestion}
            onClickEdit={handleEditClick}
            onDelete={handleDeleteClick}
          />
        ))}

      </div>

      <div className="d-flex justify-content-start ml-5 mt-5">
        <button style={white_font_button}>Save Changes</button>
      </div>
    </div>
  );
};

export default EditAssessment;

