import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import AppServices from '../../../../services/appServices';

import { APIEndpoint } from '../../../../config/apiendpoint';
import Swal from 'sweetalert2';
import * as Yup from "yup";
import { Formik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import ContextStore from '../../../../context/clearContext';

const ChangePassword = (
  {
    show,
    onHide,
    onClick,
    editMode,
    uuidToEdit,
    isLoading,
    name,
    data
  }: any
) => {
  const navigate = useNavigate();
  const _appServices = new AppServices()
  const _contextStore = new ContextStore()
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const logOut = () => {
    _contextStore.clearContext()
    localStorage.clear()
    navigate('/')
    // _appServices.showFlashMsg('success', 'Success', 'Logged out successfully!', false);
  }

  const handleFormSubmit = async (values: any, resetForm: any, setSubmitting: any) => {
    if (values.oldPassword == values.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "The old password and the new password cannot be the same.",
      });
      setSubmitting(false);
      return
    }
    setSubmitting(true);
    let postJson = {
      "oldPassword": values.oldPassword,
      "password": values.confirmPassword
    };
    try {
      const res = await _appServices.patchData(APIEndpoint.changePassword, postJson)
      if ((res.status === 200 || res.status === 202) && res.success) {
        // handle success
        onHide()
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res?.message + ' Please, Login again' || "Data updated successfully. Please, Login again",
        }).then((result: any) => {
          if (result.isConfirmed) {
            logOut()
          }
        })
        setSubmitting(false)
      } else {
        // handle failure
        let errMessage = _appServices.getErrorMessage(res)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
        setSubmitting(false)
      }
      _appServices.logData({
        Severity: 'Info',
        Activity: res?.message,
        ActivityStatus: 'Password change',
        ActivityResponse: 'Data Submitted',
        Operation: 'User Activity',
      })
    } catch (error) {
      let errMessage = _appServices.getErrorMessage(error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });
      _appServices.logData({
        Severity: 'Info',
        Activity: error,
        ActivityStatus: 'Password Change Error',
        ActivityResponse: 'Data Submit Failed',
        Operation: 'User Activity',
      })
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{10,}$/,
        "Password must contain at least 10 characters including a symbol, upper and lowercase letters, and a number."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Confirm Password and New Password did not match")
      .required("Confirm Password is required"),
  });

  const handleHide = () => {
    onHide()
  }

  return (
    <div>
      <Modal
        className="assignModal assign-assessment-pop-model"
        show={show}
        animation={false}
        onHide={handleHide}
      >
        <div className="header-popup modal-header">
          <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>
        <Modal.Body className="body-popup">
          <div className="profile-form mb-5">
            <div className="mb-5">
              <div className="row ">
                <h5 className="header" style={{ textAlign: "center" }}>
                  Reset Password
                </h5>
              </div>

              <div className='change form'>
                <Formik
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    handleFormSubmit(values, resetForm, setSubmitting);
                  }}
                  initialValues={initialValues}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    isValid,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row className="mt-5 mb-4">
                        {/* <Form.Label>First Name*</Form.Label> */}
                        <Form.Control
                          className="custom-form-input"
                          type={showPassword ? "text" : "password"}
                          name="oldPassword"
                          placeholder="Old Password"
                          value={values.oldPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.oldPassword}
                        />
                        {showPassword ? (
                          <IoEye
                            onClick={() => setShowPassword(!showPassword)}
                            size={30}
                            style={{
                              position: "absolute",
                              right: '10px',
                              transform: "translateY(50%)",
                              padding: "5px",
                              width: "35px",
                              borderRadius: "50%",
                              marginRight: 30,
                              alignItems: "center",
                            }}
                          />
                        ) : (
                          <IoEyeOff
                            onClick={() => setShowPassword(!showPassword)}
                            size={30}
                            style={{
                              position: "absolute",
                              right: '10px',
                              transform: "translateY(50%)",
                              padding: "5px",
                              width: "35px",
                              borderRadius: "50%",
                              marginRight: 30,
                              alignItems: "center",
                            }}
                          />
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.oldPassword}
                        </Form.Control.Feedback>
                      </Row>

                      <Row className="mb-4">
                        <Form.Control
                          className="custom-form-input"
                          type={showNewPassword ? "text" : "password"}
                          name="newPassword"
                          placeholder="New Password"
                          value={values.newPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.newPassword}
                        />
                        {showNewPassword ? (
                          <IoEye
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            size={30}
                            style={{
                              position: "absolute",
                              right: '10px',
                              transform: "translateY(50%)",
                              padding: "5px",
                              width: "35px",
                              borderRadius: "50%",
                              marginRight: 30,
                              alignItems: "center",
                            }}
                          />
                        ) : (
                          <IoEyeOff
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            size={30}
                            style={{
                              position: "absolute",
                              right: '10px',
                              transform: "translateY(50%)",
                              padding: "5px",
                              width: "35px",
                              borderRadius: "50%",
                              marginRight: 30,
                              alignItems: "center",
                            }}
                          />
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.newPassword}
                        </Form.Control.Feedback>
                      </Row>

                      <Row className="mb-4">
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          className="custom-form-input"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.confirmPassword}
                        />
                        {showConfirmPassword ? (
                          <IoEye
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            size={30}
                            style={{
                              position: "absolute",
                              right: '10px',
                              transform: "translateY(50%)",
                              padding: "5px",
                              width: "35px",
                              borderRadius: "50%",
                              marginRight: 30,
                              alignItems: "center",
                            }}
                          />
                        ) : (
                          <IoEyeOff
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            size={30}
                            style={{
                              position: "absolute",
                              right: '10px',
                              transform: "translateY(50%)",
                              padding: "5px",
                              width: "35px",
                              borderRadius: "50%",
                              marginRight: 30,
                              alignItems: "center",
                            }}
                          />
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword}
                        </Form.Control.Feedback>
                      </Row>

                      {/* <Row className="mt-5 mb-3">
                <Col>
                <PrimaryButton
                      handleSubmit={async () => handleSubmit()}
                      loading={isSubmitting}
                      text="Change Password"
                      disabled={!isValid}
                    />
                </Col>
                <Col></Col>
              </Row> */}
                      <div className="d-flex justify-content-center mt-4">
                        <Button
                          className="primary-btn"
                          disabled={isSubmitting}
                          onClick={async () => handleSubmit()}

                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ChangePassword