import React from 'react';
import lock from '../../../../assets/image/lock.png';
import print from '../../../../assets/image/print.png';
import edit from '../../../../assets/image/editProvider.png'
import { white_font_button } from '../../../../theme/theme';
import { useNavigate } from 'react-router-dom';

export default function AssessmentTemplate() {

  const navigate = useNavigate()

  const handleCreateNewAssess =() =>{
      navigate('/user-panel/editAssessment')
  }

  return (
    <div className='container top-margin-container text-left'>
      <div className='mt-5'>
        <h6 className='h6 heading'>View/Edit Assessment Templates</h6>
      </div>

    <div className='mb-5'>
      <table className='assess-table'>
        <thead>
          <tr className='headings'>
            <th className='h ml-5'>Assessment Template</th>
            {/* <th>Edit</th> */}
            <th>View/Print</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='h'>ADCS-MCI-ADL Standard Assessment</td>
            {/* <td><img src={lock} alt='pic to nai mili' style={{height:'25px', width:'20px'}} /></td> */}
            <td><img src={print} alt='pic to nai mili' style={{height:'23px', width:'19px'}} /></td>
          </tr>
        </tbody>
      </table>
    </div>

    {/* trying to make table from theme 
    <div style={assess_table}>
      <div className='heading'>
        
      </div>
    </div> */}

    {/* <h6 className='h6 heading'>My Modified Assessment Templates</h6>

    <div>
    <table className='table'>
        <thead>
          <tr className='headings'>
            <th className='h ml-5'>Assessment Template</th>
            <th>Edit</th>
            <th>View/Print</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='h'>ADCS-MCI-ADL Standard Assessment</td>
            <td><img src={edit} alt='pic to nai mili' style={{height:'25px', width:'20px'}} /></td>
            <td><img src={print} alt='pic to nai mili' style={{height:'23px', width:'19px'}} /></td>
          </tr>
          <tr>
            <td className='h'>ADCS-MCI-ADL Standard Assessment</td>
            <td><img src={edit} alt='pic to nai mili' style={{height:'25px', width:'20px'}} /></td>
            <td><img src={print} alt='pic to nai mili' style={{height:'23px', width:'19px'}} /></td>
          </tr>
        </tbody>
      </table>
      <div className='table-footer ml-3'>
        <div className='col-6'>
        </div>
        <div className='col-6 text-right'>
          <button style={white_font_button} onClick={handleCreateNewAssess}>Create New Assessment Template</button>
        </div>
      </div>
    </div> */}

    </div>
  )
}
